import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {ConnectionDataService} from '../../connection.data-service';
import {ConnectionModel, EConnectionType} from '../../../../models/api/connection.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {Toaster} from '../../../../classes/toaster.class';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {StringUtil} from '../../../../classes/string-util';

@Component({
    selector: 'superunie-adam-connection-form',
    templateUrl: './superunie-adam-connection-form.component.html',
    styleUrls: ['./superunie-adam-connection-form.component.scss'],
})
export class SuperunieAdamConnectionFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    public form: FormGroup;

    public validationMessages: any;

    public logo = ConnectionModel.getLogo(EConnectionType.SUPERUNIE_ADAM);

    private _dataFieldOptions: DropdownItem<string>[];
    public dataFieldOptions: DropdownItem<string>[] = [];

    @Input() public formData: Record<string, any>;
    @Output() public formSubmitted: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

    constructor(private fb: FormBuilder,
                private connectionDataService: ConnectionDataService,
                private dataFieldsService: DataFieldsApiService) {
    }

    public ngOnInit(): void {
        this.setupFormGroup();
        this.getDataFields();

        this.form.statusChanges.pipe(
            map((status) => {
                return status === 'VALID';
            }),
            distinctUntilChanged(),
            startWith(false),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => {
            this.connectionDataService.setSaveButtonStateDisabled(!valid);
        });

        this.connectionDataService.saveButtonClickEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.form.status === 'VALID') {
                this.onSubmitValid();
            }
        });
    }

    private setupFormGroup(): void {
        const connection = this.formData;
        this.form = this.fb.group({
            name: [connection?.name || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            apiKey: [connection?.apiKey || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            imageRegexFilter: connection?.synchronisationSettings?.imageRegexFilter || '',
            enableImageSync: connection?.synchronisationSettings?.enableImageSync || false,
            removeLeadingZeros: connection?.synchronisationSettings?.removeLeadingZeros || false,
            globalLocationNumbers: [],
            productDataField: []
        });

        if (connection?.synchronisationSettings?.globalLocationNumbers?.length > 0) {
            const items =  connection?.synchronisationSettings.globalLocationNumbers.map((GLN: string) => new DropdownItem(GLN, GLN));

            this.form.patchValue(
                { globalLocationNumbers: items}
            );
        }
    }

    private patchDataFieldOption(): void {
        const connection = this.formData;
        if (connection?.synchronisationSettings?.productDataField) {
            const dataField = this._dataFieldOptions.find((dataFieldOption) =>
                dataFieldOption.getValue() === connection.synchronisationSettings.productDataField);

            this.form.patchValue(
                { productDataField: dataField}
            );
        }
    }

    public onSubmitValid(): void {
        this.connectionDataService.setSaveButtonStateLoading(true);
        this.formSubmitted.emit(this.form.value);
    }

    private getDataFields(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (dataFields) => {
                    this._dataFieldOptions = dataFields.map((field) => new DropdownItem(field.name, field._id));
                    this.dataFieldOptions = this._dataFieldOptions;
                    this.patchDataFieldOption();
                },
                error: Toaster.handleApiError
            });
    }

    public searchProductDataFields(event: IDropdownRequestDataEvent): void {
        this.dataFieldOptions = this.filterDropdownItems(this._dataFieldOptions, event.search);
    }
    private filterDropdownItems(sourceData: DropdownItem<string>[], search: string): DropdownItem<string>[] {
        try {
            const regex = new RegExp(StringUtil.escapeRegExp(search), 'i');
            if (search) {
                return sourceData.filter((item) => item.getTitle().match(regex)?.length > 0);
            } else {
                return sourceData;
            }
        } catch(err) {
            return sourceData;
        }
    }
}
