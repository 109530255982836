<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        <nuc-button-secondary
            *ngIf="permissions.PUT_CONNECTION | userIsAllowedTo"
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    </div>
    <div class="connection-details" *ngIf="connection">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div>
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{connection.name}}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Consumer Secret</p>
                    <key-viewer [key]="connection.consumerSettings.secret"
                                [disabled]="loading">
                    </key-viewer>
                </div>
                <div class="field">
                    <p class="body-strong">Consumer Authentication</p>
                    <key-viewer [key]="connection.consumerSettings.authentication"
                                [disabled]="loading">
                    </key-viewer>
                </div>
            </div>
        </div>
    </div>
    <div class="events">
        <div class="header-row">
            <h1>Events</h1>
            <nuc-button-bar>
                <nuc-button-primary text="Add event" (click)="addEvent()"></nuc-button-primary>
            </nuc-button-bar>
        </div>
        <div class="items">
            <ng-container *ngIf="connection && events else loadingIndicator">
                <ng-container *ngIf="events.length; else emptyState">
                    <div class="event" *ngFor="let event of events">
                        <div class="properties">
                            <div>
                                <p class="body-strong">Event</p>
                                <p>{{event.event}}</p>
                            </div>
                            <div>
                                <p class="body-strong">Event endpoint</p>
                                <p>{{event.endpoint}}</p>
                            </div>
                        </div>
                        <nuc-button-bar class="actions">
                            <nuc-button-secondary *ngIf="permissions.PUT_CONNECTION | userIsAllowedTo"
                                                  icon="nucicon_edit"
                                                  (click)="onEditEventClicked(event._id)"
                                                  nucTooltip="Edit event"></nuc-button-secondary>
                            <nuc-button-secondary *ngIf="permissions.DELETE_CONNECTION | userIsAllowedTo"
                                                  icon="nucicon_trash_fill"
                                                  (click)="openDeleteDialog(event._id)"
                                                  nucTooltip="Delete event"></nuc-button-secondary>
                        </nuc-button-bar>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #loadingIndicator>
                <rl-loading-indicator size="large"></rl-loading-indicator>
            </ng-template>
            <ng-template #emptyState>
                <nuc-empty-state-component imageUrl="/assets/images/general_empty_state.svg"
                                           title="Add your first event"
                                           [small]="true">
                </nuc-empty-state-component>
            </ng-template>
        </div>
    </div>
</div>
