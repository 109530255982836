import {Component, computed, effect, OnInit, signal, WritableSignal} from '@angular/core';
import {WorkflowConfigurationsDetailsService} from '../workflow-configurations-details.service';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {toSignal} from '@angular/core/rxjs-interop';
import {
    IWorkflowDetailPropertyMetadata
} from '../workflow-configuration-detail-property/workflow-configuration-detail-property.component';
import {EWorkflowConfigurationProperty} from '../workflow-configuration-property.config';

@Component({
  selector: 'workflow-configuration-component-details-component',
  templateUrl: 'workflow-configuration-component-details.component.html',
  styleUrls: ['workflow-configuration-component-details.component.scss']
})
export class WorkflowConfigurationComponentDetailsComponent implements OnInit {
    public propertyMetadata: WritableSignal<IWorkflowDetailPropertyMetadata> = signal(undefined);

    public componentId = toSignal(this.route.params.pipe(
        map((params) => params.componentId)));

    public component = computed(() => {
        return this.workflowConfigurationsDetailsService.workflow().components.find(
            (component) => component._id === this.componentId());
    });

    public effect = effect(() => {
        this.propertyMetadata.set({property: EWorkflowConfigurationProperty.ACTIONS, componentId: this.componentId()});
    }, {allowSignalWrites: true});


    constructor(private route: ActivatedRoute,
                private workflowConfigurationsDetailsService: WorkflowConfigurationsDetailsService) {
    }

    public ngOnInit(): void {
        // initialize the property, later check if we can make this better, probably issues with routing or signal
        this.propertyMetadata.set({property: EWorkflowConfigurationProperty.ACTIONS, componentId: this.componentId()});
    }
}
