import {Component, Input, OnInit} from '@angular/core';
import {EDataFieldTypes} from '../../app.enums';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormDetailModel, FormFieldModel} from '../../models/api/form.model';
import {VariantModel} from '../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {IDataFieldsModel} from '../../models/interfaces/datafields-model.interface';

@Component({
    selector: 'data-fields-information-form',
    templateUrl: './data-fields-information-form.component.html',
    styleUrls: ['./data-fields-information-form.component.scss']
})
export class DataFieldsInformationFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public dataFields: Record<string, any>;

    @Input() public variants: VariantModel[];
    @Input() public formConfig: FormDetailModel;
    @Input() public readonly = false;

    public variantFormControl: UntypedFormControl;
    public variantEnabled = false;
    public dataFieldsGroup: UntypedFormGroup;

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.variantEnabled = this.formConfig?.rows.some((row) =>
                row.fields.some((field) => field.dataField.enableVariants))
            && !!this.variants?.length;

        if (!this.form.contains('variant')) {
            this.variantFormControl = new UntypedFormControl();
            this.form.addControl('variant', this.variantFormControl);
        } else {
            this.variantFormControl = this.form.get('variant') as UntypedFormControl;
        }

        if (!this.form.contains('dataFields')) {
            if (!this.formConfig) return;
            this.addControls();
            if (this.variantEnabled) this.variantFormControl.patchValue(this.variants[0]);
        } else {
            this.dataFieldsGroup = this.form.get('dataFields') as UntypedFormGroup;
        }
    }

    private addControls(): void {
        this.dataFieldsGroup = new UntypedFormGroup({});
        this.form.addControl('dataFields', this.dataFieldsGroup);
        // Add data fields group
        this.formConfig.rows.forEach((row) =>
            row.fields.forEach((field) => {
                const fieldName = field.dataField.fieldName;
                if (this.variantEnabled && field.dataField.enableVariants) {
                    this.variants.forEach((variant) => {
                        const controlName = fieldName + '.' + variant.key; // example: control name is MB-description.f3xyz4xy
                        this.dataFieldsGroup.addControl(controlName, DataFieldsInformationFormComponent.getFormControl(field));
                    });
                } else { // example: control name is MB-body_pos
                    this.dataFieldsGroup.addControl(fieldName, DataFieldsInformationFormComponent.getFormControl(field));
                }
            }));

        if (this.dataFields) {
            const data = {
                dataFields: DataFieldsComponentUtil.dataFieldsFromModelData(this.formConfig,
                    {dataFields: this.dataFields} as IDataFieldsModel, this.variants)
            };
            this.form.patchValue(data);
        }
    }

    private static getFormControl(field: FormFieldModel): UntypedFormControl {
        return field.dataField.dataType.type === EDataFieldTypes.ENUM && field.dataField.dataType.enumeration.multiSelect
            ? new UntypedFormControl({value: [], disabled: !field.options.editable})
            : new UntypedFormControl({value: null, disabled: !field.options.editable});
    }
}
