import {TableDataProvider} from '@relayter/rubber-duck';
import {inject, Injectable} from '@angular/core';
import {finalize, tap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {Toaster} from '../../classes/toaster.class';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {NewCursorArray} from '../../api/new-api-cursor';
import {TableSortOptions} from '../../api/table-sort-options';
import {SelectionModel} from '@angular/cdk/collections';

@Injectable()
export class MasterBriefingDataProvider extends TableDataProvider<CampaignItemModel> {
    private campaignItemsApiService = inject(CampaignItemsApiService)
    private apiCursor: NewCursorArray;
    sortOptions: TableSortOptions;

    constructor() {
        super();

        // Init api cursor
        this.apiCursor = new NewCursorArray(1, this.sortOptions);
        this.selection = new SelectionModel<CampaignItemModel>(true);
    }

    public retrieveData(all = false): void {
        this.loading = true;

        if (all) {
            this.campaignItemsApiService.getAllCampaignItems(undefined, undefined,
                this.searchValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(1))
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    takeUntilDestroyed(this.destroyRef)

                )
                .subscribe({
                    next: (items: CampaignItemModel[]) => {
                        this.select(...items);
                    },
                    error: (err: ARApiError) => {
                        Toaster.handleApiError(err);
                    }
                });
        } else {
            this.campaignItemsApiService.getCampaignItems(undefined, undefined,
                this.pageSize, 0, this.searchValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(this.pageIndex))
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    tap(() => {
                        this.searching = !!this.searchValue;
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (res: ARPagedResponseDataModel<CampaignItemModel>) => {
                        this.data = res.items;
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            this.setCursor();
                        }
                    },
                    error: (err: ARApiError) => {
                        Toaster.handleApiError(err);
                    }
                });
        }

    }

    public resetCursorArray(pageIndex = this.pageIndex): void {
        this.apiCursor.reset(pageIndex, this.sortOptions);
    }

    private setCursor(): void {
        this.apiCursor.setCursor(this.pageIndex, this.data[this.data.length - 1]);
    }
}
