import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ExportImageModalViewComponent, IExportImageModalData} from '../../../../components/export-asset-modal-view/export-image-modal-view.component';
import {ProductModel} from '../../../../models/api/product.model';
import {AssetModel, EAssetType} from '../../../../models/api/asset.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../../api/services/products.service';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {ConfigModel} from '../../../../models/api/config.model';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {BUTTON_TYPE, FullModalConfig, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {filter, takeUntil} from 'rxjs/operators';
import {EAssetDisplayProperties} from '../../../../pipes/asset-display.pipe';
import {IProductFormData, ProductFormComponent} from '../../../../forms/product-form/product-form.component';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {ESegmentTrackEvents, ISegmentService, SEGMENT_SERVICE} from '../../../../services/segment/segment.service.interface';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {animate, style, transition, trigger} from '@angular/animations';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-product-detail-page-component',
    templateUrl: 'products-detail.component.html',
    styleUrls: ['products-detail.component.scss'],
    animations: [
        trigger('rlLoadTrigger', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.3s ease', style({opacity: '1'}))
            ])
        ])
    ]
})
export class ProductDetailComponent extends RLBaseComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public productId: string;
    @Output() public onProductDeleted: EventEmitter<void> = new EventEmitter();
    @Output() public onProductEdited: EventEmitter<void> = new EventEmitter();
    public product: ProductModel = new ProductModel();
    public productAssets: AssetModel[] = [];
    public config: ConfigModel;

    public EAssetDisplayProperties = EAssetDisplayProperties;
    protected readonly EAssetType = EAssetType;
    public dateFormats = RLDatePipe.dateFormats;

    public dataFields: DataFieldModel[];

    public dataSubscription: Subscription;

    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private productService: ProductService,
                private dialogService: NucDialogService,
                private dataFieldService: DataFieldsApiService,
                private fullModalService: FullModalService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService) {
        super();
    }

    public ngOnInit(): void {
        this.productId = this.route.snapshot.params['id'] ? this.route.snapshot.params['id'] : this.productId;

        this.onProductDeleted
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.router.navigate([AppConstants.PRODUCTS_PATH]));

        this.onProductEdited
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getData());

        this.getData();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.productId) {
            this.getData();
        }
    }

    /**
     * On edit button clicked open edit product modal
     */
    public onEditProductClicked(): void {
        this.dataSubscription = this.productService.getProductById(this.product._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (product: ProductModel) => {
                    const modalData: IProductFormData = {product};
                    const modalConfig = new FullModalConfig(
                        'Edit Product', 'You can edit basic product information.', modalData);
                    modalConfig.confirmClose = true;
                    const modal = this.fullModalService.open(ProductFormComponent, modalConfig);
                    modal.afterClosed()
                        .pipe(filter((refresh: boolean) => refresh))
                        .subscribe(() => this.onProductEdited.next());
                },
                error: Toaster.handleApiError
            });

    }

    public onExportAssetClicked(image: AssetModel): void {
        const modalData: IExportImageModalData = {image};
        const modalConfig = new FullModalConfig('Download asset', 'Select the type and size of the asset you wish to download.', modalData);
        this.fullModalService.open(ExportImageModalViewComponent, modalConfig);
    }

    public onDownloadOriginalAssetClicked(assetUrl: string): void {
        window.open(assetUrl);
    }

    /**
     * On delete button clicked, open delete product dialog
     */
    public openDeleteDialog(): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete product',
            'Please confirm that you wish to delete this product.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteProduct();
        });
    }

    /**
     * Delete product, then navigate to products overview page
     */
    private deleteProduct(): void {
        this.dataSubscription = this.productService.deleteProduct(this.product._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    this.segmentService.track(ESegmentTrackEvents.TRACK_REMOVE_PRODUCT);
                    Toaster.success('Product removed');
                    this.onProductDeleted.next();
                },
                error: Toaster.handleApiError
            });
    }

    private getData(): void {
        const product$ = this.productService.getProductById(this.productId);
        const dataFields$ = this.dataFieldService.getAllDataFields(EDataFieldCollectionName.PRODUCT);

        this.dataSubscription = forkJoin([product$, dataFields$])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: ([product, dataFields]) => {
                    [this.product, this.dataFields] = [product, dataFields];
                    // Sort assets descending on updatedAt
                    this.productAssets = Array.from(this.product.assets || []).sort((asset1, asset2) => {
                        return asset2.updatedAt.getTime() - asset1.updatedAt.getTime();
                    });
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.router.navigate([AppConstants.MAIN_REDIRECT]);
                }
            });
    }

}
