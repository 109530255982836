import {Component, DestroyRef, inject, Inject, OnInit, Optional} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {IProductApiModel, ProductModel} from '../../models/api/product.model';
import {FormService} from '../../api/services/form.service';
import {UntypedFormGroup} from '@angular/forms';
import {ProductService} from '../../api/services/products.service';
import {Toaster} from '../../classes/toaster.class';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {AssetModel} from '../../models/api/asset.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {finalize, map, switchMap} from 'rxjs/operators';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {forkJoin, of} from 'rxjs';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {EDataFieldCollectionName} from '../../app.enums';
import {DataFieldModel} from '../../models/api/data-field.model';
import {EFormContext, FormDetailModel} from '../../models/api/form.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IProductFormData {
    product?: ProductModel;
}

@Component({
    selector: 'product-form',
    templateUrl: 'product-form.component.html',
    styleUrls: ['product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public TAB_PRODUCT = 0;
    public TAB_ASSETS = 1;

    public productTab = new TabBarItemModel('Product Information', this.TAB_PRODUCT);
    public assetsTab = new TabBarItemModel('Assets', this.TAB_ASSETS);
    public tabBarItems: TabBarItemModel[] = [
        this.productTab,
        this.assetsTab];
    private _selectedTab = this.tabBarItems[this.TAB_PRODUCT];
    public variants: VariantModel[];
    public formConfig: FormDetailModel;

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
        }
    }

    private nextTabAction(): void {
        this.selectedTab = this.tabBarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    private confirmAction: FullModalActionModel;
    private actions: FullModalActionModel[] = [];

    private confirmButton: ButtonConfig;

    /**
     * Responder to modal confirm button click. Initialized with 'go to next tab'
     */
    private onConfirmClicked = this.nextTabAction;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public product: ProductModel;
    public selectedAssets: AssetModel[];
    public dataFields: Record<string, any>;
    public productDataFields: DataFieldModel[];

    constructor(private formService: FormService,
                private fullModalService: FullModalService,
                private variantService: VariantService,
                private productService: ProductService,
                private dataFieldsService: DataFieldsApiService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) private modalData: IProductFormData) {}

    public ngOnInit(): void {
        forkJoin([
            this.variantService.getVariants(),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT),
            this.formService.getForms(EFormContext.PRODUCT, 1)
                .pipe(
                    map((result) => result.items[0]),
                    switchMap((form) =>
                        form ? this.formService.getForm(form._id) : of(null))
                )
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variants, dataFields, formConfig]) => {
                    this.variants = variants.items;
                    this.productDataFields = dataFields;
                    this.formConfig = formConfig;
                    this.confirmButton.disabled = false;
                },
                error: Toaster.handleApiError
            });

        this.initFormData();
        this.initButtons();
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.confirmButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onConfirmClicked());

        this.actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    private initFormData(): void {
        this.product = this.modalData?.product;
        this.selectedAssets = this.product?.assets || [];
        this.dataFields = this.product?.dataFields || {};
    }

    /**
     * If current tab is information text is Next and action is 'go to next tab'
     * Otherwise text is 'Save' and action is 'Save'
     */
    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_PRODUCT:
                this.confirmAction.button.text = 'Next';
                this.onConfirmClicked = this.nextTabAction;
                return;
            case this.TAB_ASSETS:
                this.confirmAction.button.text = 'Save';
                this.onConfirmClicked = this.onSaveButtonClicked;
                break;
        }
    }

    private onSaveButtonClicked(): void {
        if (this.form?.valid) {
            this.saveProduct();
        } else {
            Toaster.warn('Form contains errors. Please update incorrect and/or missing values');
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty());
        }
    }

    private saveProduct(): void {
        this.confirmButton.loading = true;
        const formattedForm = this.getUpdateBody(!!this.product);

        const observable = this.product ?
            this.productService.patchProduct(this.product._id, formattedForm) :
            this.productService.postProduct(formattedForm);

        observable
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    Toaster.success(this.product ?
                        'Product updated successfully' :
                        'Product added successfully');
                    this.fullModalService.close(result)
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * Returns all non-null values in form
     */
    private getUpdateBody(patch: boolean): IProductApiModel {
        try {
            const dataFields = DataFieldsComponentUtil.serializeDataFieldsForApiBody(
                DataFieldsComponentUtil.getBodyForDataFields(this.form.value.dataFields, patch), this.productDataFields);
            return {
                dataFields,
                assets: this.form.value.assets?.map((asset) => asset._id) || []
            };
        } catch (error) {
            Toaster.handleApiError(error);
        }
    }
}
