import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {WorkflowLayoutItem} from '../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {CustomWorkflowService} from '../custom-workflow.service';
import {takeUntil} from 'rxjs/operators';
import {EItemSelectionType, ItemSelectionState} from './custom-workflow-item-selection.component';

export class UpdatedWorkflowLayoutItemData {
    constructor(public workflowLayoutItem: WorkflowLayoutItem, public transitionItemId?: string) {
    }
}

@Injectable()
export class PublicationItemSelectionService implements OnDestroy {

    private selectedWorkflowLayoutItem = new BehaviorSubject<WorkflowLayoutItem>(null);
    public selectedWorkflowLayoutItem$ = this.selectedWorkflowLayoutItem.asObservable();

    private onNextClicked = new Subject<void>();
    public onNextClicked$ = this.onNextClicked.asObservable();

    private onBackClicked = new Subject<void>();
    public onBackClicked$ = this.onBackClicked.asObservable();

    private isFirstItem = new BehaviorSubject<boolean>(true);
    public isFirstItem$ = this.isFirstItem.asObservable();

    private isLastItem = new BehaviorSubject<boolean>(true);
    public isLastItem$ = this.isLastItem.asObservable();

    private updatedWorkflowLayoutItem = new Subject<UpdatedWorkflowLayoutItemData>();
    public updatedWorkflowLayoutItem$ = this.updatedWorkflowLayoutItem.asObservable();

    private onWorkflowLayoutItemsChanged = new Subject<void>();
    public onWorkflowLayoutItemsChanged$ = this.onWorkflowLayoutItemsChanged.asObservable();

    private redirectPublicationItemId = new BehaviorSubject<string>(null);

    private onNavigateToPublicationItem = new Subject<string>();
    public onNavigateToPublicationItem$ = this.onNavigateToPublicationItem.asObservable();

    // Item selection state
    private itemSelectionState = new BehaviorSubject<ItemSelectionState>(new ItemSelectionState());

    private itemSelectionType = new BehaviorSubject<EItemSelectionType>(EItemSelectionType.ITEM_SELECTION);
    public itemSelectionType$ = this.itemSelectionType.asObservable();

    private _itemsChanged: boolean;
    public set itemsChanged(value: boolean) {
        this._itemsChanged = value;
    }
    public get itemsChanged() {
        return this._itemsChanged;
    }

    private onDestroySubject = new Subject<void>();

    private subjects = [
        this.selectedWorkflowLayoutItem,
        this.onNextClicked,
        this.onBackClicked,
        this.updatedWorkflowLayoutItem,
        this.isFirstItem,
        this.isLastItem,
        this.onWorkflowLayoutItemsChanged,
        this.redirectPublicationItemId,
        this.onNavigateToPublicationItem,
        this.itemSelectionState,
        this.itemSelectionType
    ];

    constructor(private customWorkflowService: CustomWorkflowService) {
        this.customWorkflowService.activeStep$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.resetState());
    }

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public setSelectedWorkflowLayoutItem(workflowLayoutItem?: WorkflowLayoutItem, items: WorkflowLayoutItem[] = [], hasNext: boolean = false): void {
        this.selectedWorkflowLayoutItem.next(workflowLayoutItem);
        const isFirstItem = !workflowLayoutItem || workflowLayoutItem.index === items[0]?.index;
        this.isFirstItem.next(isFirstItem);
        const isLastItem = !workflowLayoutItem ||
            (items.findIndex((item) => item.index === workflowLayoutItem.index) === (items.length - 1) && !hasNext);
        this.isLastItem.next(isLastItem);
    }

    public getSelectedWorkflowLayoutItem(): WorkflowLayoutItem {
        return this.selectedWorkflowLayoutItem.getValue();
    }

    public setUpdatedWorkflowLayoutItem(data: UpdatedWorkflowLayoutItemData): void {
        this.updatedWorkflowLayoutItem.next(data);
    }

    public onNextClick(): void {
        this.onNextClicked.next();
    }

    public onBackClick(): void {
        this.onBackClicked.next();
    }

    public onWorkflowLayoutItemsChange(): void {
        this.onWorkflowLayoutItemsChanged.next();
    }

    public setRedirectPublicationItemId(itemId: string): void {
        this.redirectPublicationItemId.next(itemId);
    }

    public getRedirectPublicationItemId(): string {
        return this.redirectPublicationItemId.getValue();
    }

    public navigateToPublicationItem(publicationItemId: string): void {
        this.onNavigateToPublicationItem.next(publicationItemId);
    }

    public getItemSelectionState(): ItemSelectionState {
        return this.itemSelectionState.getValue();
    }

    public setItemSelectionState(state: ItemSelectionState): void {
        this.itemSelectionState.next(state);
    }

    public resetState(): void {
        const currentState = this.getItemSelectionState();
        const newState = new ItemSelectionState();
        newState.itemSelectionType = currentState.itemSelectionType;

        this.itemSelectionState.next(newState);
        this.selectedWorkflowLayoutItem.next(null);
    }

    public setItemSelectionType(itemSelectionType: EItemSelectionType): void {
        this.itemSelectionType.next(itemSelectionType);
    }
}
