import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {IndesignLibraryService} from '../../../../api/services/indesign-library.service';
import {AppConstants} from '../../../../app.constants';
import {IndesignLibraryModel} from '../../../../modules/format-rulesets/models/api/indesign-library.model';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {UsageModel} from '../../../../models/api/usage.model';
import {
    BUTTON_TYPE,
    EColumnSize,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {ILibraryFormData, LibraryFormComponent} from '../../../../forms/library-form/library-form.component';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {SortDirection} from '@angular/material/sort';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {NullUndefinedPipe} from '../../../../pipes/null-undefined.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {Subscription} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'library-overview',
    templateUrl: 'library-overview.component.html',
    styleUrls: ['library-overview.component.scss'],
    providers: [PaginatorService]
})

export class LibraryOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'library-items-overview-table';

    public items: IndesignLibraryModel[];
    public total: number;

    public sortProperty: string;
    public sortOrder: SortDirection;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage: boolean;

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Date modified',
        key: 'updatedAt',
        sortProperty: 'updatedAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT)
    }, {
        title: 'Created by',
        key: 'createdBy.fullName',
        format: (value) => NullUndefinedPipe.transform(value, NullUndefinedPipe.defaultValues.DELETED_USER),
        size: EColumnSize.BASE
    }];
    public actions: ITableAction[];
    public readonly ESelectionMode = ESelectionMode;
    public indesignLibrarySubject: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private indesignLibraryService: IndesignLibraryService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (this.pageSize !== pagination.pageSize || this.pageIndex !== pagination.pageIndex) {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;
                }

                this.updateUrl();

                this.getIndesignLibraries();
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_LIBRARY_ITEMS)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.DOWNLOAD];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_LIBRARY_ITEMS)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.EDIT) : [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_LIBRARY_ITEMS)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        const pageIndex = params['pageIndex'] && parseInt(params['pageIndex'], 10) > 0 ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : null;

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATES,
            AppConstants.CONTEXT_URL.INDESIGN_LIBRARIES,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder)]);
    }

    private getIndesignLibraries(): void {
        this.indesignLibrarySubject?.unsubscribe();

        this.indesignLibrarySubject = this.indesignLibraryService
            .getIndesignLibraries(this.pageSize, (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (result: ARPagedResponseDataModel<IndesignLibraryModel>) => {
                        ({items: this.items, total: this.total} = result);
                        this.disableNextPage = this.total <= this.pageIndex * this.pageSize;
                    },
                    error: Toaster.handleApiError
                }
            );
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const eventItem = event.item as IndesignLibraryModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.onDeleteLibraryClicked(eventItem);
        } else {
            this.indesignLibrarySubject = this.indesignLibraryService.getIndesignLibrary(eventItem._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((result: IndesignLibraryModel) => {
                    const library = result;
                    switch (event.action) {
                        case AppConstants.TABLE_ACTION_TYPES.DOWNLOAD:
                            window.open(library.files.library);
                            break;
                        case AppConstants.TABLE_ACTION_TYPES.EDIT:
                            this.openLibraryModal(library);
                            break;
                        default:
                            Toaster.notYetImplementedError();
                            break;
                    }
                }, Toaster.handleApiError);
        }
    }

    public onDeleteLibraryClicked(item: IndesignLibraryModel): void {
        this.indesignLibrarySubject = this.indesignLibraryService.getIndesignLibraryInUse(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (usageObject: UsageModel) => usageObject.usage ? this.openCannotDeleteDialog() : this.openDeleteDialog(item),
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                }
            );
    }

    private openCannotDeleteDialog(): void {
        const cannotDeleteDialogConfig = new NucDialogConfigModel('Cannot delete', 'The InDesign library is in use.');
        const cannotDeleteDialog = this.dialogService.openDialog(cannotDeleteDialogConfig);
        cannotDeleteDialogConfig.addAction('Close', BUTTON_TYPE.SECONDARY).subscribe(() => cannotDeleteDialog.close());
    }

    private openDeleteDialog(item: IndesignLibraryModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete InDesign library',
            `Please confirm that you wish to delete ${item.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteLibraryItem(item);
        });
    }

    private reload(): void {
        this.setPageIndex(1);
        if (this.pageIndex !== 1) {
            this.updateUrl();
        }
    }

    private deleteLibraryItem(item: IndesignLibraryModel): void {
        this.indesignLibrarySubject = this.indesignLibraryService.deleteIndesignLibrary(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Successfully deleted InDesign library');
                    this.reload();
                }, error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        const library = event.item as IndesignLibraryModel;
        this.router.navigate([AppConstants.CONTEXT_URL.TEMPLATES, AppConstants.CONTEXT_URL.INDESIGN_LIBRARIES, library._id]);
    }

    public openLibraryModal(library?: IndesignLibraryModel): void {
        const config = library
            ? new FullModalConfig(
                'Edit InDesign library',
                'You can edit the information of InDesign library or upload a new file.',
                {library} as ILibraryFormData)
            : new FullModalConfig('Add new InDesign library', 'Add a new InDesign library by uploading it.', {});

        config.confirmClose = true;
        this.fullModalService.open(LibraryFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                !!result.jobId ? this.monitorJob(result.jobId) : this.getIndesignLibraries();
            }
        });
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getJobMonitor(jobId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.getIndesignLibraries();
                }
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

}
