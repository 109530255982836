import {FieldPropertyType, RulePropertyModel} from '../api/rule-property.model';

export class PropertyValueModel {
    private _property: RulePropertyModel;

    constructor(public path: string, property: RulePropertyModel, public properties: FieldPropertyType[]) {
        this.path = path;
        this._property = property;
    }

    public get isArray() {
        if (this._property instanceof RulePropertyModel) return !!this._property.isArray;
    }

    public get arrayIndexSelected() {
        const propertyIndex = this.properties.findIndex(property => property === this.property);
        if (propertyIndex === -1) return false;

        return this.isArray && this.properties[propertyIndex + 1] !== null && this.properties[propertyIndex + 1] !== undefined;
    }

    public get type() {
        if (this._property instanceof RulePropertyModel) return this._property.type;
    }

    public get dataType() {
        if (this._property instanceof RulePropertyModel) return this._property.dataType;
    }

    public get enableVariants() {
        if (this._property instanceof RulePropertyModel) return !!this._property.enableVariants;
    }

    public get property(): RulePropertyModel {
        if (this._property instanceof RulePropertyModel) return this._property;
    }

    public set property(property: FieldPropertyType) {
        if (property instanceof RulePropertyModel) {
            this._property = property;
        } else {
            this._property = null;
        }
    }
}
