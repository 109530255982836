import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ProductModel} from '../../../models/api/product.model';
import {AppConstants} from '../../../app.constants';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {ISortOptionEvent, ITableColumn, ITableItem} from '@relayter/rubber-duck';
import {ProductService} from '../../../api/services/products.service';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../../app.enums';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {takeUntil, tap} from 'rxjs/operators';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AdvancedFiltersDataService} from '../../../api/services/advanced-filters.data-service';

import {CAMPAIGN_ITEM_PRODUCTS_SELECTED_ICON_COLUMN, CAMPAIGN_ITEM_PRODUCTS_TABLE_DEFAULT_COLUMNS} from './campaign-item-products-table-columns';
import {DataFieldsComponentUtil} from '../../../classes/data-fields-component.util';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DataFilterModel} from '../../../models/ui/data-filter.model';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {DataFieldModel} from '../../../models/api/data-field.model';

@Component({
    selector: 'campaign-item-products-form',
    templateUrl: './campaign-item-products-form.component.html',
    styleUrls: ['./campaign-item-products-form.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class CampaignItemProductsFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'campaign-item-products-table';
    @Input() public readonly = false;
    @Input() public form: UntypedFormGroup;

    @Input() public selectedProducts: ProductModel[];
    @Output() public selectedProductsChange = new EventEmitter<ProductModel[]>();

    public EDataFieldCollectionName = EDataFieldCollectionName;

    private onDestroySubject = new Subject<void>();

    public productsSubscription: Subscription;

    public products: ProductModel[] = [];
    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public disableNextPage: boolean = true;
    public pageSizeOptions: DropdownItem<number>[] =
        AppConstants.PAGE_SIZE_OPTIONS.map((pageSize) => new DropdownItem(`${pageSize}`, pageSize));
    public disableSort = false;

    public allColumns: ITableColumn[];
    public allSelectedColumns: ITableColumn[];

    public searching: boolean = false;

    public dataFilters: DataFilterModel[] = [new DataFilterModel('RAN', 'ran', EDataFieldTypes.STRING)];

    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    constructor(private productsService: ProductService,
                private dataFieldsService: DataFieldsApiService,
                private filtersDataService: AdvancedFiltersDataService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);

        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        this.initForm();

        this.getTableData();

        this.filtersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((values) => {
                this.filterValues = values;

                this.paginatorService.setPageIndex(this.tableId, 1);
            });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.newApiCursor.reset(this.pageIndex, this.tableSortOptions);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getProducts();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        this.form.contains('products') ?
            this.selectedProducts = this.form.get('products').value :
            this.form.addControl('products', new UntypedFormControl(this.selectedProducts));
    }

    private getTableData(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (dataFields) => {
                    this.initializeTable(dataFields);
                    this.paginatorService.setPageIndex(this.tableId, 1);
                },
                error: (err) => Toaster.handleApiError(err)
            });
    }

    private initializeTable(dataFields: DataFieldModel[]): void {
        this.allColumns = [
            ...CAMPAIGN_ITEM_PRODUCTS_TABLE_DEFAULT_COLUMNS,
            ...this.dataFieldsComponentUtil.getDataFieldsColumnSelection(dataFields)
        ];

        this.tableSortOptions.columns = [this.allColumns.find((column) => column.key === 'updatedAt')];
        this.tableSortOptions.sortOrder = 'desc';

        this.allSelectedColumns = [...this.allColumns, CAMPAIGN_ITEM_PRODUCTS_SELECTED_ICON_COLUMN]
            .map(column => ({...column, sortProperty: undefined}));
    }

    private getProducts(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PRODUCTS)) {
            const cursor = this.newApiCursor.getCursor(this.pageIndex);

            // prevent showing results from older slower calls
            if (this.productsSubscription) {
                this.productsSubscription.unsubscribe();
            }

            this.productsSubscription = this.productsService.getData(
                this.tableSortOptions.sortOrder === 'desc', !this.searchValue ? this.tableSortOptions.sortPropertiesAsString : null, this.searchValue,
                this.pageSize, 0, this.filterValues, cursor)
                .pipe(
                    tap(() => {
                        this.searching = !!this.searchValue || (this.filterValues && Object.values(this.filterValues).length > 0);
                    }),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(
                    (result: ARPagedResponseDataModel<ProductModel>) => {
                        this.products = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (result.items.length > 0) {
                            const item = this.products[this.products.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    (err: ARApiError) => Toaster.handleApiError(err)
                );
        }
    }

    public onSelectionChanged(selectedProducts: ITableItem[]): void {
        const products = selectedProducts as ProductModel[];
        this.selectedProductsChange.emit(products);
        this.form.get('products').setValue(products);
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;
            this.disableSort = !!this.searchValue;

            this.paginatorService.setPageIndex(this.tableId, 1);
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.newApiCursor.reset(1, this.tableSortOptions);
        this.paginatorService.setPageIndex(this.tableId, 1);
    }
}
