<form *ngIf="formGroup" [formGroup]="formGroup" class="container">
    <div class="step">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Icon">
            <nuc-dropdown formControlName="icon"
                          [nullOption]="false"
                          [searchable]="true"
                          (requestData)="searchIcons($event)"
                          [total]="nucIcons.length"
                          [items]="nucIconOptions"
                          placeholder="Select an icon">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Permissions">
            <nuc-dropdown-multiselect
                formControlName="permissions"
                [items]="permissionOptions"
                placeholder="Select permissions">
            </nuc-dropdown-multiselect>
        </nuc-form-field>

        <div formGroupName="schedule">
            <nuc-form-field label="Schedule (hours before deadline)">
                <nuc-input
                    type="number"
                    [min]="0"
                    formControlName="hours"
                    placeholder="Hours before deadline, items should be past this step">
                </nuc-input>
            </nuc-form-field>
        </div>
    </div>
    <div class="deadline-notifications" *ngIf="formGroup.controls.schedule.controls.hours.value > 0">
        <h1>Deadline notifications</h1>
        <p>Optional, set up notifications so users can know in time when a deadline is approaching or has been
            expired.</p>
        <hr/>

        <ng-container *ngFor="let control of notificationsFormArray?.controls; let index = index">
            <div class="notification" [formGroup]="control">
                <div>Notification #{{ index + 1 }}</div>
                <nuc-button-secondary icon="nucicon_trash_fill" class="delete-button"
                                      (click)="deleteNotification(index)">
                </nuc-button-secondary>
                <nuc-form-field label="Hours">
                    <nuc-input
                        formControlName="hours"
                        type="number"
                        [min]="0"
                        placeholder="Hours before or after the deadline">
                    </nuc-input>
                </nuc-form-field>
                <nuc-form-field label="Before or after deadline">
                    <nuc-dropdown formControlName="alertType"
                                  [nullOption]="false"
                                  [total]="alertTypeOptions.length"
                                  [items]="alertTypeOptions"
                                  placeholder="Select an alert type">
                    </nuc-dropdown>
                </nuc-form-field>
                <nuc-form-field label="Message" class="message">
                    <nuc-textarea formControlName="message"
                                  placeholder="Notification message">
                    </nuc-textarea>
                </nuc-form-field>

                <div class="receivers">
                    <h2>Receiver(s)</h2>
                    <p>Who should receive the notification</p>
                    <ng-container *ngFor="let rControl of getReceiversFormArray(index)?.controls; let rIndex = index">
                        <div class="receiver" [formGroup]="rControl">
                            <div>Receiver #{{ rIndex + 1 }}</div>
                            <nuc-button-secondary icon="nucicon_trash_fill" class="delete-button"
                                                  (click)="deleteReceiver(index, rIndex)">
                            </nuc-button-secondary>
                            <nuc-form-field label="Receiver type">
                                <nuc-dropdown formControlName="type"
                                              [nullOption]="false"
                                              [total]="receiverTypeOptions.length"
                                              [items]="receiverTypeOptions"
                                              (dataChange)="typeChanged($event, index, rIndex)"
                                              placeholder="Select a receiver type">
                                </nuc-dropdown>
                            </nuc-form-field>
                            <ng-container [ngSwitch]="rControl.controls.type.value?.getValue()">
                                <nuc-form-field label="Value" *ngSwitchCase="EReceiverType.ROLE">
                                    <nuc-dropdown-multiselect formControlName="roles"
                                                              [items]="roleOptions"
                                                              placeholder="Select role(s)">
                                    </nuc-dropdown-multiselect>
                                </nuc-form-field>
                                <nuc-form-field label="Value" *ngSwitchCase="EReceiverType.MAIL">
                                    <nuc-suggestive-input
                                        formControlName="emails"
                                        [options]="[]"
                                        placeholder="Add e-mail addresses">
                                    </nuc-suggestive-input>
                                </nuc-form-field>
                                <nuc-form-field label="Value" *ngSwitchCase="EReceiverType.PROPERTY">
                                    <property-control
                                        formControlName="property"
                                        [ruleProperties]="propertyOptions"
                                        [variantRequired]="true"
                                        [variants]="variants"/>
                                </nuc-form-field>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="add-button">
                        <nuc-button-secondary icon="nucicon_add" (click)="addReceiver(index)" nucTooltip="Add receiver">
                        </nuc-button-secondary>
                    </div>
                </div>
                <div class="properties">
                    <h2>Details</h2>
                    <p>Define the data that you wish to show in the email</p>
                    <ng-container *ngFor="let pControl of getPropertiesFormArray(index)?.controls; let rIndex = index">
                        <div class="property" [formGroup]="pControl">
                            <div>Property #{{ rIndex + 1 }}</div>
                            <nuc-button-secondary icon="nucicon_trash_fill" class="delete-button"
                                                  (click)="deleteProperty(index, rIndex)">
                            </nuc-button-secondary>
                            <nuc-form-field label="Label">
                                <nuc-input formControlName="label"
                                           placeholder="Fill in a label">
                                </nuc-input>
                            </nuc-form-field>
                            <nuc-form-field label="Value">
                                <property-control
                                    formControlName="property"
                                    [ruleProperties]="propertyOptions"
                                    [variantRequired]="true"
                                    [variants]="variants"/>
                            </nuc-form-field>
                        </div>
                    </ng-container>
                    <div class="add-button">
                        <nuc-button-secondary icon="nucicon_add" (click)="addProperty(index)" nucTooltip="Add property">
                        </nuc-button-secondary>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="add-button">
            <nuc-button-secondary text="Add notification" (click)="addNotification()" nucTooltip="Add notification">
            </nuc-button-secondary>
        </div>
        <nuc-empty-state-component *ngIf="notificationsFormArray.controls.length === 0"
                                   [small]="true"
                                   title="No notifications added yet"
                                   imageUrl="/assets/images/general_empty_state.svg">
        </nuc-empty-state-component>
    </div>
</form>
