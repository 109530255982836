import {inject, Injectable} from '@angular/core';
import {TableDataProvider} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {NewCursorArray} from '../../api/new-api-cursor';
import {TableSortOptions} from '../../api/table-sort-options';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../classes/toaster.class';
import {finalize} from 'rxjs/operators';

@Injectable()
export class BriefingDataProvider extends TableDataProvider<CampaignItemModel> {
    private campaignItemsApiService = inject(CampaignItemsApiService);
    private apiCursor: NewCursorArray;
    sortOptions: TableSortOptions;
    phraseValue: string;
    public campaignId: string;
    public publicationId: string;
    public assignableItems: boolean;

    constructor() {
        super();

        // Init api cursor
        this.apiCursor = new NewCursorArray(1, this.sortOptions);
    }

    public retrieveData(): void {
        this.loading = true;

        this.campaignItemsApiService.getCampaignItems(this.campaignId, this.publicationId,
            this.pageSize, 0, this.phraseValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(this.pageIndex),
            this.assignableItems)
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public resetCursorArray(pageIndex = this.pageIndex): void {
        this.apiCursor.reset(pageIndex, this.sortOptions);
    }

    private setCursor(): void {
        this.apiCursor.setCursor(this.pageIndex, this.data[this.data.length - 1]);
    }
}
