import {Deserialize, INewable, ISerializable, Serialize} from 'cerialize';
import {UserService} from './users.service';
import {Injectable} from '@angular/core';
import {ARLogger} from '@relayter/core';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsStorageService {
    private static tryParseJson(jsonString: string): Record<string, any> | void {
        let jsonObject;
        try {
            jsonObject = JSON.parse(jsonString);
        } catch (err) {
            ARLogger.error(`Handled JSON parse error: ${err.name}: ${err.message}`);
        }
        return jsonObject;
    }

    constructor(private userService: UserService) {}

    public getPrefixStorageKey(): string {
        const userId = this.userService.getLoggedInUser()?._id;
        if (!userId) {
            ARLogger.error('No logged in user available for prefix storage key. Will use shared key \'temp_user\'');
            return 'relayter.temp_user';
        }
        return `relayter.${userId}`;
    }

    private getUserStorageKey(key: string): string {
        const prefix = this.getPrefixStorageKey();
        if (!prefix) return;

        return `${prefix}.${key}`;
    }

    /**
     * Store settings for the logged in user.
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public storeSettings<T>(key: string, data: T, type?: Function|INewable<T>|ISerializable): void {
        const userKey = this.getUserStorageKey(key);
        if (!userKey) return;

        const jsonObject = type ? Serialize(data, type) : data;
        localStorage.setItem(userKey, JSON.stringify(jsonObject));
    }

    /**
     * Load settings for the logged in user for the given key.
     * Optional to deserialize to a specific type
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public loadSettings<T>(key: string, type?: Function|INewable<T>|ISerializable): any {
        const userKey = this.getUserStorageKey(key);
        if (!userKey) return;

        const jsonObject = UserSettingsStorageService.tryParseJson(localStorage.getItem(userKey));
        if (type) {
            return Deserialize(jsonObject, type);
        }

        return jsonObject;
    }
}
