import {RLBaseComponent} from './rl-base.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {ITableConfigStorage} from '../../api/services/table-config.service';
import {TableSortOptions} from '../../api/table-sort-options';
import {SortDirection} from '@angular/material/sort';
import {NewCursorArray} from '../../api/new-api-cursor';

export abstract class RLTableComponent extends RLBaseComponent implements ITableConfigStorage {
    public abstract tableId: string;
    public readonly storageKey: string;
    public searchValue: string = '';
    // @deprecated Use tableSortOptions
    public sortProperty: string;
    // @deprecated Use tableSortOptions
    public sortOrder: SortDirection;
    public tableSortOptions: TableSortOptions = new TableSortOptions();
    public newApiCursor = new NewCursorArray(1, this.tableSortOptions);
    // Filters setup
    public filterValues: Record<string, any>;

    protected constructor(private userSettingsStorageService: UserSettingsStorageService) {
        super();
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }
}
