import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {BooleanOption, ConditionsConstants, ConditionType, PropertyOperator} from './conditions.constants';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {Subscription} from 'rxjs';
import {EBooleanText, EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ConditionBaseType, ConditionValueType, RuleConditionModel} from '../../models/api/rule-condition.model';
import {VariantModel} from '../../models/api/variant.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {StringUtil} from '../../classes/string-util';
import {RulePropertyUtil} from '../../classes/rule-property.util';

export class ConditionForm {
    property: FormControl<RulePropertyModel>;
    variant: FormControl<VariantModel>;
    operator?: FormControl<PropertyOperator>;
    type: FormControl<ConditionType>;
    value: FormControl<ConditionValueType>;
}

@Component({
    selector: 'condition-form',
    templateUrl: './condition-form.component.html',
    styleUrls: ['./condition-form.component.scss']
})
export class ConditionFormComponent implements OnInit, OnDestroy {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public condition: RuleConditionModel;
    @Input() public ruleProperties: RulePropertyModel[];
    @Input() public variants: VariantModel[];
    @Output() public deleteClicked = new EventEmitter<void>();

    public conditionTypes = ConditionType.TYPES;
    public operators = PropertyOperator.OPERATORS;
    public inputType: string;
    public dropdownItems: IDropdownItem<ConditionBaseType>[];
    public propertyOptions: RulePropertyModel[];

    private propertySubscription: Subscription;
    private operatorSubscription: Subscription;
    private typeSubscription: Subscription;

    public get propertyControl(): AbstractControl {
        return this.formGroup.get('property');
    }

    public get variantControl(): AbstractControl {
        return this.formGroup.get('variant');
    }

    public get operatorControl(): AbstractControl {
        return this.formGroup.get('operator');
    }

    public get typeControl(): AbstractControl {
        return this.formGroup.get('type');
    }

    public get valueControl(): AbstractControl {
        return this.formGroup.get('value');
    }

    public ngOnInit(): void {
        this.propertyOptions = this.ruleProperties;
        this.initForm();
    }

    private initForm(): void {
        if (this.propertyControl.value?.isArray) {
            this.operatorControl.enable();
        } else {
            this.operatorControl.disable();
        }
        if (this.typeControl?.value?.valueRequired) {
            this.valueControl.enable();
        } else {
            this.valueControl.disable();
        }

        if (this.propertyControl.value?.enableVariants) {
            this.variantControl.enable();
        } else {
            this.variantControl.disable();
        }

        // Prepare input based on current values
        this.setTypes();
        this.setInputType();

        // Subscribe to value change of the property to update inputType
        this.propertySubscription = this.propertyControl.valueChanges
            .subscribe((property: RulePropertyModel) => {
                // When changing to enum
                const isDropDownValue = property?.dataType.type === EDataFieldTypes.ENUM ||
                    property?.dataType.type === EDataFieldTypes.BOOLEAN;
                if (isDropDownValue || (this.inputType === 'dropdown' && !isDropDownValue)) {
                    this.valueControl.patchValue(null);
                }

                if (property?.isArray) {
                    this.operatorControl.enable();
                } else {
                    this.operatorControl.patchValue(null);
                    this.operatorControl.disable();
                }

                if (property?.enableVariants) {
                    this.variantControl.enable();
                } else {
                    this.variantControl.patchValue(null);
                    this.variantControl.disable();
                }

                this.setTypes();
                this.setInputType();

                if (!property?.isArray && this.conditionTypes?.length) {
                    this.typeControl.enable();
                }
            });

        // Subscribe to value change of the property to update inputType
        this.operatorSubscription = this.operatorControl.valueChanges
            .subscribe((operator: PropertyOperator) => {
                // Reset value if no longer compatible
                let value = this.valueControl.value;
                let type = this.typeControl.value;
                switch (operator) {
                    case PropertyOperator.LENGTH:
                        value = parseInt(value, 10);
                        value = isNaN(value) ? null : value;
                        break;
                    case PropertyOperator.EVERY:
                    case PropertyOperator.SOME:
                        const property = this.propertyControl.value;
                        if (property?.dataType?.type !== EDataFieldTypes.ENUM) {
                            value = value?.toString();
                        } else if (!(value instanceof DropdownItem)) {
                            value = null;
                        }
                        break;
                    case PropertyOperator.EXISTS:
                    case PropertyOperator.NOT_EXISTS:
                    default:
                        value = null;
                        type = null;
                        break;
                }
                this.valueControl.patchValue(value);
                this.typeControl.patchValue(type);
                this.setTypes();
                if (!this.conditionTypes.find(conditionType => this.typeControl.value === conditionType)) {
                    this.typeControl.patchValue(null);
                }
                this.setInputType();
                if (operator?.typeRequired) {
                    this.typeControl.enable();
                } else {
                    this.typeControl.patchValue(null);
                    this.typeControl.disable();
                }
            });

        // Subscribe to value change of the type to update and enable/disable inputType
        this.typeSubscription = this.typeControl.valueChanges.subscribe((type: ConditionType) => {
            if (type?.valueRequired) {
                this.valueControl.enable();
            } else {
                this.valueControl.patchValue(null);
                this.valueControl.disable();
            }

            this.setInputType();
        });
    }

    public ngOnDestroy(): void {
        this.propertySubscription.unsubscribe();
        this.operatorSubscription.unsubscribe();
        this.typeSubscription.unsubscribe();
    }

    public setDropdownItems(dataType: string, enumList?: string[]): void {
        switch (dataType) {
            case EDataFieldTypes.ENUM:
                this.dropdownItems = enumList.map((item) => new DropdownItem(item, item));
                break;
            case EDataFieldTypes.BOOLEAN:
                this.dropdownItems = BooleanOption.OPTIONS;
                break;
            default:
                this.dropdownItems = [];
                break;
        }
    }

    /**
     * Set possible typeOperators that can be used for selected property dataType
     */
    public setTypes(): void {
        let property: string;

        switch (this.operatorControl?.value) {
            case PropertyOperator.LENGTH:
                property = ConditionsConstants.DATA_TYPE_LENGTH;
                break;
            case PropertyOperator.EXISTS:
            case PropertyOperator.NOT_EXISTS:
                property = null;
                break;
            case PropertyOperator.EVERY:
            case PropertyOperator.SOME:
                property = this.propertyControl.value?.dataType.type;
                break;
            default:
                if (!this.propertyControl.value?.isArray) {
                    property = this.propertyControl.value?.dataType.type;
                }
                break;
        }

        this.conditionTypes = ConditionType.getTypesForProperty(property);
        // No conditions, no value
        if (this.conditionTypes.length === 0) {
            this.valueControl.patchValue(null);
        }
    }

    /**
     * Set input type of the value fields based on the property datatype, operator and condition type
     */
    public setInputType(): void {
        const property = this.propertyControl.value;
        const operator = this.operatorControl.value;
        const type = this.typeControl.value;

        // Reset input type if selected type is not selectable
        if (!this.conditionTypes.find(conditionType => conditionType === type)) {
            this.inputType = null;
            return;
        }

        switch (type) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
                this.inputType = EDataFieldTypes.NUMBER;
                break;
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
                this.inputType = property?.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : EDataFieldTypes.NUMBER;
                break;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
                if (operator === PropertyOperator.LENGTH) {
                    this.inputType = EDataFieldTypes.NUMBER;
                } else {
                    const propertyDataType = property?.dataType?.type;
                    if (propertyDataType === EDataFieldTypes.ENUM) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.ENUM, property.dataType.enumeration?.items);
                    } else if (propertyDataType === EDataFieldTypes.BOOLEAN) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.BOOLEAN);
                    } else if (propertyDataType === EDataFieldTypes.LIST) {
                        this.inputType = operator ? EDataFieldTypes.STRING : null;
                    } else if (propertyDataType === ConditionsConstants.DATA_TYPE_LENGTH) {
                        // Normally, propertyDataType will only be length for array operator length
                        this.inputType = EDataFieldTypes.NUMBER;
                    } else {
                        this.inputType = propertyDataType;
                    }
                }
                break;
            case ConditionType.NOT_STARTS_WITH:
            case ConditionType.STARTS_WITH:
                this.inputType = EDataFieldTypes.STRING;
                break;
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
            default:
                this.inputType = null;
                break;
        }

        // Update validators, based on the inputType
        if (this.inputType) {
            if (this.inputType === EDataFieldTypes.NUMBER) {
                this.valueControl.setValidators(RLValidatorConstants.VALIDATOR_SETS.REQUIRED_NUMBER);
            } else {
                this.valueControl.setValidators(RLValidatorConstants.VALIDATOR_SETS.REQUIRED);
            }
        } else {
            this.valueControl.clearValidators();
        }
        // Apply the new validators
        this.valueControl.updateValueAndValidity();
    }

    public searchProperties(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.propertyOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.propertyOptions = this.ruleProperties.filter((ruleProperty) =>
                ruleProperty.name.match(regex)?.length > 0);
        } else {
            this.propertyOptions = this.ruleProperties;
        }
    }

    public static createForm(): FormGroup<ConditionForm> {
        const propertyControl = new FormControl<RulePropertyModel>(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED);
        const variantControl = new FormControl<VariantModel>({value: null, disabled: true}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED);
        const operatorControl = new FormControl<PropertyOperator>({value: null, disabled: true}, [(control: AbstractControl) => {
            if (propertyControl.value?.isArray && !control.value) {
                return {valueRequired: 'Operator is required for this property'};
            }

            return null;
        }]);
        const typeControl = new FormControl<ConditionType>(null, [(control: AbstractControl) => {
            if (operatorControl.value?.typeRequired && !control.value) {
                return {valueRequired: 'Type is required for this operator'};
            }

            return null;
        }]);
        // If a control is disabled, it is skipped in the validation
        const valueControl = new FormControl<ConditionValueType>({value: '', disabled: true},
            [...RLValidatorConstants.VALIDATOR_SETS.REQUIRED, (control: AbstractControl) => {
                if (typeControl.value?.valueRequired && (control.value?.value === undefined || control.value?.value === null ||
                    control.value?.value === '')) {
                    return {valueRequired: 'Value is required for this type'};
                }

                return null;
            }
            ]);

        const formGroup = new FormGroup<ConditionForm>({
            property: propertyControl,
            variant: variantControl,
            operator: operatorControl,
            type: typeControl,
            value: valueControl
        });
        formGroup.setValidators(RLValidatorConstants.VALIDATOR_SETS.REQUIRED);

        return formGroup;
    }

    public static getPatchValue(condition: RuleConditionModel, variants: VariantModel[], ruleProperties: RulePropertyModel[]): Record<string, any> {
        const {ruleProperty, variant} =
            RulePropertyUtil.findRulePropertyAndVariantByPath(condition.property, variants, ruleProperties);

        const operator = PropertyOperator.getByValue(condition.operator);
        const type = ConditionType.getByValue(condition.type);
        const value = ConditionFormComponent.getConditionValue(condition, ruleProperty?.dataType.type);

        return {property: ruleProperty, operator, type, value, variant};
    }

    /**
     * Get condition value and put it in a dropdown item if needed.
     */
    private static getConditionValue(condition: RuleConditionModel, dataType?: EDataFieldTypes): ConditionValueType {
        switch (condition?.type) {
            case ConditionType.LEADING_LENGTH.getValue():
            case ConditionType.LEADING_LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH.getValue():
            case ConditionType.LOWER_OR_EQUAL.getValue():
            case ConditionType.LOWER_THAN.getValue():
            case ConditionType.GREATER_OR_EQUAL.getValue():
            case ConditionType.GREATER_THAN.getValue():
            case ConditionType.NOT_STARTS_WITH.getValue():
            case ConditionType.STARTS_WITH.getValue():
                return condition.value;
            case ConditionType.NOT_EQUALS.getValue():
            case ConditionType.EQUALS.getValue():
                // Prevent compiler error, but value is always not a Date for type ENUM
                if (dataType === EDataFieldTypes.ENUM && !(condition.value instanceof Date)) {
                    if (condition.operator === PropertyOperator.LENGTH.getValue()) {
                        return condition.value;
                    } else {
                        return new DropdownItem(condition.value as string, condition.value as ConditionBaseType);
                    }
                } else if (dataType === EDataFieldTypes.BOOLEAN) {
                    return new DropdownItem(!!condition.value ? EBooleanText.TRUE : EBooleanText.FALSE,
                        !!condition.value);
                } else {
                    return condition.value;
                }
            case ConditionType.EXISTS.getValue():
            case ConditionType.NOT_EXISTS.getValue():
                break;
        }
    }
}
