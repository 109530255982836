<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <div class="row">
        <div class="left">
            <p class="title">
                #{{valueIndex + 1}}
            </p>
        </div>
        <div class="right">
            <ng-content/>
            <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteClicked.emit()"/>
        </div>
    </div>

    <div class="row">
        <nuc-form-field label="Property" class="column property-control">
            <property-control
                formControlName="property"
                [ignoreIndex]="false"
                [arrayIndexRequired]="false"
                [controlType]="EControlType.CONDITION"
                [ruleProperties]="ruleProperties" />
        </nuc-form-field>

        <nuc-form-field *ngIf="operators.length" label="Operator" class="column">
            <nuc-dropdown [items]="operators" [total]="operators.length" formControlName="operator" placeholder="Choose the operator" />
        </nuc-form-field>

        <div *ngIf="conditionTypes?.length" class="type column">
            <nuc-form-field label="Type">
                <nuc-dropdown [items]="conditionTypes" [total]="conditionTypes.length" formControlName="type" placeholder="Choose type" />
            </nuc-form-field>
            <nuc-form-field label="Value type" *ngIf="typeValueRequired()">
                <nuc-dropdown [items]="valueTypes" [total]="valueTypes.length" [nullOption]="false" formControlName="typeValue" placeholder="Choose value type" />
            </nuc-form-field>
        </div>

        <nuc-form-field *ngIf="inputType" [ngSwitch]="inputType" label="Value" class="column" [ngClass]="{'property-control': inputType === 'property'}">
            <nuc-input *ngSwitchCase="'number'" formControlName="value" placeholder="Define the number value" type="number"/>
            <nuc-input *ngSwitchCase="'string'" formControlName="value" placeholder="Define the string value" type="string"/>
            <nuc-regex-input *ngSwitchCase="'regex'" formControlName="value" placeholder="Define the regular expression" type="string"/>
            <nuc-dropdown  *ngSwitchCase="'dropdown'"
                           [items]="dropdownItems"
                           [total]="dropdownItems.length"
                           formControlName="value"
                           [placeholder]="'Select option'"/>
            <nuc-datepicker *ngSwitchCase="'date'" formControlName="value"/>
            <property-control *ngSwitchCase="'property'"
                              formControlName="valueProperty"
                              [controlType]="EControlType.VALUE"
                              [ruleProperties]="valuePropertyRuleProperties" />
            <nuc-error *ngIf="formGroup.controls.value.touched && formGroup.errors?.invalidRegex">{{formGroup.errors.invalidRegex}}</nuc-error>
        </nuc-form-field>
    </div>
</form>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
