import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA, NucIcons} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    CustomWorkflowStepModel,
    NotificationPropertyModel,
    NotificationReceiverModel,
    ScheduleNotificationModel
} from '../../models/api/custom-workflow-step.model';
import {Toaster} from '../../classes/toaster.class';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {PermissionsService} from '../../api/services/permissions.service';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {StringUtil} from '../../classes/string-util';
import {RolesService} from '../../api/services/roles.service';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {PropertyControlComponent} from '../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../models/ui/property-value.model';

export interface IWorkflowConfigurationStepFormData {
    workflowConfigurationId: string;
    workflowConfigurationStep: CustomWorkflowStepModel;
}

export enum EAlertType {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER'
}

export enum EReceiverType {
    ROLE = 'ROLE',
    MAIL = 'MAIL',
    PROPERTY = 'PROPERTY'
}

class StepForm {
    name: FormControl<string>;
    icon: FormControl<DropdownItem<string>>;
    permissions: FormControl<DropdownItem<string>[]>;
    schedule: FormGroup<ScheduleForm>;
}

class ScheduleForm {
    hours: FormControl<number>;
    notifications: FormArray<FormGroup<NotificationForm>>;
}

class NotificationForm {
    hours: FormControl<number>;
    alertType: FormControl<DropdownItem<EAlertType>>;
    message: FormControl<string>;
    receivers: FormArray<FormGroup<ReceiverForm>>;
    properties:  FormArray<FormGroup<PropertyForm>>;
}

class ReceiverForm {
    type: FormControl<DropdownItem<EReceiverType>>;
    roles?: FormControl<DropdownItem<string>[]>;
    emails?: FormControl<DropdownItem<string>[]>;
    property?: FormControl<PropertyValueModel>;
}

class PropertyForm {
    label: FormControl<string>;
    property: FormControl<PropertyValueModel>;
}

@Component({
    selector: 'workflow-configuration-step-form-component',
    templateUrl: 'workflow-configuration-step-form.component.html',
    styleUrls: ['workflow-configuration-step-form.component.scss']
})
export class WorkflowConfigurationStepFormComponent implements OnInit, OnDestroy {
    protected readonly EReceiverType = EReceiverType;
    public readonly nucIcons: DropdownItem<string>[];
    public permissionOptions: DropdownItem<string>[] = [];
    public nucIconOptions: DropdownItem<string>[] = [];
    public propertyOptions: RulePropertyModel[] = [];
    public formGroup: FormGroup<StepForm>;

    private saveButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();
    private workflowConfigurationId: string;
    protected variants: VariantModel[];

    private workflowConfigurationStep: CustomWorkflowStepModel;
    public roleOptions: DropdownItem<string>[] = [];
    public alertTypeOptions: DropdownItem<EAlertType>[] = [
        new DropdownItem('Before deadline', EAlertType.BEFORE),
        new DropdownItem('After deadline', EAlertType.AFTER)
    ]

    public receiverTypeOptions: DropdownItem<EReceiverType>[] = [
        new DropdownItem('Role', EReceiverType.ROLE),
        new DropdownItem('E-mail', EReceiverType.MAIL),
        new DropdownItem('Publication item property', EReceiverType.PROPERTY)
    ]

    get notificationsFormArray() {
        return this.formGroup.controls.schedule.controls.notifications as FormArray<FormGroup<NotificationForm>>;
    }

    getReceiversFormArray(index: number) {
        return this.notificationsFormArray.at(index)?.controls.receivers as FormArray<FormGroup<ReceiverForm>>;
    }

    getPropertiesFormArray(index: number) {
        return this.notificationsFormArray.at(index)?.controls.properties as FormArray<FormGroup<PropertyForm>>;
    }

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private permissionService: PermissionsService,
                private rolesService: RolesService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationStepFormData) {
        this.nucIcons = [...NucIcons].sort().map(nucIcon =>
            new DropdownItem(
                nucIcon,
                `nucicon_${nucIcon}`,
                null,
                `nucicon_${nucIcon}`
            ));
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationStep());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initForm(): void {
        const stepIcon = this.nucIcons.find(icon => icon.getValue() === this.workflowConfigurationStep.icon);
        const permissions = this.permissionOptions.filter(permission => this.workflowConfigurationStep.permissions.includes(permission.getValue()));

        this.formGroup = new FormGroup<StepForm>({
            name: new FormControl<string>(null, [Validators.required]),
            icon: new FormControl<DropdownItem<string>>(null, [Validators.required]),
            permissions: new FormControl<DropdownItem<string>[]>([]),
            schedule: new FormGroup<ScheduleForm>({
                hours: new FormControl<number>(null, [Validators.min(0)]),
                notifications: new FormArray<FormGroup<NotificationForm>>([])
            })
        });

        const patchValue = {
            name: this.workflowConfigurationStep.name,
            icon: stepIcon,
            permissions: permissions,
            schedule: {
                hours: this.workflowConfigurationStep.schedule?.hours,
                notifications: []
            }
        };

        if (this.workflowConfigurationStep.schedule?.notifications) {
            let notificationIndex = 0;
            for (const notification of this.workflowConfigurationStep.schedule.notifications) {
                this.addNotification();
                const notificationValue = {
                    hours: notification.hours,
                    alertType: this.alertTypeOptions.find(option => option.getValue() === notification.alertType),
                    message: notification.message,
                    receivers: [],
                    properties: []
                };

                let receiverIndex = 0;
                for (const receiver of notification.receivers) {
                    this.addReceiver(notificationIndex);
                    const type = this.receiverTypeOptions.find(option => option.getValue() === receiver.type);
                    this.typeChanged(type, notificationIndex, receiverIndex);

                    const receiverValue: Record<string, any> = {
                        type
                    };

                    switch (type.getValue()) {
                        case EReceiverType.ROLE:
                            receiverValue.roles = receiver.roles.map(role => this.roleOptions.find(option => option.getValue() === role));
                            break;
                        case EReceiverType.MAIL:
                            receiverValue.emails = receiver.emails.map(email => new DropdownItem(email, email));
                            break;
                        case EReceiverType.PROPERTY: {
                            receiverValue.property = PropertyControlComponent.getPropertyValueModel(receiver.property, this.propertyOptions,
                                this.variants, false);
                            break;
                        }
                    }
                    notificationValue.receivers.push(receiverValue);

                    receiverIndex++;
                }

                if (Array.isArray(notification.properties)) for (const property of notification.properties) {
                    this.addProperty(notificationIndex);

                    const propertyValue: Record<string, any> = {
                        label: property.label
                    };

                    propertyValue.property = PropertyControlComponent.getPropertyValueModel(property.property, this.propertyOptions,
                        this.variants, false);
                    notificationValue.properties.push(propertyValue);
                }
                patchValue.schedule.notifications.push(notificationValue);
                notificationIndex++;
            }
        }

        this.listenToFormChanges();

        this.formGroup.patchValue(patchValue);
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status: string) => this.saveButton.disabled = status !== 'VALID');
    }

    private initData(): void {
        this.workflowConfigurationId = this.modalData.workflowConfigurationId;
        this.workflowConfigurationStep = this.modalData.workflowConfigurationStep || new CustomWorkflowStepModel();

        this.nucIconOptions = [...this.nucIcons];

        forkJoin([
            this.permissionService.getAllPermissions('key', 'asc'),
            this.rolesService.getRoles(null, null, 'name', 'asc'),
            this.propertyService.getProperties(EPropertyContext.WORKFLOW_PUBLICATION_ITEM),
            this.variantService.getVariants()
        ])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: ([permissions, rolesRes, properties, variantRes]) => {
                    this.permissionOptions = permissions.map(permission => new DropdownItem(
                        permission._id,
                        permission._id
                    ));

                    this.roleOptions = rolesRes.items.map(role => new DropdownItem(role.name, role._id));
                    this.propertyOptions = properties;
                    this.variants = variantRes.items;
                    this.initForm();
                },
                error: Toaster.handleApiError
            });
    }

    private saveWorkflowConfigurationStep(): void {
        // Remove notifications if no hours is set
        if (!this.formGroup.value.schedule.hours || this.formGroup.value.schedule.hours <= 0) {
            this.notificationsFormArray.clear();
        }

        const body: Record<string, any> = {
            name: this.formGroup.value.name,
            icon: this.formGroup.value.icon.getValue(),
            permissions: this.formGroup.value.permissions?.map((permission: DropdownItem<string>) => permission.getValue()) || [],
            schedule: {
                hours: this.formGroup.value.schedule.hours,
                notifications: []
            }
        };

        if (this.formGroup.value.schedule.notifications.length) {
            body.schedule.notifications = [];

            for (const notificationValue of this.formGroup.value.schedule.notifications) {
                const notification: Record<string, any> = {};

                notification.hours = notificationValue.hours;
                notification.alertType = notificationValue.alertType.getValue();
                notification.message = notificationValue.message;
                notification.receivers = [];
                notification.properties = [];

                for (const receiverValue of notificationValue.receivers) {
                    const receiver: Record<string, any> = {};
                    receiver.type = receiverValue.type.getValue();
                    switch(receiver.type) {
                        case EReceiverType.ROLE:
                            receiver.roles = receiverValue.roles.map((role: DropdownItem<string>) => role.getValue());
                            break;
                        case EReceiverType.MAIL:
                            receiver.emails = receiverValue.emails.map((email: DropdownItem<string>) => email.getValue());
                            break;
                        case EReceiverType.PROPERTY:
                            receiver.property = receiverValue.property.path;
                            break;
                    }

                    notification.receivers.push(receiver as NotificationReceiverModel)
                }
                for (const propertyValue of notificationValue.properties) {
                    const property: Record<string, any> = {};
                    property.label = propertyValue.label;
                    property.property = propertyValue.property.path;

                    notification.properties.push(property as NotificationPropertyModel)
                }
                body.schedule.notifications.push(notification as ScheduleNotificationModel);
            }
        }

        const step = ModelUtil.createApiBody(body, this.workflowConfigurationStep._id) as CustomWorkflowStepModel;

        if (this.workflowConfigurationStep._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationStep(this.workflowConfigurationId, this.workflowConfigurationStep._id, step)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Step updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationStep(this.workflowConfigurationId, step)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Step created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public searchIcons(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.nucIconOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.nucIconOptions = this.nucIcons.filter((icon) => icon.getTitle().match(regex)?.length > 0);
        } else {
            this.nucIconOptions = this.nucIcons;
        }
    }

    public typeChanged(type: DropdownItem<EReceiverType>, notificationIndex: number, receiverIndex: number): void {
        if (!type) return;

        const receiverForm = this.getReceiversFormArray(notificationIndex)?.at(receiverIndex) as FormGroup<ReceiverForm>;

        switch(type.getValue()) {
            case EReceiverType.ROLE:
                receiverForm.removeControl('emails', {emitEvent: false});
                receiverForm.removeControl('property', {emitEvent: false});
                receiverForm.addControl('roles', new FormControl<DropdownItem<string>[]>([], [Validators.required, Validators.minLength(1)]));
                break;
            case EReceiverType.MAIL:
                receiverForm.removeControl('roles', {emitEvent: false});
                receiverForm.removeControl('property', {emitEvent: false});
                receiverForm.addControl('emails', new FormControl<DropdownItem<string>[]>([],
                    [Validators.required, Validators.minLength(1), (control: AbstractControl) => {
                        const emails = control.value;
                        if (Array.isArray(emails) && emails.length &&
                            !emails.every(email => !!email.getValue().match(RLValidatorRegExConstants.EMAIL))) {
                            return {email: 'Invalid email address'};
                        }

                        return null;
                    }]));
                break;
            case EReceiverType.PROPERTY:
                receiverForm.removeControl('roles', {emitEvent: false});
                receiverForm.removeControl('emails', {emitEvent: false});
                receiverForm.addControl('property', new FormControl<PropertyValueModel>(null, [Validators.required]))
                break;
        }
    }

    public addNotification(): void {
        this.notificationsFormArray.push(new FormGroup<NotificationForm>({
            hours: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
            alertType:  new FormControl<DropdownItem<EAlertType>>(null, [Validators.required]),
            message:  new FormControl<string>(null, [Validators.required]),
            receivers: new FormArray<FormGroup<ReceiverForm>>([], [Validators.required, Validators.minLength(1)]),
            properties: new FormArray<FormGroup<PropertyForm>>([])
        }));
    }

    public deleteNotification(index: number): void {
        this.notificationsFormArray.removeAt(index);
    }

    public addReceiver(notificationIndex: number): void {
        this.getReceiversFormArray(notificationIndex)?.push(new FormGroup<ReceiverForm>({
            type: new FormControl<DropdownItem<EReceiverType>>(null, [Validators.required])
        }));
    }

    public deleteReceiver(notificationIndex: number, index: number): void {
        this.getReceiversFormArray(notificationIndex).removeAt(index);
    }

    public addProperty(notificationIndex: number): void {
        this.getPropertiesFormArray(notificationIndex)?.push(new FormGroup<PropertyForm>({
            label: new FormControl<string>(null, [Validators.required]),
            property: new FormControl<PropertyValueModel>(null, [Validators.required])
        }));
    }

    public deleteProperty(notificationIndex: number, index: number): void {
        this.getPropertiesFormArray(notificationIndex).removeAt(index);
    }
}
