<div class="header-row">
    <h1>Package setup overview</h1>

    <nuc-button-bar>
        <nuc-button-primary
            *ngIf="permissions.POST_PACKAGE_SETUP | userIsAllowedTo"
            text="Add setup"
            (click)="onCreatePackageSetupClicked()">
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="permissions.GET_PACKAGE_SETUPS | userIsAllowedTo; else noPermissionState">
    <nuc-table [items]="items"
               [columns]="columns"
               [selectionMode]="ESelectionMode.EMIT"
               [actions]="actions"
               [tableId]="tableId"
               [loading]="!packageSetupServiceSubscription?.closed"
               [prefixStorageKey]="storageKey"
               (actionClicked)="handleTableRowAction($event)"
               (itemClicked)="onPackageSetupClicked($event)"
               [emptyStateTitle]="searching ? 'No item matches your search criteria' : 'This section is empty.'"
               (sortOptionChange)="onSortOptionChanged($event)">
        <ng-container button-bar-left>
            <nuc-input prefix="nucicon_search" class="search"
                       (debounceValueChanged)="onSearchBarValueUpdated($event)"
                       [placeholder]="'Search'">
            </nuc-input>
        </ng-container>
        <paginator button-bar-right
                   [viewId]="tableId"
                   [loading]="!packageSetupServiceSubscription?.closed"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
