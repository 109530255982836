
<div class="image-container">
    <img class="logo" [src]="logo">
</div>
<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>

        <ng-container *ngIf="form.controls.name.touched && form.controls.name.errors as error">
            <nuc-error *ngIf="error.required">Name required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <nuc-form-field label="API key">
        <nuc-input formControlName="apiKey"
                   type="text"
                   placeholder="Fill in your API key">
        </nuc-input>

        <ng-container *ngIf="form.controls.apiKey.touched && form.controls.apiKey.errors as error">
            <nuc-error *ngIf="error.required">API key required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <div class="margin-bottom-base margin-top-large">
        <h2>Synchronisation settings</h2>
        <span class="margin-bottom-base block">
            Setup a regular expression to select specific images to synchronise
        </span>
        <hr>
    </div>
    <nuc-form-field label="Regular expression">
        <nuc-regex-input
            formControlName="imageRegexFilter">
        </nuc-regex-input>
    </nuc-form-field>
    <nuc-form-field label="Global Location Numbers (GLN)">
        <nuc-suggestive-input placeholder="Add a global location number"
                              formControlName="globalLocationNumbers">
        </nuc-suggestive-input>
    </nuc-form-field>
    <nuc-form-field label="Remove leading zero's">
        <nuc-checkbox
            formControlName="removeLeadingZeros"
            text="Remove leading zero's from image identifier.">
        </nuc-checkbox>
    </nuc-form-field>
    <nuc-form-field label="Product data field">
        <nuc-dropdown
            formControlName="productDataField"
            placeholder="Select a product data field which is used to link the image to"
            [searchable]="true"
            [items]="dataFieldOptions"
            (requestData)="searchProductDataFields($event)">
        </nuc-dropdown>
    </nuc-form-field>
    <nuc-checkbox
        formControlName="enableImageSync"
        text="Enable image syncing">
    </nuc-checkbox>
</form>
