import InteractionEvent = PIXI.interaction.InteractionEvent;
import InteractionData = PIXI.interaction.InteractionData;
import {Subject} from 'rxjs';
import {TemplateAreaModel} from '../../../../../../../../models/api/template-area.model';
import {TemplateModel} from '../../../../../../../../models/api/template.model';
import {ISpreadContentTypeBody} from '../../../../../../../../models/interfaces/spread-content-type-body.interface';
import {ItemBackgroundPixi} from './item-background.pixi';
import {ButtonPixi} from './button.pixi';
import {ResizeBodyPixi} from './resize-body.pixi';
import {PlaceEvent} from '../stages/template-stage.pixi';
import {IEditorOptions} from '../spread-editor.component';
import {PropertySetting, PropertySettingsModel} from '../../../../../../../../components/property-settings/property-settings.model';
import {SpreadNoteModel} from '../../../../../../../../models/api/spread-note.model';
import {CampaignItemModel} from '../../../../../../../../models/api/campaign-item.model';
import {NoteItemBackgroundPixi} from './note-item-background.pixi';
import {FileTypeUtil} from '../../../../../../../../classes/file-type.util';
import {VariantModel} from '../../../../../../../../models/api/variant.model';
import {PropertySettingDisplayPipe} from '../../../../../../../../pipes/property-setting-display.pipe';

export class ResizeEvent {

    constructor(public readonly item: ItemPixi,
                public readonly templateArea: TemplateAreaModel,
                public readonly row: number,
                public readonly column: number,
                public readonly rowSpan: number,
                public readonly columnSpan: number) {
    }
}

export class MoveEvent {
    constructor(public readonly item: ItemPixi,
                public readonly templateArea: TemplateAreaModel,
                public readonly row: number,
                public readonly column: number,
                public readonly rowSpan: number,
                public readonly columnSpan: number) {
    }
}

export class ItemPixi extends PIXI.Container {

    // TODO: This should be fixed by scale & from ButtonPixi class itself
    private static BUTTON_WIDTH = 32;
    private static BUTTON_HEIGHT = 32;
    private static BUTTON_MARGIN = 8;
    private static readonly TEXT_MARGIN = 4;
    private static readonly MAX_LINES = Infinity;

    private static IMAGE_CONTAINER_MARGIN = 8;

    private readonly buttonWidth: number = ItemPixi.BUTTON_WIDTH;
    private readonly buttonHeight: number = ItemPixi.BUTTON_HEIGHT;
    private readonly buttonMargin: number = ItemPixi.BUTTON_MARGIN;
    private readonly textMargin: number = ItemPixi.TEXT_MARGIN;
    private readonly imageContainerMargin: number = ItemPixi.IMAGE_CONTAINER_MARGIN;

    private onItemMoving = new Subject<MoveEvent>();
    public onItemMoving$ = this.onItemMoving.asObservable();

    private onItemMoved = new Subject<MoveEvent>();
    public onItemMoved$ = this.onItemMoved.asObservable();

    private onItemResizing = new Subject<ResizeEvent>();
    public onItemResizing$ = this.onItemResizing.asObservable();

    private onItemResized = new Subject<ResizeEvent>();
    public onItemResized$ = this.onItemResized.asObservable();

    private onItemRemoved = new Subject<ItemPixi>();
    public onItemRemoved$ = this.onItemRemoved.asObservable();

    private onItemEdit = new Subject<ItemPixi>();
    public onItemEdit$ = this.onItemEdit.asObservable();

    private onItemClicked = new Subject<ItemPixi>();
    public onItemClicked$ = this.onItemClicked.asObservable();

    private itemWidth: number;
    private itemHeight: number;

    private hovered: boolean;

    private moving: boolean;
    private moveData: InteractionData;

    private resizing: boolean;
    private resizeData: InteractionData;
    private resizeBody: PIXI.Container;

    private background: ItemBackgroundPixi;

    private imageContainer: PIXI.Container;
    private images: PIXI.Texture[];

    private textBackground: PIXI.Graphics;
    private textContainer: PIXI.Container;

    private editButton: ButtonPixi;
    private removeButton: ButtonPixi;
    private resizeButton: ButtonPixi;

    public get templateArea(): TemplateAreaModel {
        return this._templateArea;
    }

    public get size(): { row: number; column: number; rowSpan: number; columnSpan: number } {
        return {
            row: this.row,
            column: this.column,
            rowSpan: this.rowSpan,
            columnSpan: this.columnSpan
        };
    }

    constructor(public content: ISpreadContentTypeBody,
                private template: TemplateModel,
                private _templateArea: TemplateAreaModel,
                private row: number,
                private column: number,
                private rowSpan: number,
                private columnSpan: number,
                public contentIndex: number,
                private activeVariant: VariantModel,
                private editorOptions: IEditorOptions,
                private scaleRatio: number,
                private propertySettings: PropertySettingsModel = new PropertySettingsModel()) {
        super();
        this.buttonMode = true;
        this.interactive = true;

        this.on('mouseover', () => this.onHover())
            .on('mouseout', () => this.onHoverEnd());


        if (!this.editorOptions.editEnabled && this.content instanceof SpreadNoteModel) {
            this.on('click', () => this.onItemClicked.next(this));
        }

        this.buttonWidth = ItemPixi.BUTTON_WIDTH / scaleRatio;
        this.buttonHeight = ItemPixi.BUTTON_HEIGHT / scaleRatio;
        this.buttonMargin = ItemPixi.BUTTON_MARGIN / scaleRatio;
        this.textMargin = ItemPixi.TEXT_MARGIN / scaleRatio;
        this.imageContainerMargin = ItemPixi.IMAGE_CONTAINER_MARGIN / scaleRatio;

        this.reDrawContent(content);

    }

    private get backgroundColor(): number {
        if (this.content instanceof SpreadNoteModel) {
            // this function could be replaced by PIXI.utils.string2hex('#ffffff') when we update pixi
            const colorString = this.content.color.substring(1); // remove #
            return parseInt(colorString, 16);
        } else {
            return null;
        }
    }

    public reset(): void {
        this.alpha = 1;
        this.draw();
    }

    public commit(event: ResizeEvent | MoveEvent): void {
        if (event instanceof ResizeEvent) {
            this.column = event.column;
            this.row = event.row;
            this.columnSpan = event.columnSpan;
            this.rowSpan = event.rowSpan;
        }
        if (event instanceof MoveEvent) {
            this._templateArea = event.templateArea;
            this.row = event.row;
            this.column = event.column;
            this.rowSpan = Math.min(this.rowSpan, this.templateArea.rows - this.row);
            this.columnSpan = Math.min(this.columnSpan, this.templateArea.columns - this.column);
        }

        this.alpha = 1;
        this.draw();
    }

    public updateContent(content: ISpreadContentTypeBody): void {
        // Currently, only support campaign item and note update content
        this.content = content;
        this.reDrawContent(content);
    }

    private reDrawContent(content: ISpreadContentTypeBody): void {
        this.draw();

        if (content?.isLoadable()) {
            // TODO: reset loader when removing item to free memory
            const imageLoader = new PIXI.loaders.Loader();
            imageLoader.add(content.getUniqueAssetUrls(FileTypeUtil.FILE_TYPE_CATEGORIES.INDESIGN_GENERATION));
            imageLoader.load((_loader, resources) => {
                this.images = Object.values(resources).map((resource) => resource['texture'] as PIXI.Texture);
                this.drawImages();
            });
        }
    }

    private draw(): void {
        this.removeChildren();
        this.x = (this.templateArea.position.x + this.column * this.templateArea.getColumnWidth() + this.column * this.templateArea.columnGutter);
        this.y = (this.templateArea.position.y + this.row * this.templateArea.getRowHeight() + this.row * this.templateArea.rowGutter);

        this.itemWidth = (this.templateArea.getColumnWidth() * this.columnSpan + (this.columnSpan - 1) * this.templateArea.columnGutter);
        this.itemHeight = (this.templateArea.getRowHeight() * this.rowSpan + (this.rowSpan - 1) * this.templateArea.rowGutter);

        this.addBackground();

        this.addImageContainer();
        this.drawImages();

        this.drawTexts();

        this.addButtons();

        if (this.content instanceof SpreadNoteModel && !this.editorOptions.showLayoutNotes) {
            this.visible = false;
        }
    }

    private addBackground(): void {
        if (this.content instanceof SpreadNoteModel) {
            this.background = new NoteItemBackgroundPixi(this.itemWidth, this.itemHeight, this.backgroundColor, this.scaleRatio);
        } else {
            this.background = new ItemBackgroundPixi(this.itemWidth, this.itemHeight, this.backgroundColor, this.scaleRatio);
        }

        this.background.setHovered(this.hovered);
        this.addChild(this.background);

        if (this.editorOptions.editEnabled) {
            this.background.interactive = true;
            this.background.buttonMode = true;
            this.background
                .on('mousedown', (event: InteractionEvent) => this.onMoveStart(event))
                .on('touchstart', (event: InteractionEvent) => this.onMoveStart(event))
                .on('mouseup', () => this.onMoveEnd())
                .on('mouseupoutside', () => this.onMoveEnd())
                .on('touchend', () => this.onMoveEnd())
                .on('touchendoutside', () => this.onMoveEnd())
                .on('mousemove', () => this.onMove())
                .on('touchmove', () => this.onMove());
        }
    }

    private addImageContainer(): void {
        this.imageContainer = new PIXI.Container();
        this.imageContainer.x = this.imageContainerMargin;
        this.imageContainer.y = this.imageContainerMargin;
        this.addChild(this.imageContainer);
    }

    private drawImages(): void {
        if (!this.images || this.images.length === 0) {
            return;
        }

        // Only show the text background when there are images
        if (this.textBackground) this.textBackground.visible = true;

        const imageWidth = (this.itemWidth - 2 * this.imageContainerMargin) / this.images.length;
        const imageHeight = this.itemHeight - 2 * this.imageContainerMargin;

        this.images.forEach((image, index) => {
            const sprite = new PIXI.Sprite(image);

            const scale = Math.min(imageWidth / sprite.width, imageHeight / sprite.height);
            sprite.scale = new PIXI.Point(scale, scale);

            sprite.anchor.x = 0.5;
            sprite.anchor.y = 0.5;
            sprite.x = imageWidth * index + imageWidth / 2;
            sprite.y = imageHeight / 2;

            this.imageContainer.addChild(sprite);
        });
    }

    private addTextBackground(): void {
        if (!(this.content instanceof SpreadNoteModel)) {
            // Draw a 30% transparent white layer between the images and the text for better readability of black on black
            this.textBackground = new PIXI.Graphics();
            this.textBackground.beginFill(0xffffff, .3);
            this.textBackground.drawRect(0, 0, this.itemWidth, this.itemHeight);
            this.textBackground.endFill();
            this.textBackground.visible = this.images?.length > 0 || this.content instanceof SpreadNoteModel;
            this.addChild(this.textBackground);
        }

    }

    private drawTexts(): void {
        const properties: PropertySetting[] = [...this.propertySettings.relayterFields, ...this.propertySettings.dataFields];
        if (properties.length === 0 && !(this.content instanceof SpreadNoteModel)) {
            return;
        }

        const textScale = 1 / this.scaleRatio;
        this.textContainer = new PIXI.Container();

        this.addTextBackground();

        const textStyle = new PIXI.TextStyle({
            fontFamily: 'SourceSansPro-Regular',
            fontSize: 14,
            wordWrap: true,
            wordWrapWidth: (this.width - (2 * this.textMargin)) / textScale,
            breakWords: true,
            textBaseline: 'bottom'
        });

        let yPosition = this.textMargin;

        if (this.content instanceof SpreadNoteModel) {
            this.drawNoteText(textStyle, yPosition, textScale);
        }

        for (const property of properties) {
            const propertyValue = PropertySettingDisplayPipe.transform(this.content, property, this.activeVariant?.key, '');
            let text = `${property.title}: ${propertyValue ? propertyValue : ''}`;
            text = this.ellipsizeText(text, textStyle);

            const textItem = this.createTextItem(textStyle, text, yPosition, textScale);
            yPosition += textItem.height;

            if (yPosition > this.height - (this.textMargin * 2)) {
                // addChild adjusts the height of the item
                this.textContainer.addChild(textItem);
                break;
            }
            this.textContainer.addChild(textItem);
        }

        // mask text container to prevent text from overflowing outside the content area.
        const maskContainer = new PIXI.Container();
        const mask = new PIXI.Graphics();
        mask.drawRect(this.textMargin, this.textMargin, this.width - (3*this.textMargin), this.height - (3*this.textMargin));
        maskContainer.mask = mask;
        maskContainer.addChild(mask);
        maskContainer.addChild(this.textContainer);
        this.addChild(maskContainer);
    }

    private drawNoteText(textStyle: PIXI.TextStyle, yPosition: number, textScale: number): void {
        let text = this.content.getSpreadEditorTitle();
        text = this.ellipsizeText(text, textStyle);

        const textItem = this.createTextItem(textStyle, text, yPosition, textScale);
        textItem.y = this.height - (textItem.height + this.textMargin) > (this.height / 2) ?
            this.height - (textItem.height + this.textMargin) : (this.height / 2) + this.textMargin;

        const textBGMaxY = textItem.y - this.textMargin > (this.height / 2) ? textItem.y - this.textMargin
            : this.height / 2;
        const textBgMaxHeight = this.height - (textItem.y - (this.textMargin * textScale)) < (this.height / 2) ?
            this.height - (textItem.y - (this.textMargin * textScale)) : (this.height / 2) - (this.textMargin * textScale);

        const maskContainer = new PIXI.Container();
        const mask = this.textBackground = new PIXI.Graphics();
        mask.drawRect(0, textBGMaxY, (this.width - (this.textMargin * textScale)), textBgMaxHeight);

        this.textBackground = new PIXI.Graphics();
        this.textBackground.beginFill(this.backgroundColor, .8);
        this.textBackground.drawRect(0, textBGMaxY, this.width, textBgMaxHeight);
        this.textBackground.endFill();
        maskContainer.mask = mask;
        maskContainer.addChild(mask);
        maskContainer.addChild(this.textBackground);
        maskContainer.addChild(textItem);
        this.addChild(maskContainer);
    }

    private createTextItem(textStyle: PIXI.TextStyle, text: string, yPosition: number, textScale: number): PIXI.Text {
        const textItem = new PIXI.Text(text, textStyle);
        textItem.x = this.textMargin;
        textItem.y = yPosition;
        textItem.scale = new PIXI.Point(textScale, textScale);

        return textItem;
    }

    private addButtons(): void {
        let numberOfButtons = 0;

        if (this.editorOptions.editEnabled) {
            numberOfButtons++;
            this.removeButton = new ButtonPixi(
                this.itemWidth - this.buttonWidth - this.buttonMargin,
                this.buttonMargin,
                this.buttonWidth,
                this.buttonHeight,
                'remove-button');

            this.removeButton.on('click', () => this.onItemRemoved.next(this));
            this.removeButton.alpha = this.hovered ? 1 : 0;
            this.addChild(this.removeButton);

            this.resizeButton = new ButtonPixi(
                this.itemWidth - this.buttonWidth - this.buttonMargin,
                this.itemHeight - this.buttonHeight - this.buttonMargin,
                this.buttonWidth,
                this.buttonWidth,
                'resize-button');

            this.resizeButton
                .on('mousedown', (event: InteractionEvent) => this.onResizeStart(event))
                .on('touchstart', (event: InteractionEvent) => this.onResizeStart(event))
                .on('mouseup', () => this.onResizeEnd())
                .on('mouseupoutside', () => this.onResizeEnd())
                .on('touchend', () => this.onResizeEnd())
                .on('touchendoutside', () => this.onResizeEnd())
                .on('mousemove', () => this.onResize())
                .on('touchmove', () => this.onResize());

            this.resizeButton.alpha = this.hovered ? 1 : 0;
            this.addChild(this.resizeButton);

        }

        // You can edit a briefing item (when you can edit the briefing item) or the note (when in edit mode)
        if ((this.editorOptions.editEnabled && this.content instanceof SpreadNoteModel) ||
            (this.content instanceof CampaignItemModel && this.editorOptions.editBriefingItem)) {
            this.editButton = new ButtonPixi(
                this.itemWidth - (numberOfButtons + 1) * this.buttonWidth - (numberOfButtons + 1) * this.buttonMargin,
                this.buttonMargin,
                this.buttonWidth,
                this.buttonHeight,
                'edit-button');
            // TODO: update this image if necessary, as users could view briefing item only when they don't have PUT_CAMPAIGN_ITEM permission

            this.editButton.on('click', () => this.onItemEdit.next(this));
            this.editButton.alpha = this.hovered ? 1 : 0;
            this.addChild(this.editButton);
        }

    }

    private onHover(): void {
        this.hovered = true;
        this.background.setHovered(this.hovered);
        if (this.editButton) {
            this.editButton.alpha = 1;
        }
        if (this.removeButton) {
            this.removeButton.alpha = 1;
        }
        if (this.resizeButton) {
            this.resizeButton.alpha = 1;
        }
    }

    private onHoverEnd(): void {
        if (!this.moving && !this.resizing) {
            this.hovered = false;
            this.background.setHovered(this.hovered);
            if (this.editButton) {
                this.editButton.alpha = 0;
            }
            if (this.removeButton) {
                this.removeButton.alpha = 0;
            }
            if (this.resizeButton) {
                this.resizeButton.alpha = 0;
            }
        }
    }

    private onMoveStart(event: InteractionEvent): void {
        this.moveData = event.data;
        this.alpha = 0.5;
        this.moving = true;
    }

    private onMove(): void {
        if (this.moving) {
            this.announceMove(this.onItemMoving);
        }
    }

    private onMoveEnd(): void {
        if (this.moving) {
            this.announceMove(this.onItemMoved);
            this.moving = false;
        }
    }

    private announceMove(moveSubject: Subject<MoveEvent>): void {
        const newPosition = this.moveData.getLocalPosition(this.parent);

        this.position.x = newPosition.x;
        this.position.y = newPosition.y;

        const targetArea = this.template.areas.find((area) =>
            area.position.x <= newPosition.x &&
            area.position.x + area.size.width > newPosition.x &&
            area.position.y <= newPosition.y &&
            area.position.y + area.size.height > newPosition.y);

        if (targetArea) {
            const x = newPosition.x - targetArea.position.x;
            const y = newPosition.y - targetArea.position.y;
            const column = Math.floor(x / (targetArea.getColumnWidth() + targetArea.columnGutter));
            const row = Math.floor(y / (targetArea.getRowHeight() + targetArea.rowGutter));

            // Check if the new location is not the same area to prevent redraw if nothing changed.
            if (column === this.column && this.row === row && this.templateArea._id === targetArea._id) {
                moveSubject.next(new MoveEvent(this, null, 0, 0, 1, 1));
            } else {
                moveSubject.next(new MoveEvent(this, targetArea, row, column, this.rowSpan, this.columnSpan));
            }
        } else {
            moveSubject.next(new MoveEvent(this, null, 0, 0, 1, 1));
        }
    }

    private onResizeStart(event: InteractionEvent): void {
        this.resizeData = event.data;
        this.resizing = true;
        this.drawResizeBody();
    }

    private onResize(): void {
        if (this.resizing) {
            this.drawResizeBody();
            this.onItemResizing.next(this.getResizeEvent());
        }
    }

    private onResizeEnd(): void {
        if (this.resizing) {
            const resizeEvent = this.getResizeEvent();
            this.onItemResized.next(resizeEvent);
            this.resizing = false;
            this.resizeBody.destroy();
        }
    }

    private getResizeEvent(): ResizeEvent {
        const newPosition = this.resizeData.getLocalPosition(this.parent);
        const width = this.templateArea.getColumnWidth() + this.templateArea.columnGutter;
        const height = this.templateArea.getRowHeight() + this.templateArea.rowGutter;

        let column: number;
        let columnSpan: number;
        let row: number;
        let rowSpan: number;

        if (newPosition.x >= this.x) {
            column = this.column;
            columnSpan = Math.min(
                Math.ceil((newPosition.x - this.x) / width),
                this.templateArea.columns - this.column);
        }

        if (newPosition.x < this.x) {
            column = Math.max(
                (this.column - 1) - Math.floor((this.x - newPosition.x) / width),
                0);
            columnSpan = this.column - column + 1;
        }

        if (newPosition.y >= this.y) {
            row = this.row;
            rowSpan = Math.min(
                Math.ceil((newPosition.y - this.y) / height),
                this.templateArea.rows - this.row);
        }

        if (newPosition.y < this.y) {
            row = Math.max(
                (this.row - 1) - Math.floor((this.y - newPosition.y) / height),
                0);
            rowSpan = this.row - row + 1;
        }

        return new ResizeEvent(this, this.templateArea, row, column, rowSpan, columnSpan);
    }

    private drawResizeBody(): void {
        if (this.resizeBody) {
            this.removeChild(this.resizeBody);
        }

        const newPosition = this.resizeData.getLocalPosition(this.parent);
        const newWidth = newPosition.x - this.position.x;
        const newHeight = newPosition.y - this.position.y;
        this.resizeBody = new ResizeBodyPixi(0, 0, newWidth, newHeight);

        this.addChild(this.resizeBody);
    }

    public blocks(event: MoveEvent | ResizeEvent | PlaceEvent): boolean {
        if ((event instanceof MoveEvent || event instanceof ResizeEvent) && (
            event.item === this || // Ignore blocking if blocked by self
            event.item.content instanceof SpreadNoteModel)) { // Ignore blocking if content is NOTE
            return false;
        }

        // Item is in other templateArea
        if (event.templateArea !== this.templateArea) {
            return false;
        }

        return event.row < this.row + this.rowSpan &&
            event.row + event.rowSpan > this.row &&
            event.column < this.column + this.columnSpan &&
            event.column + event.columnSpan > this.column;
    }

    public setBlocking(blocking: boolean): void {
        this.background.setBlocking(blocking);
    }

    private ellipsizeText(text: string, textStyle: PIXI.TextStyle): string {
        if (ItemPixi.MAX_LINES === Infinity) {
            return text;
        }
        const {wordWrapWidth} = textStyle;
        const pixiStyle = new PIXI.TextStyle(textStyle);
        const metrics = PIXI.TextMetrics.measureText(text, pixiStyle);
        // The type definition isn't correct, so using this hack to prevent compiler messages
        const lines = metrics.lines as unknown as string[];
        let newText = text;
        if (lines.length > ItemPixi.MAX_LINES) {
            const truncatedLines = lines.slice(0, ItemPixi.MAX_LINES);
            const lastLine = truncatedLines[truncatedLines.length - 1];
            const words = lastLine.split(' ');
            const wordMetrics = PIXI.TextMetrics.measureText(`\u00A0\n…\n${words.join('\n')}`, textStyle);
            const [spaceLength, dotsLength, ...wordLengths] = wordMetrics.lineWidths;
            const {text: newLastLine} = wordLengths.reduce((data, wordLength, i) => {
                if (data.length + wordLength + spaceLength >= wordWrapWidth) {
                    return {...data, length: wordWrapWidth};
                }

                return {
                    text: `${data.text}${i > 0 ? ' ' : ''}${words[i]}`,
                    length: data.length + wordLength + spaceLength,
                };
            }, {text: '', length: dotsLength});
            truncatedLines[truncatedLines.length - 1] = `${newLastLine}…`;
            newText = truncatedLines.join('\n');
        }

        return newText;
    }
}
