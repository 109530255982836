<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        <nuc-button-secondary
            *ngIf="permissions.PUT_CONNECTION | userIsAllowedTo"
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    </div>
    <div class="connection-details" *ngIf="connection">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div class="properties-container">
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{ connection.name }}</p>
                </div>
                <div class="field">
                    <p class="body-strong">API Key</p>
                    <p>{{ connection.apiKey }}</p>
                </div>
            </div>
            <div>
                <h2>Synchronisation settings</h2>
                <hr>
                <div class="properties">
                    <div class="field">
                        <p class="body-strong">Regular expression</p>
                        <p>{{ connection.synchronisationSettings.imageRegexFilter || '-' }}</p>
                    </div>
                    <div class="field">
                        <p class="body-strong">Global location numbers (GLN)</p>
                        <p>{{ connection.synchronisationSettings?.globalLocationNumbers?.length > 0 ? connection.synchronisationSettings.globalLocationNumbers : '-' }}</p>
                    </div>
                    <div class="field">
                        <p class="body-strong">Remove leading zero's</p>
                        <i
                            [ngClass]="connection.synchronisationSettings?.removeLeadingZeros ? 'green' : 'snow'"
                            class="nucicon_check_round_fill"></i>
                    </div>
                    <div class="field">
                        <p class="body-strong">Product data field</p>
                        <p>{{ productDataField ? productDataField.name : '-' }}</p>
                    </div>
                    <div class="field">
                        <p class="body-strong">Image syncing</p>
                        <i
                            [ngClass]="connection.synchronisationSettings.enableImageSync ? 'green' : 'snow'"
                            class="nucicon_check_round_fill"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
