import {Component, computed, Input, OnChanges, signal, SimpleChanges} from '@angular/core';


@Component({
    selector: 'loading-counter',
    templateUrl: './loading-counter.component.html',
    styleUrl: './loading-counter.component.scss'
})
export class LoadingCounterComponent implements OnChanges {
    public readonly rx = 11;

    @Input() count: number;
    @Input() progress: number;
    @Input() transformOrigin = 'center'; // this is for the ending animation

    public digits = signal(2);
    public extraWidth = computed(() => 6 * this.digits() - 12);
    public width = computed(() => this.rx * 2 + this.extraWidth());
    public pathLength = computed(() => this.width() * 2 - this.rx * 4 + Math.PI * 2 * this.rx);

    public progressPercentage = signal(0);
    public loadedPath = computed(() => this.progressPercentage() * this.pathLength());
    public remainingPath = computed(() => this.pathLength() - this.loadedPath());

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.count) {
            // set digits
            this.digits.set(Math.max(String(this.count).length, 2));
        }

        if (changes.progress) {
            this.progressPercentage.set((this.progress || 0) / 100);
        }
    }
}
