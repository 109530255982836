import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModelUtil} from '../../classes/model.util';
import {distinctUntilChanged, forkJoin, map, startWith} from 'rxjs';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {CustomWorkflowFilterModel, EWorkflowConfigurationFilterType} from '../../models/api/custom-workflow-filter.model';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {ConditionForm, ConditionFormComponent} from '../conditions-form/condition-form.component';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {PublicationTypesService} from '../../api/services/publication-types.service';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {PropertyControlComponent} from '../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../models/ui/property-value.model';

export interface IWorkflowConfigurationFilterFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    filter?: CustomWorkflowFilterModel;
}

class FilterForm {
    name: FormControl<string>;
    type: FormControl<DropdownItem<EWorkflowConfigurationFilterType>>;
    queryParam: FormControl<string> | FormControl<PropertyValueModel>;
    filters: FormGroup<FiltersForm>;
    items: FormArray<FormGroup<ItemForm>>;
}

class ItemForm {
    title: FormControl<string>;
    conditions: FormArray<FormGroup<ConditionForm>>;
}

class FiltersForm {
    publicationTypes?: FormControl<DropdownItem<string>[]>;
}

@Component({
    selector: 'workflow-configuration-filter-form-component',
    templateUrl: 'workflow-configuration-filter-form.component.html',
    styleUrls: ['workflow-configuration-filter-form.component.scss']
})
export class WorkflowConfigurationFilterFormComponent implements OnInit {
    protected readonly EWorkflowConfigurationFilterType = EWorkflowConfigurationFilterType;
    private destroyRef: DestroyRef = inject(DestroyRef);

    public filterTypeOptions: DropdownItem<EWorkflowConfigurationFilterType>[];
    public queryParamPropertyOptions: RulePropertyModel[] = [];
    public conditionPropertyOptions: RulePropertyModel[] = [];
    public publicationTypeOptions: DropdownItem<string>[] = [];

    public formGroup: FormGroup<FilterForm>;
    private saveButton: ButtonConfig;

    private workflowConfiguration: WorkflowConfigurationModel;
    public filter: CustomWorkflowFilterModel;

    // all the campaign item dataFields field key collection
    // either with 'dataFields.' or without, we should align it
    public displayOptions: DropdownItem<string>[];
    public campaignItemFieldOptions: DropdownItem<string>[];

    public variants: VariantModel[];


    get itemsFormArray() {
        return this.formGroup.controls.items as FormArray<FormGroup<ItemForm>>;
    }

    getConditionsFormArray(index: number) {
        return this.itemsFormArray.at(index)?.controls.conditions as FormArray<FormGroup<ConditionForm>>;
    }

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private propertyService: PropertyService,
                private dataFieldsService: DataFieldsApiService,
                private variantService: VariantService,
                private publicationTypesService: PublicationTypesService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationFilterFormData) {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.filter = this.modalData.filter || new CustomWorkflowFilterModel();

        this.filterTypeOptions = Object.keys(EWorkflowConfigurationFilterType).map((filterType) => {
            const filter = EWorkflowConfigurationFilterType[filterType];
            return new DropdownItem<EWorkflowConfigurationFilterType>(filter, filter);
        });
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationFilter());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        forkJoin([
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            this.propertyService.getProperties(EPropertyContext.WORKFLOW_CONFIGURATION_FILTER),
            this.variantService.getVariants(),
            this.publicationTypesService.find()
        ]).subscribe({
            next: ([campaignItemDataFields, properties, variants, publicationTypes]) => {
                this.displayOptions = campaignItemDataFields.map((field) => new DropdownItem(field.name, field.fieldName));
                this.campaignItemFieldOptions = campaignItemDataFields.map((field) =>
                    new DropdownItem(field.name, `dataFields.${field.fieldName}`));
                this.queryParamPropertyOptions = properties as RulePropertyModel[];
                this.conditionPropertyOptions = RulePropertyModel.convertRuleProperties(this.queryParamPropertyOptions, true);
                this.variants = variants.items;
                this.publicationTypeOptions = publicationTypes.items.map(type => new DropdownItem<string>(type.name, type._id));
                this.initForm();
                this.listenToFormStatus();
            },
            error: Toaster.handleApiError
        });
    }

    private initForm(): void {
        this.formGroup = new FormGroup<FilterForm>({
            name: new FormControl<string>(null, Validators.required),
            type: new FormControl<DropdownItem<EWorkflowConfigurationFilterType>>(null, Validators.required),
            queryParam: this.getQueryParamFormControl(!this.filter.custom ? EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER :
                this.filter.filterName),
            items: new FormArray<FormGroup<ItemForm>>([]),
            filters: new FormGroup<FiltersForm>({})
        });

        // set queryParam and type
        let queryParam: string | PropertyValueModel;
        let typeValue: DropdownItem<EWorkflowConfigurationFilterType>;
        let filters: Record<string, any>;
        if (this.filter.custom) {
            typeValue = this.filterTypeOptions.find(option => option.getValue() === this.filter.filterName);

            switch (this.filter.filterName) {
                case EWorkflowConfigurationFilterType.CONDITIONS_FILTER:
                    queryParam = this.filter.queryParam.replace(/WorkflowFilterConditions$/, '');
                    break;
                case EWorkflowConfigurationFilterType.EXTERNAL_PUBLICATION_ITEMS:
                    this.formGroup.controls.filters.addControl('publicationTypes', this.getPublicationTypesFormControl());
                    if (this.filter.filters?.publicationTypes?.length) {
                        filters = {
                            publicationTypes: this.publicationTypeOptions.filter(option =>
                                this.filter.filters.publicationTypes.includes(option.getValue()))
                        };
                    }
                    break;
            }
        } else {
            typeValue = this.filterTypeOptions.find(option => option.getValue() === EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER);
            queryParam = PropertyControlComponent.getPropertyValueModel(this.filter.queryParam, this.queryParamPropertyOptions,
                this.variants, true);
        }

        const patchValue = {
            name: this.filter?.name,
            type: typeValue,
            queryParam: queryParam,
            items: [],
            filters
        };

        if (this.filter.items?.length) {
            // Add items
            let itemIndex = 0;
            for (const item of this.filter.items) {
                this.addItem();
                const itemValue = {
                    title: item.title,
                    value: item.value,
                    conditions: []
                };
                patchValue.items.push(itemValue);

                // Add conditions, its patchValue is done by the condition-form
                const conditionsArray = this.getConditionsFormArray(itemIndex);
                item.conditions.forEach((condition) => {
                    conditionsArray.push(ConditionFormComponent.createForm());
                    itemValue.conditions.push(ConditionFormComponent.getPatchValue(condition, this.variants, this.conditionPropertyOptions))
                });
                itemIndex++;
            }
        }

        this.formGroup.patchValue(patchValue);
    }

    private listenToFormStatus(): void {
        // only to the form after everything is initialized
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === 'VALID'),
            startWith(this.formGroup.status === 'VALID'),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    public onFilterTypeChange(filterType: EWorkflowConfigurationFilterType): void {
        const currentFilterType = this.formGroup.controls.type?.value.getValue();
        if (currentFilterType !== filterType) {
            this.formGroup.removeControl('queryParam');
            this.formGroup.addControl('queryParam', this.getQueryParamFormControl(filterType));

            this.formGroup.removeControl('filters');
            this.formGroup.removeControl('items');

            if (filterType === EWorkflowConfigurationFilterType.CONDITIONS_FILTER) {
                this.formGroup.addControl('items', new FormArray<FormGroup<ItemForm>>([], [Validators.required, Validators.minLength(1)]));
            }

            if (filterType === EWorkflowConfigurationFilterType.EXTERNAL_PUBLICATION_ITEMS) {
                this.formGroup.addControl('filters', new FormGroup<FiltersForm>({
                    publicationTypes: this.getPublicationTypesFormControl()
                }));
            }
        }
    }

    private getQueryParamFormControl(filterType: EWorkflowConfigurationFilterType): FormControl<string> | FormControl<PropertyValueModel> {
        switch(filterType) {
            case EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER:
                return new FormControl<PropertyValueModel>(null, [Validators.required]);
            case EWorkflowConfigurationFilterType.CONDITIONS_FILTER: {
                return new FormControl<string>(null, [Validators.required, Validators.pattern(RLValidatorRegExConstants.ALPHANUMERIC_STRICT)]);
            }
            default:
                return new FormControl<string>(null)
        }
    }

    private getPublicationTypesFormControl(): FormControl<DropdownItem<string>[]> {
        return new FormControl<DropdownItem<string>[]>(null, [Validators.required, Validators.minLength(1)]);
    }

    private saveWorkflowConfigurationFilter(): void {
        const formValue = this.formGroup.value;

        const patchBody = {
            name: formValue.name
        } as Record<string, any>;

        switch(formValue.type.getValue()) {
            case EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER:
                patchBody.queryParam = (formValue.queryParam as PropertyValueModel).path;
                break;
            case EWorkflowConfigurationFilterType.CONDITIONS_FILTER:
            case EWorkflowConfigurationFilterType.EXTERNAL_PUBLICATION_ITEMS:
                patchBody.custom = true;
                patchBody.filterName = formValue.type.getValue();
                if (formValue.type.getValue() === EWorkflowConfigurationFilterType.CONDITIONS_FILTER) {
                    patchBody.queryParam = `${formValue.queryParam}WorkflowFilterConditions`;
                    patchBody.dataType = EDataFieldTypes.ENUM;
                    patchBody.items = formValue.items.map((item, index) => {
                        return {
                            title: item.title,
                            value: index.toString(),
                            conditions: item.conditions.map(condition => {
                                const property = condition.property.getValue();
                                const dataType = condition.property.dataType.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : undefined;
                                const value = condition.value instanceof DropdownItem ? condition.value.getValue() : condition.value;

                                return {
                                    property: property + `${condition.property.enableVariants ? '.' + condition.variant?.getValue() : ''}`,
                                    operator: condition.operator?.getValue(),
                                    type: condition.type?.getValue(),
                                    value,
                                    ...dataType && { dataType }
                                };
                            })
                        };
                    });
                } else {
                    patchBody.filters = {
                        publicationTypes: formValue.filters.publicationTypes.map(type => type.getValue())
                    };
                }
                break;
        }

        const filter = ModelUtil.createApiBody(patchBody, this.filter._id);

        if (this.filter._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationFilter(this.workflowConfiguration._id,
                this.filter._id, filter)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Filter updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationFilter(this.workflowConfiguration._id, filter)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Filter created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public addItem(): void {
        this.itemsFormArray?.push(new FormGroup<ItemForm>({
            title: new FormControl<string>(null, [Validators.required]),
            conditions: new FormArray<FormGroup<ConditionForm>>([], [Validators.required, Validators.minLength(1)])
        }));
    }

    public deleteItem(index: number): void {
        this.itemsFormArray.removeAt(index);
    }

    public addCondition(itemIndex: number): void {
        this.getConditionsFormArray(itemIndex)?.push(ConditionFormComponent.createForm());
    }

    public deleteCondition(itemIndex: number, index: number): void {
        this.getConditionsFormArray(itemIndex).removeAt(index);
    }

    // Initialize condition form with its condition
    public getCondition(index: number, cIndex: number): RuleConditionModel {
        return this.filter.items.at(index)?.conditions.at(cIndex);
    }
}
