import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {PlanModel} from './plan.model';
import {UserTeamModel} from './user-team.model';
import {UserModel} from './user.model';

/**
 * TeamModel
 */
@inheritSerialization(UserTeamModel)
export class TeamModel extends UserTeamModel {
    @autoserialize public companyName: string;
    @autoserialize public country: string;
    @autoserialize public userLimit: number;
    @autoserialize public userCount: number;
    @autoserialize public storageSeatLimit: number;
    @autoserialize public creationSeatLimit: number;
    @autoserializeAs(UserModel) public owner: UserModel;
    @autoserializeAs(PlanModel) public plan: PlanModel;

    get maxAllowedStorage(): number {
        return this.userLimit * this.storageSeatLimit;
    }

    get maxAllowedCreation(): number {
        return this.userLimit * this.creationSeatLimit;
    }
}
