import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormControl, FormControlStatus, FormGroup, UntypedFormGroup} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {WorkflowAutomationsService} from '../../api/services/workflow-automations.service';
import {Toaster} from '../../classes/toaster.class';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {EEngineType, TemplateModel} from '../../models/api/template.model';
import {TemplateService} from '../../api/services/templates.service';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    WorkflowAutomationModel,
    WorkflowAutomationRuleBodyModel,
    WorkflowAutomationRuleModel
} from '../../models/api/workflow-automation.model';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {AppConstants} from '../../app.constants';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {EDataFieldTypes} from '../../app.enums';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {ConditionTypeValue} from '../../modules/format-rulesets/format-ruleset.constants';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IWorkflowAutomationRuleFormData {
    workflowAutomation: WorkflowAutomationModel;
    rule: WorkflowAutomationRuleModel;
}

class AutomationRuleForm {
    name: FormControl<string>;
    template: FormControl<TemplateModel>;
}

@Component({
    selector: 'workflow-automation-rule-form-component',
    templateUrl: 'workflow-automation-rule-form.component.html',
    styleUrls: ['workflow-automation-rule-form.component.scss']
})

export class WorkflowAutomationRuleFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private confirmButton: ButtonConfig;
    private onConfirmClicked: () => void = this.nextAction;

    public formGroup: UntypedFormGroup;

    // data
    public rule: WorkflowAutomationRuleModel;
    public templates: TemplateModel[] = [];
    private templateOffset = 0;
    public totalTemplates: number;
    public ruleProperties: RulePropertyModel[] = [];
    public variants: VariantModel[] = [];
    private selectedPublicationType: EPublicationType;
    private selectedTemplate: TemplateModel;
    public defaultPageSize = AppConstants.PAGE_SIZE_DEFAULT;
    private templateSubscription: Subscription;

    // tab bar related
    public TAB_RULE_DESCRIPTION = 0;
    public TAB_CONDITIONS = 1;
    public ruleDescriptionTab = new TabBarItemModel('Rule description', this.TAB_RULE_DESCRIPTION);
    public conditionsTab = new TabBarItemModel('Conditions', this.TAB_CONDITIONS);
    public tabbarItems: TabBarItemModel[] = [this.ruleDescriptionTab, this.conditionsTab];

    private _selectedTab = this.tabbarItems[this.TAB_RULE_DESCRIPTION];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabbarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.updateButtonStatus(this.formGroup.status);
        }
    }

    constructor(private fullModalService: FullModalService,
                private workflowAutomationsService: WorkflowAutomationsService,
                private templatesService: TemplateService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowAutomationRuleFormData) {
        this.rule = this.modalData.rule;
        this.selectedPublicationType = this.modalData.workflowAutomation.publicationType.name;
    }

    public ngOnInit(): void {
        if (this.rule) {
            this.selectedTemplate = this.rule.template;
            this.templates = this.selectedTemplate ? [this.selectedTemplate] : [];
        }
        this.getTemplates();
        this.setupFormGroup();
        this.initModalButtons();
        this.listenToFormChanges();
        this.getRuleProperties();
        this.getVariants();
    }

    private setupFormGroup(): void {
        this.formGroup = new FormGroup<AutomationRuleForm>({
            name: new FormControl(this.rule?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            template: new FormControl(this.selectedTemplate),
        });
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const confirmAction = new FullModalActionModel(this.confirmButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        confirmAction.observable.subscribe(() => this.onConfirmClicked());
        this.fullModalService.setModalActions([cancelAction, confirmAction]);
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.updateButtonStatus(status));
    }

    public getTemplates(): void {
        if (this.templateSubscription) this.templateSubscription.unsubscribe();
        this.templateSubscription =
            this.templatesService.getTemplates(
                this.selectedPublicationType, EEngineType.INDESIGN, this.defaultPageSize, this.templateOffset)
                .subscribe((result) => {
                    this.templateOffset = this.templateOffset + result.items.length;
                    // only add unique value here
                    this.templates = this.templates.concat(result.items.filter((item) => item._id !== this.selectedTemplate?._id));
                    this.totalTemplates = result.total;
                }, Toaster.handleApiError);
    }

    private getRuleProperties(): void {
        this.propertyService.getProperties(EPropertyContext.WORKFLOW_AUTOMATION)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => this.ruleProperties = result, Toaster.handleApiError);
    }

    private getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => this.variants = result.items, Toaster.handleApiError);
    }

    private updateButtonStatus(status: FormControlStatus): void {
        this.confirmButton.disabled = this.selectedTab.index === this.TAB_CONDITIONS && status !== 'VALID';
    }

    private nextAction(): void {
        this.selectedTab = this.tabbarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_RULE_DESCRIPTION:
                this.confirmButton.text = 'Next';
                this.onConfirmClicked = this.nextAction;
                return;
            case this.TAB_CONDITIONS:
                this.confirmButton.text = 'Save';
                this.onConfirmClicked = this.saveRule;
                break;
        }
    }

    private saveRule(): void {
        this.confirmButton.loading = true;
        const conditions = this.formGroup.value.conditions.map((condition) => {
            const conditionProperty = condition.property as RulePropertyModel;
            const property = conditionProperty.getValue() + `${conditionProperty.enableVariants ? '.' + condition.variant?.getValue() : ''}`;
            const operator = condition.operator?.getValue();
            const type = condition.type?.getValue();
            const value = condition.value instanceof DropdownItem ? condition.value.getValue() : condition.value;
            const dataType = conditionProperty.getDataType() === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : null;

            return new RuleConditionModel(property,
                type,
                ConditionTypeValue.CUSTOM.getValue(),
                value,
                null,
                operator,
                dataType);
        });
        const body = new WorkflowAutomationRuleBodyModel(
            this.formGroup.value.name,
            this.formGroup.value.template?._id,
            conditions);

        const observable = this.rule
            ? this.workflowAutomationsService.updateAutomationRule(this.modalData.workflowAutomation._id, this.rule._id, body)
            : this.workflowAutomationsService.createAutomationRule(this.modalData.workflowAutomation._id, body);

        observable
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => {
                this.fullModalService.close(result);
                this.rule ? Toaster.success('Workflow automation rule updated successfully') :
                    Toaster.success('Workflow automation rule created successfully');
            }, (error) => Toaster.handleApiError(error));
    }
}
