import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RLDatePipe} from '../../../../../../../../pipes/rl-date.pipe';
import {CustomWorkflowComponentModel} from '../../../../../../../../models/api/custom-workflow-component.model';
import {NullUndefinedPipe} from '../../../../../../../../pipes/null-undefined.pipe';
import {StickyNoteModel, StickyNotePostModel} from '../../../../../../../../models/api/sticky-note.model';
import {CustomWorkflowActionModel, EStickyNoteActionName} from '../../../../../../../../models/api/custom-workflow-action.model';
import {DataFieldModel} from '../../../../../../../../models/api/data-field.model';
import {of, Subject} from 'rxjs';
import {UserService} from '../../../../../../../../api/services/users.service';
import {CustomWorkflowService} from '../../../custom-workflow.service';
import {AppConstants} from '../../../../../../../../app.constants';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {StickyNoteUtil} from '../../../sticky-note.util';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {CampaignItemModel} from '../../../../../../../../models/api/campaign-item.model';
import {TabBarItemModel} from '../../../../../../../../models/ui/tab-bar-item.model';
import {BUTTON_TYPE, FullModalConfig, FullModalService, GetPropertyPipe, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {ARApiError, ARLogger} from '@relayter/core';
import {
    IRelinkStickyNoteModalData,
    RelinkStickyNoteComponent
} from '../../../../../../../../components/relink-sticky-note/relink-sticky-note.component';
import {PublicationsService} from '../../../../../../../../api/services/publications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StickyNotesDataService} from '../sticky-notes-data.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {IShowStickyNotesActionOptions} from '../../custom-workflow-preview.component';
import {VariantModel} from '../../../../../../../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../../../../../../../classes/data-fields-component.util';
import {EStickyNoteStatus} from '../../../../../../../../app.enums';
import {EWorkflowActionOptionName} from '../../../../../../../../models/api/custom-workflow-option.model';

@Component({
    selector: 'custom-workflow-sticky-note-details-component',
    templateUrl: './sticky-note-details.component.html',
    styleUrls: ['./sticky-note-details.component.scss'],
    animations: [
        trigger('myInsertTrigger', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('0.2s cubic-bezier(0.22, 0.88, 0.49, 1.27)', style({transform: 'translateX(0)'})),
            ])
        ]),
    ]
})
export class StickyNoteDetailsComponent implements OnInit, OnDestroy {
    public readonly TOOLTIPS = AppConstants.STICKY_NOTE_TOOLTIPS;
    public readonly dateFormats = RLDatePipe.dateFormats;
    public readonly currentDate = new Date();

    @Input() public loading = false;
    @Input() public component: CustomWorkflowComponentModel;
    @Input() public actions: CustomWorkflowActionModel[];
    @Input() public publicationId: string;
    @Input() public campaignId: string;
    @Input() private campaignItemDataFields: DataFieldModel[];

    @ViewChild('textarea', {static: false, read: ElementRef}) public textArea: ElementRef;

    public stickyNote: StickyNoteModel;
    public isNewStickyNote: boolean;
    public addForRelated = false;
    public addToAllVariants = false;
    public stickyNoteInfo: { label: string; value: string }[] = [];
    private onDestroySubject = new Subject<void>();
    private nextItemSelectedSubject = new Subject<void>();
    public messageControl = new UntypedFormControl('', Validators.required);
    public addForRelatedControl = new UntypedFormControl(null);
    public addToAllVariantsControl = new UntypedFormControl(null);
    public formGroup = new UntypedFormGroup({message: this.messageControl});

    // all the actions
    public editStatusActions: CustomWorkflowActionModel[] = [];
    public markDuplicateAction: CustomWorkflowActionModel;
    public deleteAction: CustomWorkflowActionModel;
    public editMessageAction: CustomWorkflowActionModel;
    public addStickyNoteAction: CustomWorkflowActionModel;
    public editBriefingItemAction: CustomWorkflowActionModel;
    public showChangelogsAction: CustomWorkflowActionModel;
    public unlinkAction: CustomWorkflowActionModel;
    public linkAction: CustomWorkflowActionModel;
    public addCommentAction: CustomWorkflowActionModel;
    public showCommentsAction: CustomWorkflowActionModel;
    public showStickyNotesActionOptions: IShowStickyNotesActionOptions;
    public statusesFilter: string[];

    public activeAction: CustomWorkflowActionModel; // used to detect which action is clicked, set it to loading and disable the other action buttons

    // tab bar related
    public TAB_DETAILS = 0;
    public TAB_BRIEFING = 1;
    public TAB_NOTE_CHANGES = 2;

    public detailsTab = new TabBarItemModel('Details', this.TAB_DETAILS);
    public briefingTab = new TabBarItemModel('Briefing changes', this.TAB_BRIEFING);
    public noteChangesTab = new TabBarItemModel('Note changes', this.TAB_NOTE_CHANGES);
    public tabBarItems: TabBarItemModel[] = [this.detailsTab, this.briefingTab, this.noteChangesTab];
    public disabledTabBarItems: TabBarItemModel[] = [];

    private activeVariant: VariantModel;

    private _selectedTab = this.tabBarItems[this.TAB_DETAILS];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab && this.tabBarItems.find((t) => t.title === tab.title)) this._selectedTab = tab;
    }

    constructor(private userService: UserService,
                private customWorkflowService: CustomWorkflowService,
                private stickyNotesDataService: StickyNotesDataService,
                private publicationService: PublicationsService,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService) {
    }

    public ngOnInit(): void {
        this.customWorkflowService.activeVariant$.pipe(
            takeUntil(this.onDestroySubject))
            .subscribe((variant) => {
                    this.activeVariant = variant;
                }
            );

        this.stickyNotesDataService.selectedStickyNote$
            .pipe(
                tap(() => this.nextItemSelectedSubject.next()),
                switchMap((stickyNote) => {
                    return stickyNote && stickyNote.status !== EStickyNoteStatus.NEW
                        ? this.publicationService.getStickyNote(this.publicationId, stickyNote._id)
                        : of(stickyNote);
                }),
                takeUntil((this.onDestroySubject))
            ).subscribe((stickyNote) => {
            this.stickyNote = stickyNote;
            this.activeAction = null;
            if (this.stickyNote) this.resetForm();
        }, (error) => {
            this.stickyNotesDataService.setSelectedStickyNote(null);
            Toaster.handleApiError(error);
        });

        this.stickyNotesDataService.updatedLinkedCampaignItem$.pipe(takeUntil(this.onDestroySubject)).subscribe(
            (campaignItem: CampaignItemModel) => {
                this.stickyNote.campaignItem = campaignItem;
                this.updateStickyNoteInfo();
            }
        );
    }

    public ngOnDestroy(): void {
        this.nextItemSelectedSubject.complete();
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Updates the action booleans
     */
    private updateAllowedActions(): void {
        const user = this.userService.getLoggedInUser();

        const showStickyNoteAction = this.actions.find(item =>
            item.name === EStickyNoteActionName.SHOW_STICKY_NOTES);

        if (showStickyNoteAction?.options) {
            this.showStickyNotesActionOptions = {
                display: showStickyNoteAction.options.find((option) => option.name === EWorkflowActionOptionName.DISPLAY)
            } as IShowStickyNotesActionOptions;
        }

        this.statusesFilter = showStickyNoteAction?.from || [];

        this.editStatusActions =
            StickyNoteUtil.findEditStatusActions(this.stickyNote, this.actions)
                .filter((action) => ![EStickyNoteStatus.DELETED, EStickyNoteStatus.DUPLICATE].includes(action.to));

        this.editMessageAction = StickyNoteUtil.findAction(EStickyNoteActionName.EDIT_STICKY_NOTE_MESSAGE,
                this.stickyNote, this.actions) ||
            StickyNoteUtil.findEditOwnAction(EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_MESSAGE, this.stickyNote,
                this.actions, user);

        this.addStickyNoteAction = this.actions.find((action) =>
            action.name === EStickyNoteActionName.CREATE_STICKY_NOTE);

        // this is a workflow configuration
        this.addForRelated = this.addStickyNoteAction?.options?.find(item =>
            item.name === EWorkflowActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS)?.value;
        if (this.addForRelated) this.formGroup.addControl('addForRelated', this.addForRelatedControl);

        // add to all variants depends on if the publication item has variants
        this.addToAllVariants = this.isNewStickyNote
            && this.activeVariant?.key
            && this.stickyNote.publicationItem?.files.some((item) => item.variant);
        if (this.addToAllVariants) this.formGroup.addControl('addToAllVariantsControl', this.addToAllVariantsControl);

        this.markDuplicateAction =
            StickyNoteUtil.findEditStatusAction(EStickyNoteStatus.DUPLICATE, this.stickyNote, this.actions);

        this.deleteAction =
            StickyNoteUtil.findEditStatusAction(EStickyNoteStatus.DELETED, this.stickyNote, this.actions) ||
            StickyNoteUtil.findEditOwnStatusAction(EStickyNoteStatus.DELETED, this.stickyNote, this.actions, user);

        this.editBriefingItemAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.EDIT_BRIEFING_ITEM, this.stickyNote, this.actions);

        // overwrite the default icon
        if (this.editBriefingItemAction?.icon === 'nucicon_question_fill') this.editBriefingItemAction.icon = 'nucicon_sub_information';

        this.showChangelogsAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.SHOW_NOTE_BRIEFING_CHANGELOG, this.stickyNote,
                this.actions);

        this.unlinkAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.UNLINK, this.stickyNote, this.actions);

        this.linkAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.LINK, this.stickyNote, this.actions);

        this.addCommentAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.ADD_COMMENT, this.stickyNote, this.actions);

        this.showCommentsAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.SHOW_COMMENTS, this.stickyNote, this.actions);
    }

    private resetForm(): void {
        // reset all the info
        this.selectedTab = this.detailsTab;
        this.isNewStickyNote = this.stickyNote.status === EStickyNoteStatus.NEW;
        this.updateAllowedActions();
        this.updateStickyNoteInfo();
        this.messageControl.patchValue(this.stickyNote.message);
        if (this.addForRelated) this.addForRelatedControl.patchValue(null);
        if (this.addToAllVariants) this.addToAllVariantsControl.patchValue(null);
        this.disabledTabBarItems = [];
        if (!this.showChangelogsAction || !this.stickyNote.campaignItem) {
            this.briefingTab.disabled = true;
            this.disabledTabBarItems = [this.briefingTab];
        }
        if (this.isNewStickyNote) {
            this.noteChangesTab.disabled = true;
            this.disabledTabBarItems = [this.noteChangesTab];
        }

        // set the focus
        this.textArea?.nativeElement.children[0].focus();
    }

    /**
     * Utility function used by the template
     * returns the username of the user that created the sticky note
     * @returns {string}
     */
    public getUserName(): string {
        return this.isNewStickyNote
            ? this.userService.getLoggedInUser().fullName
            : NullUndefinedPipe.transform(this.stickyNote.createdBy?.fullName, NullUndefinedPipe.defaultValues.DELETED_USER);
    }

    private updateStickyNoteInfo(): void {
        if (this.showStickyNotesActionOptions?.display) {
            this.stickyNoteInfo = [];
            if (this.stickyNote.campaignItem) for (const displayProperty of this.showStickyNotesActionOptions.display.value) {
                const label = this.campaignItemDataFields.find(item => item.fieldName === displayProperty);
                if (label) this.stickyNoteInfo.push({
                    label: label.name,
                    value: GetPropertyPipe.transform(this.stickyNote.campaignItem.dataFields, displayProperty,
                        DataFieldsComponentUtil.getDataFieldFormatter(null, this.activeVariant?.key))
                });
            }
        }
    }

    /**
     * Handle click on link briefing item
     * @param {StickyNoteModel} stickyNote
     */
    public openRelinkBriefingItemModal(stickyNote: StickyNoteModel): void {
        const modalData: IRelinkStickyNoteModalData = {
            spreadId: stickyNote.publicationItem._id,
            campaignId: this.campaignId,
            publicationId: this.publicationId,
            campaignItem: stickyNote.campaignItem,
            variant: this.activeVariant
        };
        const title = 'Link new briefing item';
        const subtitle = stickyNote.campaignItem ?
            'Link the note to another briefing item on the current spread.' :
            'Link the note to a briefing item on the current spread.';
        const modalConfig = new FullModalConfig(title, subtitle, modalData);

        this.fullModalService.open(RelinkStickyNoteComponent, modalConfig).afterClosed()
            .pipe(
                filter((linkedItem) => {
                    if (!linkedItem) this.activeAction = null;
                    return !!linkedItem;
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((campaignItem) => this.onStickyRelink(campaignItem));
    }

    public onStickyNoteActionClicked(action: CustomWorkflowActionModel): void {
        const stickyNoteBody = new StickyNoteModel();
        stickyNoteBody._id = this.stickyNote._id;

        this.activeAction = action;

        switch (action.name) {
            case EStickyNoteActionName.CREATE_STICKY_NOTE:
                this.postStickyNote();
                break;
            case EStickyNoteActionName.EDIT_STICKY_NOTE_STATUS:
            case EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_STATUS:
                stickyNoteBody.status = action.to;
                if (action.to === EStickyNoteStatus.DELETED) {
                    this.openDeleteStickyNoteDialog(stickyNoteBody);
                } else {
                    this.updateStickyNote(stickyNoteBody);
                }
                break;
            case EStickyNoteActionName.EDIT_STICKY_NOTE_MESSAGE:
            case EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_MESSAGE:
                stickyNoteBody.message = this.messageControl.value;
                this.updateStickyNote(stickyNoteBody);
                break;

            case EStickyNoteActionName.UNLINK:
                stickyNoteBody.campaignItem = new CampaignItemModel();
                this.openUnlinkDialog(stickyNoteBody);
                break;

            case EStickyNoteActionName.LINK:
                this.openRelinkBriefingItemModal(this.stickyNote);
                break;

            case EStickyNoteActionName.EDIT_BRIEFING_ITEM:
                this.customWorkflowService.setEditCampaignItemId({
                    campaignItemId: this.stickyNote.campaignItem._id,
                    pubItemId: this.stickyNote.publicationItem._id
                });
                this.activeAction = null;
                break;

            default:
                ARLogger.debug('Missing implementation for sticky action: ' + action.name);
                this.activeAction = null;
                break;
        }
    }

    private openDeleteStickyNoteDialog(stickyNote: StickyNoteModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete note', 'Please confirm that you wish to delete this note.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            deleteDialog.close();
            this.activeAction = null;
        });
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.updateStickyNote(stickyNote);
        });
    }

    private openUnlinkDialog(stickyNote: StickyNoteModel): void {
        const unlinkDialogConfig = new NucDialogConfigModel('Unlink briefing item', 'Please confirm that you wish to unlink this briefing item.');
        const unlinkDialog = this.dialogService.openDialog(unlinkDialogConfig);
        unlinkDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            unlinkDialog.close();
            this.activeAction = null;
        });
        unlinkDialogConfig.addAction('Unlink', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            unlinkDialog.close();
            this.stickyNote.campaignItem = null;
            this.briefingTab.disabled = true;
            this.updateStickyNoteInfo();
            if (stickyNote._id) {
                this.updateStickyNote(stickyNote, 'Briefing item unlinked successfully');
            } else {
                this.activeAction = null;
            }
        });
    }

    private postStickyNote(): void {
        const stickyNotePostBody = new StickyNotePostModel(this.messageControl.value, this.stickyNote.position,
            this.stickyNote.campaignItem, this.activeVariant?._id);

        const addForRelated = this.addForRelated && this.addForRelatedControl.value && this.stickyNote.campaignItem;
        const addToAllVariants = this.addToAllVariants && this.addToAllVariantsControl.getRawValue();

        this.publicationService.postStickyNote(this.publicationId,
            this.stickyNote.publicationItem._id,
            stickyNotePostBody,
            this.component._id,
            !!addForRelated,
            !!addToAllVariants
        ).pipe(
            takeUntil(this.nextItemSelectedSubject),
            takeUntil(this.onDestroySubject),
        ).subscribe({
            next: (createdStickyNote: StickyNoteModel) => {
                this.stickyNotesDataService.announceRefreshStickyNotes();
                this.stickyNotesDataService.setSelectedStickyNote(createdStickyNote);
            },
            error: (error: ARApiError) => {
                Toaster.handleApiError(error);
                this.activeAction = null;
            }
        });
    }

    private updateStickyNote(stickyNote: StickyNoteModel, toasterMessage?: string): void {
        this.publicationService.updateStickyNote(this.publicationId, stickyNote, this.component._id)
            .pipe(
                takeUntil(this.nextItemSelectedSubject),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (updatedStickyNote: StickyNoteModel) => {
                    if (toasterMessage) Toaster.success(toasterMessage);
                    this.stickyNotesDataService.announceRefreshStickyNotes();
                    const selectedStickyNote = this.statusesFilter.includes(updatedStickyNote.status) ? updatedStickyNote : null;
                    this.stickyNotesDataService.setSelectedStickyNote(selectedStickyNote);
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.activeAction = null;
                }
            });
    }

    public onStickyRelink(campaignItem: CampaignItemModel): void {
        this.stickyNote.campaignItem = campaignItem;
        this.updateStickyNoteInfo();
        this.briefingTab.disabled = !this.showChangelogsAction || !this.stickyNote.campaignItem;

        if (this.stickyNote._id) {
            const stickyNoteBody = new StickyNoteModel();
            stickyNoteBody._id = this.stickyNote._id;
            stickyNoteBody.campaignItem = campaignItem;
            this.updateStickyNote(stickyNoteBody, 'Briefing item linked successfully');
        } else {
            this.activeAction = null;
        }
    }

    public unsetStickyNote(): void {
        this.stickyNotesDataService.setSelectedStickyNote(null);
    }

    public onCancelClicked(): void {
        this.isNewStickyNote
            ? this.stickyNotesDataService.setSelectedStickyNote(null)
            : this.messageControl.patchValue(this.stickyNote.message);
    }

    public onCommentAdded(): void {
        this.stickyNotesDataService.announceRefreshStickyNotes();
    }
}
