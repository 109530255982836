<div class="sidebar" [class.loading]="(indicatorStatus$ | async) === EIndicatorStatus.LOADING">

    <div class="sort-container" *ngIf="workflowLayoutItems.length > 0">

        <div class="popout-button">
            <nuc-button-secondary text="Sort"
                                  [disabled]="(indicatorStatus$ | async) === EIndicatorStatus.LOADING"
                                  iconPosition="end"
                                  [nucTooltip]="sortValue.getTitle()"
                                  [matMenuTriggerFor]="menu">
            </nuc-button-secondary>

            <mat-menu #menu="matMenu" class="menu-items-container">
                <button mat-menu-item
                        *ngFor="let sortOption of sortOptions"
                        (click)="setSortValue(sortOption)"
                        [ngClass]="sortValue === sortOption && 'selected-option'"
                        [disableRipple]="true">
                    {{sortOption.getTitle()}}
                </button>
            </mat-menu>
        </div>

        <nuc-button-secondary [icon]="sortAsc ? 'nucicon_sort_asc' : 'nucicon_sort_dsc'"
                              [nucTooltip]="sortAsc ? 'Sort ascending' : 'Sort descending'"
                              [disabled]="(indicatorStatus$ | async) === EIndicatorStatus.LOADING"
                              (click)="sortAsc = !sortAsc; resetWorkflowLayoutItems()"></nuc-button-secondary>
    </div>

    <div class="items-container" scrollIntoView>
        <ng-container *ngFor="let row of workflowLayoutItems; index as index">
            <nuc-media-card-view
                (click)="setSelectedWorkflowLayoutItem(row)"
                [isSelected]="(publicationItemSelectionService.selectedWorkflowLayoutItem$ | async)?.index === row.index"
                [media]="row.media"
                itemWidth="128">
                <div content>
                    {{row.title}}
                </div>
            </nuc-media-card-view>
        </ng-container>

        <nuc-button-secondary text="Show more"
                              *ngIf="hasNext"
                              (click)="getPublicationItems()"
                              [disabled]="!hasNext || (indicatorStatus$ | async) === EIndicatorStatus.LOADING">
        </nuc-button-secondary>
    </div>
</div>
<rl-loading-indicator *ngIf="(indicatorStatus$ | async) === EIndicatorStatus.LOADING"></rl-loading-indicator>
