import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FilesRevisionModel, PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {CustomWorkflowComponentModel} from '../../../../../../../models/api/custom-workflow-component.model';
import {TabBarItemModel} from '../../../../../../../models/ui/tab-bar-item.model';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {DataFieldModel} from '../../../../../../../models/api/data-field.model';
import {Clipboard} from '@angular/cdk/clipboard';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {
    CustomWorkflowBriefingChangesDataService,
    IBriefingChange
} from './custom-workflow-briefing-changes-data.service';
import {
    EScrollPosition,
    ScrollItemIntoViewDirective
} from '../../../../../../../directives/scroll-item-into-view.directive';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../../../../../../classes/data-fields-component.util';
import {EWorkflowActionOptionName} from '../../../../../../../models/api/custom-workflow-option.model';

export interface IShowBriefingChangesActionOptions {
    display: { name: EWorkflowActionOptionName.DISPLAY; value: string };
}

@Component({
    selector: 'custom-workflow-briefing-changes-component',
    templateUrl: './custom-workflow-briefing-changes.component.html',
    styleUrls: ['./custom-workflow-briefing-changes.component.scss']
})
export class CustomWorkflowBriefingChangesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public publicationItems: PublicationItemModel[];
    @Input() public loading = false;
    @Input() public component: CustomWorkflowComponentModel;
    @Input() public publication: PublicationModel;
    @Input() public dataFields: DataFieldModel[];
    @Input() public action: CustomWorkflowActionModel;

    @ViewChild(ScrollItemIntoViewDirective) private scrollContainer: ScrollItemIntoViewDirective;
    private firstLoad = true;

    public selectedBriefingChange: IBriefingChange;
    public selectedPublicationItem: PublicationItemModel;
    public lastFileVersion: FilesRevisionModel;
    public actionOptions: IShowBriefingChangesActionOptions;
    public displayTitle: string;
    private activeVariant: VariantModel;
    public dataFieldFormatter = DataFieldsComponentUtil.getDataFieldFormatter(null, null);

    private onDestroySubject = new Subject<void>();

    // tab bar related
    public tabBarItems: TabBarItemModel[] = [];
    private _selectedTab: TabBarItemModel;
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this.selectedPublicationItem = this.publicationItems[index];
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.lastFileVersion = this.selectedPublicationItem?.getVariantFilesRevisions(this.activeVariant?._id)
                ? this.selectedPublicationItem?.
                    getVariantFilesRevisions(this.activeVariant?._id)[this.selectedPublicationItem?.
                    getVariantFilesRevisions(this.activeVariant?._id).length - 1]
                : null;
        }
    }

    constructor(private clipboard: Clipboard,
                private customWorkflowBriefingChangesDataService: CustomWorkflowBriefingChangesDataService,
                private customWorkflowDataService: CustomWorkflowService) {
    }

    public ngOnInit(): void {
        // make sure view is loaded, then we can navigate without any issue
        this.customWorkflowBriefingChangesDataService.selectedBriefingChange$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((briefingChange: IBriefingChange) => {
            this.selectedBriefingChange = briefingChange;

            if (this.selectedBriefingChange) {
                const selectedPublicationItemId = this.selectedBriefingChange.publicationItemId;
                if (this.selectedBriefingChange && this.selectedPublicationItem._id !== selectedPublicationItemId) {
                    const publicationItemToSelect = this.publicationItems.find((item) => item._id === selectedPublicationItemId);
                    this.selectedTab = this.tabBarItems.find((item) => item.title === publicationItemToSelect.getTitle());
                }

                const index = this.selectedPublicationItem.changedCampaignItems.findIndex((item) =>
                    item._id === this.selectedBriefingChange.campaignItemId);

                if (this.firstLoad) {
                    setTimeout(() => {
                        if (this.scrollContainer) this.scrollContainer.scrollToActiveItem(index, EScrollPosition.TOP);
                        this.firstLoad = false;
                    }, 0);
                } else {
                    if (this.scrollContainer) this.scrollContainer.scrollToActiveItem(index, EScrollPosition.TOP);
                }
            }
        });

        this.customWorkflowDataService.activeVariant$.pipe(takeUntil(this.onDestroySubject))
            .subscribe((activeVariant: VariantModel) => {
                this.activeVariant = activeVariant;
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.publicationItems && this.publicationItems) {
            this.initiateTabBar();
        }

        if (changes.action && this.action) { // reset action options when action changes
            this.actionOptions = {
                display: this.action.options.find((option) => option.name === EWorkflowActionOptionName.DISPLAY)
            } as IShowBriefingChangesActionOptions;
            this.displayTitle = this.dataFields.find((field) => field.fieldName === this.actionOptions.display?.value.split('.')[0])?.name;
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initiateTabBar(): void {
        this.tabBarItems = [];

        let tabBarIndex = 0;
        for (const publicationItem of this.publicationItems) {
            this.tabBarItems.push(new TabBarItemModel(publicationItem.getTitle(), tabBarIndex));
            tabBarIndex++;
        }

        if (this.tabBarItems.length > 0) this.selectedTab = this.tabBarItems[0];
    }

    public copyToClipboard(value: string): void {
        this.clipboard.copy(value) ? Toaster.success('Copied to clipboard!') :
            Toaster.error('Could not copy to clipboard');
    }

    public onBriefingChangeClicked(publicationItemId: string, campaignItemId: string): void {
        this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange({
            publicationItemId,
            campaignItemId
        } as IBriefingChange);
    }

}
