import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {ApplicationOptions} from 'pixi.js';
import {Subject, Subscription} from 'rxjs';
import {AreaClickEvent} from './area/template-area.pixi';
import {ItemPixi, MoveEvent, ResizeEvent} from './item/item.pixi';
import {ItemStagePixi} from './stages/item-stage.pixi';
import {TemplateStagePixi} from './stages/template-stage.pixi';
import {DialogCustomContentConfig, NucDialogCustomContentService} from '@relayter/rubber-duck';
import {INoteFormData, NoteFormComponent} from './note/note-form/note-form.component';
import {MasterPagesService} from '../../../../../../../api/services/master-pages.service';
import {BackgroundStagePixi} from './stages/background-stage.pixi';
import {SpreadContentUpdateModel} from '../../../../../../../models/api/spread-content-update.model';
import {ContentAreaModel, PublicationItemContentModel} from '../../../../../../../models/api/publication-item-content.model';
import {EPropertySettingsContext, PropertySettingsService} from '../../../../../../../components/property-settings/property-settings.service';
import {takeUntil} from 'rxjs/operators';
import {PropertySettingsModel} from '../../../../../../../components/property-settings/property-settings.model';
import {AppConstants} from '../../../../../../../app.constants';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {SpreadNoteModel} from '../../../../../../../models/api/spread-note.model';
import {CampaignModel} from '../../../../../../../models/api/campaign.model';
import {CustomWorkflowLayoutService, LayoutDragDropItem} from '../custom-workflow-layout.service';
import {NotePreviewComponent} from './note/note-preview/note-preview.component';
import {TemplateModel} from '../../../../../../../models/api/template.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';

export interface IEditorOptions {
    editEnabled: boolean;
    editBriefingItem: boolean;
    showLayoutNotes: boolean;
}

@Component({
    selector: 'rl-spread-editor-component',
    templateUrl: './spread-editor.component.html',
    styleUrls: ['./spread-editor.component.scss']
})
export class SpreadEditorComponent implements AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
    // TODO: Fix common pitfalls (Memory issues / cleanup textures): https://github.com/pixijs/pixi.js/wiki/v4-Tips,-Tricks,-and-Pitfalls

    private masterPageSubscription: Subscription;

    @Input() public editorOptions: IEditorOptions;
    @Input() public content: PublicationItemContentModel[];
    @Input() public template: TemplateModel;
    @Input() public campaign: CampaignModel;
    @Input() public activeVariant: VariantModel;

    @Output() public editCampaignItem: EventEmitter<CampaignItemModel> = new EventEmitter<CampaignItemModel>();

    @ViewChild('editor', {static: true}) public editor: ElementRef;
    @ViewChild('canvasContainer', {static: true})
    public container: ElementRef;

    private oldContainerWidth: number;
    private oldContainerHeight: number;
    private pixiApp: PIXI.Application;
    private scalingStage: PIXI.Container;
    private backgroundStage: BackgroundStagePixi;
    private templateStage: TemplateStagePixi;
    private itemStage: ItemStagePixi;
    private propertySettings: PropertySettingsModel;

    private scaleRatio: number = 1;

    private onDestroySubject = new Subject<void>();

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                private propertySettingsService: PropertySettingsService,
                private masterPageService: MasterPagesService,
                private customWorkflowLayoutService: CustomWorkflowLayoutService) {}


    public ngAfterViewChecked() {
        if (this.oldContainerWidth === this.container.nativeElement.offsetWidth &&
            this.oldContainerHeight === this.container.nativeElement.offsetHeight) {
            return;
        } else {
            this.oldContainerWidth = this.container.nativeElement.offsetWidth;
            this.oldContainerHeight = this.container.nativeElement.offsetHeight;
            this.update();
        }
    }

    public ngAfterViewInit(): void {
        this.initializePixi();
    }

    public ngOnChanges(): void {
        // Will only fire, if one of the input references changing (not on property change)
        this.refreshLayout();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
        this.pixiApp?.destroy(true);
    }

    public refreshLayout(): void {
        this.initializeStages();
        this.update();
    }

    private initializePixi(): void {
        const editorOptions: ApplicationOptions = {
            backgroundColor: 0xffffff,
            transparent: false,
            resolution: window.devicePixelRatio,
            view: this.editor.nativeElement,
            autoResize: true
        };

        this.pixiApp = new PIXI.Application(0, 0, editorOptions);

        this.propertySettingsService.getSettings(EPropertySettingsContext.BRIEFING).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((propertySettings) => {
            this.propertySettings = propertySettings;
            this.refreshLayout();
        });

        this.customWorkflowLayoutService.addToLayout$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((dragDropItem: LayoutDragDropItem) => this.addToLayout(dragDropItem));
    }

    private update(): void {
        if (!this.pixiApp) {
            return;
        }

        this.getScaleRatio();

        this.pixiApp.renderer.resize(this.container.nativeElement.offsetWidth, this.container.nativeElement.offsetHeight);

        this.initializeStages();

        this.drawBackground();
        this.drawTemplate();
        this.drawContent();

        const centerPointX = (this.container.nativeElement.offsetWidth / 2) -
            (this.template.pageSize.width * this.template.pages / 2 * this.scaleRatio);
        const centerPointY = (this.container.nativeElement.offsetHeight / 2) -
            (this.template.pageSize.height / 2 * this.scaleRatio);

        this.scalingStage.position = new PIXI.Point(centerPointX, centerPointY);
        this.scalingStage.scale = new PIXI.Point(this.scaleRatio, this.scaleRatio);
    }

    private getScaleRatio(): void {
        const template = this.template;
        const maxWidth = this.container.nativeElement.offsetWidth - 5;
        const maxHeight = this.container.nativeElement.offsetHeight - 5;

        const width = template.pageSize.width * template.pages;

        this.scaleRatio = Math.min(maxWidth / width, maxHeight / template.pageSize.height)
    }

    private initializeStages(): void {
        if (!this.pixiApp) {
            return;
        }
        // Cleanup old stages if they exist
        if (this.scalingStage) {
            this.scalingStage.destroy({children: true});
        }
        if (this.templateStage) {
            this.templateStage.destroy({children: true});
        }
        if (this.backgroundStage) {
            this.backgroundStage.destroy({children: true});
        }
        if (this.itemStage) {
            this.itemStage.destroy({children: true});
        }

        this.scalingStage = new PIXI.Container();
        this.backgroundStage = new BackgroundStagePixi(this.editorOptions);
        this.templateStage = new TemplateStagePixi(this.editorOptions, this.scaleRatio);
        this.itemStage = new ItemStagePixi();

        this.scalingStage.addChild(this.backgroundStage);
        this.scalingStage.addChild(this.templateStage);
        this.scalingStage.addChild(this.itemStage);
        this.pixiApp.stage.addChild(this.scalingStage);

        this.itemStage.onItemMoving$.subscribe((event) => this.onItemMoving(event));
        this.itemStage.onItemMoved$.subscribe(() => this.onItemMoved());
        this.itemStage.onItemMoveCancelled$.subscribe(() => this.onItemMoveCancelled());

        this.itemStage.onItemEdit$.subscribe((itemToEdit) => this.editContent(itemToEdit));
        this.itemStage.onItemRemoved$.subscribe((removedItem) => this.onItemRemoved(removedItem));
        this.itemStage.onItemResized$.subscribe(() => this.onItemResized());
        this.itemStage.onItemResizing$.subscribe((resizeEvent) => this.onItemResizing(resizeEvent));
        this.itemStage.onItemClicked$.subscribe((itemClicked) => this.viewNote(itemClicked)); // only when editEnabled is false

        this.templateStage.onAreaClicked$.subscribe((areaClickEvent) => this.createNote(areaClickEvent));
    }

    private drawBackground(): void {
        if (this.masterPageSubscription) {
            this.masterPageSubscription.unsubscribe();
        }

        this.backgroundStage.reset();
        if (this.template.masterPage) {
            // TODO: Update detail call tp populate master page there so we don't need a api call here (/publication/{id}/items/{itemId})
            this.masterPageSubscription = this.masterPageService.getMasterPage(this.template.masterPage)
                .subscribe((masterPage) => this.backgroundStage.setMasterPage(masterPage));
        } else {
            this.backgroundStage.setDefaultBackground(this.template);
        }
    }

    private drawTemplate(): void {
        this.templateStage.setTemplate(this.template);
    }

    private drawContent(): void {
        // TODO: Move this into itemStage
        this.content.forEach((contentItem, index) => {
            const templateArea = this.template.areas.find((area) => contentItem.area._id === area._id);

            const item = new ItemPixi(
                contentItem.getContent(),
                this.template,
                templateArea,
                contentItem.area.startRow,
                contentItem.area.startColumn,
                contentItem.area.rowSpan,
                contentItem.area.columnSpan,
                index,
                this.activeVariant,
                this.editorOptions,
                this.scaleRatio,
                contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM ? this.propertySettings : undefined);

            this.itemStage.addItem(item);
        });
    }

    private onItemMoving(event: MoveEvent): void {
        this.templateStage.highlight(event);
    }

    private onItemMoved(): void {
        this.templateStage.resetHighlight();
        this.notifyLayoutUpdate();
    }

    private onItemMoveCancelled(): void {
        this.templateStage.resetHighlight();
    }

    private onItemResizing(resizeEvent: ResizeEvent): void {
        this.templateStage.highlight(resizeEvent);
    }

    private onItemResized(): void {
        this.templateStage.resetHighlight();
        this.notifyLayoutUpdate();
    }

    private onItemRemoved(removedItem: ItemPixi): void {
        removedItem.destroy();
        this.notifyLayoutUpdate();
    }

    private notifyLayoutUpdate(): void {
        const content = this.itemStage.getItems().map((item) => {
            const contentArea = new ContentAreaModel(item.templateArea._id, item.size.row, item.size.column, item.size.rowSpan, item.size.columnSpan);
            return new SpreadContentUpdateModel(item.content, contentArea);
        });

        this.customWorkflowLayoutService.spreadContentUpdated(content);
    }

    private editContent(itemToEdit: ItemPixi): void {
        if (itemToEdit.content instanceof SpreadNoteModel) {
            const note = itemToEdit.content;
            const dialogConfig = new DialogCustomContentConfig(
                'Edit note', 'Specify the color and enter a description of your note.', {note} as INoteFormData);
            this.dialogCustomContentService.open(NoteFormComponent, dialogConfig).afterClosed().subscribe((result) => {
                if (result) {
                    // After resizing, pixi objects are removed from the canvas and replaced by new ones
                    // So, find the pixi object with the same content index
                    const item = this.itemStage.getItems().find(item => item.contentIndex === itemToEdit.contentIndex);
                    if (item) {
                        item.updateContent(result);
                        this.notifyLayoutUpdate();
                    }
                }
            });
        } else if (itemToEdit.content instanceof CampaignItemModel) {
            this.editCampaignItem.emit(itemToEdit.content);
        }
    }

    private createNote(areaClickEvent: AreaClickEvent): void {
        const dialogConfig = new DialogCustomContentConfig(
            'Add note', 'Specify the color and enter a description of your note.', {});
        this.dialogCustomContentService.open(NoteFormComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                const newItem = new ItemPixi(result,
                    this.template,
                    areaClickEvent.area.templateArea,
                    areaClickEvent.row, areaClickEvent.column,
                    1,
                    1,
                    -1,
                    this.activeVariant,
                    this.editorOptions,
                    this.scaleRatio);
                this.itemStage.addItem(newItem);
                this.notifyLayoutUpdate();
            }
        });
    }

    private viewNote(item: ItemPixi): void {
        if (item.content instanceof SpreadNoteModel) {
            const dialogConfig = new DialogCustomContentConfig('View note', '', {note: item.content});
            this.dialogCustomContentService.open(NotePreviewComponent, dialogConfig);
        }

    }

    // Handles the dragging in campaign items
    private addToLayout(dropEvent: LayoutDragDropItem): void {
        if (!this.editorOptions.editEnabled) {
            return;
        }

        const x = dropEvent.mouseEvent.offsetX;
        const y = dropEvent.mouseEvent.offsetY;

        this.templateStage.resetHighlight();
        this.itemStage.resetBlocking();

        const placeEvent = this.templateStage.getPlaceEventForGlobalPosition(x, y);
        if (!placeEvent) {
            return;
        }

        if (!this.itemStage.getItems().find((item) => item.blocks(placeEvent))) {
            const isCampaignItem = dropEvent.dragData instanceof CampaignItemModel;
            const newItem = new ItemPixi(dropEvent.dragData,
                this.template,
                placeEvent.templateArea,
                placeEvent.row, placeEvent.column, placeEvent.rowSpan, placeEvent.columnSpan,
                -1,
                this.activeVariant,
                this.editorOptions,
                this.scaleRatio,
                isCampaignItem ? this.propertySettings : undefined);
            this.itemStage.addItem(newItem);
            this.notifyLayoutUpdate();
        }
    }
}
