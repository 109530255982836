import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {BooleanOption, ConditionType, ConditionTypeValue, FormatOption, PropertyOperator} from '../format-ruleset.constants';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ConditionValueType, RuleConditionModel} from '../../../models/api/rule-condition.model';
import {ValueModel} from '../models/api/ruleset-value.model';
import {IFormatRulesetItemFormComponentData} from '../format-ruleset-item-form/format-ruleset-item-form.component';
import {EDataCollectionName, EDataFieldTypes, RULESET_OPERATORS} from '../../../app.enums';
import {IFormatRulesetAssetItemFormComponentData} from '../format-ruleset-asset-item-form/format-ruleset-asset-item-form.component';
import {FormatRulesetAssetItemModel} from '../models/api/format-ruleset-asset-item.model';
import {format as dateFormatter} from 'date-fns-tz/esm';
import {nl as DEFAULT_LOCALE} from 'date-fns/locale';
import {ARLogger} from '@relayter/core';
import {AppConstants} from '../../../app.constants';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {Toaster} from '../../../classes/toaster.class';
import {ConditionGroupsModel} from '../../../models/api/condition-groups.model';
import {ConditionGroupModel} from '../../../models/api/condition-group.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ConditionForm, ConditionGroup, ConditionGroups} from './condition-group-form/condition-group-form.component';
import {InDesignLibraryItemModel} from '../models/api/indesign-library-item.model';
import {ValueForm} from './values-form/values-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PropertyControlComponent} from '../../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../../models/ui/property-value.model';

export enum ERulesetContext {
    ITEMS,
    ASSET_ITEMS
}

export interface IFormatRulesetComponentData {
    context: ERulesetContext;
}

@Directive()
export abstract class BaseRulesetItemFormComponent implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);

    public nameControl = new FormControl<string>('', Validators.required);
    public itemControl = new FormControl<InDesignLibraryItemModel>(null, Validators.required);
    public conditionGroups = new ConditionGroups([(control: AbstractControl) => {
        const groups = control['groups'] as FormArray<ConditionGroup>;
        for (const group of groups.controls) {
            const rules = group['rules'] as FormArray<FormGroup<ConditionForm>>;
            for (const rule of rules.controls) {
                if (rule.invalid) {
                    return {message: 'All groups need to be valid.'};
                }
            }
        }
        return null;
    }]);

    public valueGroups = new FormArray<FormGroup<ValueForm>>([]);

    private saveButtonConfig: ButtonConfig;

    public ruleProperties: RulePropertyModel[];

    public formGroup: FormGroup;
    public modalData: IFormatRulesetItemFormComponentData | IFormatRulesetAssetItemFormComponentData;

    public tags: DropdownItem<string>[] = [];
    public DATE_FORMAT = FormatOption.DATE_FORMAT.getValue();
    public TO_STRING = FormatOption.TO_STRING.getValue();

    public layerOptions: DropdownItem<string>[];
    private layerProperty: string;
    public searchLayer: string;
    public totalLayers: number;

    public static GROUP_OPTIONS: IDropdownItem<RULESET_OPERATORS>[] = [
        new DropdownItem('AND', RULESET_OPERATORS.AND, false, 'nucicon_code-and'),
        new DropdownItem('OR', RULESET_OPERATORS.OR, false, 'nucicon_code-or')];

    protected constructor(protected fullModalService: FullModalService,
                          protected dataCollectionService: DataCollectionService) {
    }

    // TODO: Line up DropDownItems
    private static getConditionValue(condition?: RuleConditionModel, dataType?: EDataFieldTypes): ConditionValueType {
        switch (condition?.type) {
            case ConditionType.LEADING_LENGTH.getValue():
            case ConditionType.LEADING_LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH_GREATER_THAN.getValue():
            case ConditionType.LENGTH.getValue():
            case ConditionType.LOWER_OR_EQUAL.getValue():
            case ConditionType.LOWER_THAN.getValue():
            case ConditionType.GREATER_OR_EQUAL.getValue():
            case ConditionType.GREATER_THAN.getValue():
            case ConditionType.REGEX.getValue():
                return condition.value;
            case ConditionType.NOT_EQUALS.getValue():
            case ConditionType.EQUALS.getValue():
            case ConditionType.INCLUDES.getValue():
            case ConditionType.NOT_INCLUDES.getValue():
                // Prevent compiler error, but value is always not a Date for type ENUM
                if (dataType === EDataFieldTypes.ENUM && !(condition.value instanceof Date)) {
                    if (condition.operator === PropertyOperator.LENGTH.getValue()) {
                        return condition.value;
                    } else {
                        return new DropdownItem(condition.value as string, condition.value as string);
                    }
                } else if (dataType === EDataFieldTypes.BOOLEAN) {
                    return condition.value ? BooleanOption.TRUE : BooleanOption.FALSE;
                }
                return condition.value;
            case ConditionType.EXISTS.getValue():
            case ConditionType.NOT_EXISTS.getValue():
                break;
        }
    }

    public ngOnInit(): void {
        this.layerProperty = this.modalData.context === ERulesetContext.ITEMS ? 'items.layer' : 'assetItems.layer';

        this.getLayers();
        this.initButtons();
        this.initForm();
    }

    private initButtons(): void {
        this.saveButtonConfig =
            new ButtonConfig(BUTTON_TYPE.PRIMARY, this.modalData.item ? 'Save' : 'Create', false,
                false, this.formGroup.status !== 'VALID');
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveRule());

        this.fullModalService.setModalActions(actions);
    }

    protected initForm(): void {
        this.ruleProperties = this.modalData.ruleProperties;

        if (this.modalData.item) {

            if (!(this.modalData.item instanceof FormatRulesetAssetItemModel)) {
                this.modalData.item.values.forEach((value) => {
                    this.addValueGroup(value);
                });
            }
            this.conditionGroups.setOperator(BaseRulesetItemFormComponent.GROUP_OPTIONS.find(
                option => option.getValue() === this.modalData.item.conditions.operator));
            this.modalData.item.conditions.groups.forEach((condition) => {
                const conditionGroup = new ConditionGroup(BaseRulesetItemFormComponent.GROUP_OPTIONS.find(
                    option => option.getValue() === condition.operator));
                for (const rule of condition.rules) {
                    this.addConditionGroup(rule, conditionGroup);
                }
                this.conditionGroups.addGroup(conditionGroup);
            });
        }

        this.saveButtonConfig.disabled = this.formGroup.status !== 'VALID';

        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);

        this.itemControl.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((libraryItem: any) => {
            this.tags = libraryItem?.tags.map((tag: string) => new DropdownItem<string>(tag, tag)) || [];
            this.valueGroups.controls.forEach((item) => {
                const tagControl = item.controls.tag;
                const tagValue = tagControl.value?.getValue();

                if (!this.tags.find((tag) => tagValue === tag.getValue())) {
                    tagControl.patchValue(null);
                }
            });
        });
    }

    public searchLayers(searchValue: string): void {
        if (this.searchLayer !== searchValue) {
            this.searchLayer = searchValue;
            this.layerOptions = [];

            this.getLayers();
        }
    }

    public getLayers(offset = 0): void {
        this.dataCollectionService.getDataCollectionValues(EDataCollectionName.INDESIGN_RULE_SET,
            this.layerProperty, null, AppConstants.PAGE_SIZE_DEFAULT, offset, this.searchLayer)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    const layers = results.items.map(item => new DropdownItem<string>(item.title, item.value));
                    this.layerOptions = this.layerOptions?.concat(layers) || layers;
                    this.totalLayers = results.total;
                },
                error: Toaster.handleApiError
            });
    }

    protected getConditions(): ConditionGroupsModel {
        return new ConditionGroupsModel(this.conditionGroups.operator.value.getValue(),
            this.conditionGroups.groups.controls.map((group: ConditionGroup) => {
                return new ConditionGroupModel(group.operator.value.getValue(),
                    group.rules.controls.map((rule) => {

                        const property = rule.value.property.path;
                        const operator = rule.value.operator?.getValue();
                        const type = rule.value.type?.getValue();
                        const typeValue = rule.value.typeValue?.getValue();
                        const value = rule.value.value instanceof DropdownItem ?
                            rule.value.value.getValue() : rule.value.value;
                        const valueProperty = rule.value.valueProperty?.path;
                        const lastRuleProperty = rule.value.property;
                        const dataType = lastRuleProperty?.dataType?.type === EDataFieldTypes.DATE ?
                            EDataFieldTypes.DATE : null;

                        return new RuleConditionModel(property,
                            type,
                            typeValue,
                            value,
                            valueProperty,
                            operator,
                            dataType
                        );
                    }));
            }));
    }

    protected getValues(): ValueModel[] {
        return this.valueGroups.value.map((value) => {
            return new ValueModel(value.tag.getValue(), value.property.path,
                value.format ? value.format.getValue() : undefined,
                value.formatString ? value.formatString : undefined);
        });
    }

    public addConditionGroup(condition?: RuleConditionModel, parent?: ConditionGroup): void {

        const property = PropertyControlComponent.getPropertyValueModel(condition?.property, this.ruleProperties, [], false);
        const valueProperties = RulePropertyModel.filterPropertiesOnDataType(property?.dataType.type, this.ruleProperties);

        const conditionGroup = new FormGroup<ConditionForm>({
            property: new FormControl<PropertyValueModel>(
                property, Validators.required),
            operator: new FormControl(PropertyOperator.getByValue(condition?.operator)),
            type: new FormControl(ConditionType.getByValue(condition?.type)),
            typeValue: new FormControl(ConditionTypeValue.getByValue(condition?.typeValue)),
            value: new FormControl(BaseRulesetItemFormComponent.getConditionValue(condition, property?.dataType.type)),
            valueProperty: new FormControl(PropertyControlComponent.getPropertyValueModel(condition?.valueProperty, valueProperties, [])),
        }, [(control: AbstractControl) => {

            if (control.value.property?.isArray && !control.value.property?.arrayIndexSelected && !control.value.operator) {
                return {valueRequired: 'Operator is required for this property'};
            }

            if ((!control.value.property?.isArray || control.value.property?.arrayIndexSelected) && !control.value.type) {
                return {valueRequired: 'Type is required for this property'};
            }

            if (control.value.operator?.typeRequired && !control.value.type) {
                return {valueRequired: 'Type is required for this operator'};
            }

            if (control.value.type?.valueRequired) {
                if ((!control.value.typeValue || control.value.typeValue === ConditionTypeValue.CUSTOM) &&
                    (control.value.value === undefined || control.value.value === null ||
                    control.value.value === '')) {
                    return {valueRequired: 'Value is required for this type'};
                } else if (control.value.typeValue === ConditionTypeValue.PROPERTY && !control.value.valueProperty) {
                    return {valueRequired: 'Value is required for this type'};
                }
            }

            if (control.value.type === ConditionType.REGEX && control.value.type?.valueRequired) {
                try {
                    new RegExp(control.value.value);
                } catch (error) {
                    return {invalidRegex: error.message};
                }
            }
            return null;
        }, Validators.required]);

        parent.addRule(conditionGroup);
    }

    public addValueGroup(value?: ValueModel): void {
        const valueGroup = new FormGroup<ValueForm>({
            tag: new FormControl(null, Validators.required),
            property: new FormControl(null, Validators.required),
            format: new FormControl(null),
            formatString: new FormControl(null)
        }, [(control: AbstractControl) => {
            const formatValue = control.value.format?.getValue();
            switch (formatValue) {
                case this.DATE_FORMAT: {
                    // Format string required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Format string is required for the date format'};
                    }

                    // Valid format string
                    try {
                        const options = {
                            timeZone: AppConstants.DEFAULT_TIMEZONE,
                            locale: DEFAULT_LOCALE,
                            useAdditionalWeekYearTokens: true,
                            useAdditionalDayOfYearTokens: true
                        };
                        dateFormatter(new Date(), control.value.formatString, options);
                    } catch (error) {
                        ARLogger.error(error.message);
                        return {invalidFormatString: 'Format string is not valid'};
                    }
                    break;
                }
                case this.TO_STRING: {
                    // Separator required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Separator is required for the to string format'};
                    }
                    break;
                }
            }

            if (control.value.property && control.value.property[control.value.property.length - 2]?.isArray &&
                !control.value.property[control.value.property.length - 1] && formatValue !== this.TO_STRING) {
                return {valueRequired: 'For array properties without selected index the \'To string\' formatter is required'};
            }

            return null;
        }, Validators.required]);

        this.valueGroups.push(valueGroup);

        if (value) {
            const propertyValue = PropertyControlComponent.getPropertyValueModel(value?.property, this.ruleProperties, []);
            valueGroup.patchValue({
                tag: this.tags.find((tag) => tag.getValue() === value.tag),
                property: propertyValue,
                format: FormatOption.getByValue(value.format),
                formatString: value.formatString
            }, {emitEvent: false});
        }
    }

    public addNewConditionGroup(): void {
        const newConditionGroup = new ConditionGroup();
        this.addConditionGroup(null, newConditionGroup);
        this.conditionGroups.addGroup(newConditionGroup);
    }

    public deleteValueClicked(index: number) {
        this.valueGroups.removeAt(index);
    }

    /**
     * @override
     * @protected
     */
    protected abstract saveRule(): void;
}
