import {autoserialize, autoserializeAs} from 'cerialize';

export enum EConnectionType {
    MEDIA_VALET = 'MEDIA_VALET',
    WEBHOOK_CONSUMER = 'WEBHOOK_CONSUMER',
    SUPERUNIE_ADAM = 'SUPERUNIE_ADAM'
}

export enum EWebhookEvent {
    CAMPAIGN_CREATED = 'CAMPAIGN_CREATED'
}

export class WebhookEvent {
    @autoserialize public Name: string;
}

export class WebhookMetaData {
    @autoserialize public dataFieldName: string;
    @autoserialize public name: string;
}

export class WebhookModel {
    @autoserialize public _id: string;
    @autoserializeAs(WebhookEvent) public Events: WebhookEvent[];
    @autoserialize public categories: string[];
    @autoserialize public SubscriptionId: string;
    @autoserialize public Status: string;
    @autoserializeAs(Object) public Data: { webhook: string };
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];
}

export class WebhookPostBody {
    @autoserialize public event: string;
    @autoserialize public categories: string[];
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(event: string, categories: string[], metaData?: WebhookMetaData[]) {
        this.event = event;
        this.categories = categories;
        this.metaData = metaData;
    }
}

export class WebhookPatchBody {
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(metaData: WebhookMetaData[]) {
        this.metaData = metaData;
    }
}

export class ConsumerSettingsModel {
    @autoserialize public secret: string;
    @autoserialize public authentication: string;
}

export class SynchronisationSettingsModel {
    @autoserialize public enableImageSync: boolean;
    @autoserialize public imageRegexFilter: string;
    @autoserialize public globalLocationNumbers: string[];
    @autoserialize public productDataField: string;
    @autoserialize public removeLeadingZeros: boolean;
}

export class ConnectionEventModel {
    @autoserialize public _id: string;
    @autoserialize public event: string;
    @autoserialize public endpoint: string;
}

export class ConnectionEventBody {
    @autoserialize public event: string;
    @autoserialize public endpoint: string;

    constructor(event: string, endpoint: string) {
        this.event = event;
        this.endpoint = endpoint;
    }
}

export class ConnectionEventPatchBody {
    @autoserialize public event: string;
    @autoserialize public endpoint: string;

    constructor(event?: string, endpoint?: string) {
        if (event) this.event = event;
        if (endpoint) this.endpoint = endpoint;
    }
}

export class ConnectionModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public authUrl: string;
    @autoserialize public apiKey: string;
    @autoserialize public hasRefreshToken: boolean;
    @autoserialize public subscriptionKey: string;
    @autoserializeAs(WebhookModel) public webhooks: WebhookModel[];
    @autoserializeAs(ConnectionEventModel) public events: ConnectionEventModel[];
    @autoserializeAs(ConsumerSettingsModel) public consumerSettings: ConsumerSettingsModel;
    @autoserializeAs(SynchronisationSettingsModel) public synchronisationSettings: SynchronisationSettingsModel;

    public getLogo(): string {
        return ConnectionModel.getLogo(this.connectionType);
    }

    public static getLogo(connectionType: EConnectionType): string {
        switch (connectionType) {
            case EConnectionType.MEDIA_VALET:
                return '/assets/images/logos/MV_Logo.png';
            case EConnectionType.WEBHOOK_CONSUMER:
                return '/assets/images/logos/logo_webhook_consumer.svg';
            case EConnectionType.SUPERUNIE_ADAM:
                return '/assets/images/logos/logo_superunie_adam.jpeg';
            default:
                return '';
        }
    }
}

export class ConnectionApiModel {
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public subscriptionKey: string;
    @autoserialize public apiKey: string;
    @autoserializeAs(SynchronisationSettingsModel) public synchronisationSettings: SynchronisationSettingsModel;

    constructor(name: string, type: EConnectionType, clientId?: string, clientSecret?: string,
                subscriptionKey?: string, apiKey?: string, imageRegexFilter?: string, enableImageSync?: boolean,
                globalLocationNumbers?: string[], productDataField?: string, removeLeadingZeros?: boolean) {
        this.name = name;
        this.connectionType = type;
        if (clientId) this.clientId = clientId;
        if (clientSecret) this.clientSecret = clientSecret;
        if (subscriptionKey) this.subscriptionKey = subscriptionKey;
        if (apiKey) this.apiKey = apiKey;
        if (enableImageSync !== undefined) this.synchronisationSettings = {
            imageRegexFilter,
            enableImageSync,
            globalLocationNumbers,
            productDataField,
            removeLeadingZeros
        };
    }
}

export class ConnectionPatchModel {
    @autoserialize public apiKey: string;
    @autoserialize public hasRefreshToken: boolean;

    constructor(apiKey?: string) {
        this.apiKey = apiKey;
    }
}
