import {JoinPipe} from './join.pipe';
import {IsPlacedPipe} from './is-placed.pipe';
import {NullUndefinedPipe} from './null-undefined.pipe';
import {InitialsPipe} from '../components/user-icon/initials.pipe';
import {UserIsAllowedToPipe} from './user-is-allowed-to.pipe';
import {RLDatePipe} from './rl-date.pipe';
import {DataFieldFilterPipe} from '../pages/relayter/data-management/form-builder/data-field-filter.pipe';
import {DataFieldFormIconPipe} from '../pages/relayter/data-management/form-builder/data-field-form-icon.pipe';
import {PublicationItemDisplayPipe} from './publication-item-display.pipe';
import {FileTypeIconPipe} from './file-type-icon.pipe';
import {IsPublicationTypePipe} from './is-publication-type.pipe';
import {ChannelDisplayPipe} from './channel-display.pipe';
import {ContentTypeDisplayPipe} from './content-type-display.pipe';
import {AssetDisplayPipe} from './asset-display.pipe';
import {NumberOfPagesPipe} from '../components/paginator/paginator.component';
import {GetFilePathPipe} from './get-file-path';
import {GetDataFieldValuePipe} from './data-fields/get-data-field-value.pipe';
import {GetNotePinImagePipe} from './get-note-pin-image.pipe';
import {ConditionalValuePipe} from './conditional-value.pipe';
import {DataChangesPipe} from './data-changes.pipe';
import {WorkflowUrlPipe} from './workflow-url.pipe';
import {NgModule} from '@angular/core';
import {GetDataFieldDisplayValuePipe} from './data-fields/get-data-field-display-value.pipe';
import {BooleanDisplayPipe} from './boolean-display.pipe';
import {PropertySettingDisplayPipe} from './property-setting-display.pipe';
import {
    GetDownloadIcon, GetFileNamePipe
} from '../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-preview/publication-item-details/publication-item-details.component';
import {PastDatePipe} from './past-date.pipe';
import {DurationWordsPipe} from './duration-words.pipe';
import { DisplayFormErrorPipe } from './display-form-error.pipe';
import {AnyValueDisplayPipe} from './any-value-display.pipe';
import {LinkifyPipe} from './linkify.pipe';
import {AssetFileTypePipe} from './asset-file-type.pipe';
import {PublicationItemMediaPipe} from './publication-item-media.pipe';
import {CollectionViewMediaPipe} from './collection-view-media.pipe';

const list = [
    JoinPipe,
    IsPlacedPipe,
    NullUndefinedPipe,
    InitialsPipe,
    UserIsAllowedToPipe,
    RLDatePipe,
    DataFieldFilterPipe,
    DataFieldFormIconPipe,
    PublicationItemDisplayPipe,
    FileTypeIconPipe,
    IsPublicationTypePipe,
    ChannelDisplayPipe,
    ContentTypeDisplayPipe,
    AssetDisplayPipe,
    NumberOfPagesPipe,
    GetFilePathPipe ,
    GetDataFieldValuePipe,
    GetNotePinImagePipe,
    ConditionalValuePipe,
    DataChangesPipe,
    WorkflowUrlPipe,
    GetDataFieldDisplayValuePipe,
    BooleanDisplayPipe,
    PropertySettingDisplayPipe,
    PastDatePipe,
    DurationWordsPipe,
    DisplayFormErrorPipe,
    AnyValueDisplayPipe,
    LinkifyPipe,
    AssetFileTypePipe,
    PublicationItemMediaPipe,
    CollectionViewMediaPipe
];

@NgModule({
    imports: [],
    declarations: [list, GetDownloadIcon, GetFileNamePipe],
    exports: [list, GetDownloadIcon, GetFileNamePipe],
    providers: []
})

export class PipesModule {
}
