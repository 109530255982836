<nuc-tab-bar [tabs]="tabBarItems"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<form [formGroup]="form" *ngIf="variants; else loadingIndicator">
    <ng-container [ngSwitch]="selectedTab.index">
        <data-fields-information-form
            *ngSwitchCase="TAB_PRODUCT"
            [form]="form"
            [dataFields]="dataFields"
            [formConfig]="formConfig"
            [variants]="variants">
        </data-fields-information-form>
        <product-assets-form *ngSwitchCase="TAB_ASSETS"
                             [form]="form"
                             [assets]="selectedAssets">
        </product-assets-form>
    </ng-container>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
