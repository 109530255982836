import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {ARApiError} from '@relayter/core';
import {AppConstants} from '../../../../app.constants';
import {AssetService} from '../../../../api/services/asset.service';
import {AssetProductModel} from '../../../../models/api/asset-product.model';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    DialogCustomContentConfig,
    EColumnSize,
    FullModalConfig,
    FullModalService,
    ITableColumn,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {filter, finalize, take} from 'rxjs/operators';
import {AssetModel, EAssetType} from '../../../../models/api/asset.model';
import {
    ExportImageModalViewComponent,
    IExportImageModalData
} from '../../../../components/export-asset-modal-view/export-image-modal-view.component';
import {EAssetDisplayProperties} from '../../../../pipes/asset-display.pipe';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {AssetEditFormComponent, IAssetEditFormData} from '../../../../forms/asset-edit-form/asset-edit-form.component';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {forkJoin, Subscription} from 'rxjs';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {CampaignModel} from '../../../../models/api/campaign.model';
import {
    ESegmentTrackEvents,
    ISegmentService,
    SEGMENT_SERVICE
} from '../../../../services/segment/segment.service.interface';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {FileTypeUtil} from '../../../../classes/file-type.util';
import {animate, style, transition, trigger} from '@angular/animations';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-asset-detail-page-component',
    templateUrl: 'asset-detail.component.html',
    styleUrls: ['asset-detail.component.scss'],
    providers: [PaginatorService],
    animations: [
        trigger('rlLoadTrigger', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.3s ease', style({opacity: '1'})),
            ]),
        ])
    ],
})

export class AssetDetailComponent extends RLTableComponent implements OnInit, OnChanges {
    public readonly tableId = 'asset-detail-table';
    @Input() public assetId: string;
    @Output() public onAssetDeleted: EventEmitter<void> = new EventEmitter();
    @Output() public onAssetEdited: EventEmitter<void> = new EventEmitter();
    public readonly IMAGE = FileTypeUtil.CATEGORIES.IMAGE;

    public assetDetails: AssetProductModel = new AssetProductModel();

    public EAssetDisplayProperties = EAssetDisplayProperties;
    public dateFormats = RLDatePipe.dateFormats;
    public dataSubscription: Subscription;
    public campaignsUsingAssetSubscription: Subscription;
    protected readonly EAssetType = EAssetType;

    public dataFields: DataFieldModel[];

    // delete asset related properties
    @ViewChild('assetUsageDialog', {static: true}) protected assetUsageDialog: TemplateRef<any>;
    public assetUsagePageIndex = 1;
    public assetUsagePageSize = AppConstants.PAGE_SIZE_DIALOG;
    public disableNextPage: boolean;

    public assetUsagePageSizeOptions = [new DropdownItem(`${this.assetUsagePageSize}`, this.assetUsagePageSize)];
    public campaignsUsingAsset: CampaignModel[] = [];
    public totalCampaignsUsingAsset: number;
    public dialogTableColumns: ITableColumn[] = [
        {
            title: 'Affected campaign(s)',
            key: 'name',
            size: EColumnSize.LARGE
        } as ITableColumn
    ];
    private deleteAction: DialogCustomContentActionModel;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private assetService: AssetService,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsApiService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    /**
     * Called on init, gets the data
     */
    public ngOnInit(): void {
        this.assetId = this.route.snapshot.params['id'] ? this.route.snapshot.params['id'] : this.assetId;

        this.onAssetDeleted
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.router.navigate([AppConstants.ASSETS_PATH]));

        this.onAssetEdited
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getAssetDetails());

        // There is only one page size for asset usage dialog
        // this.paginatorService.storePageSize(this.tableId, this.assetUsagePageSize);
        // Also, to initiate the paginator service for this tableId
        this.paginatorService.getStoredPageSize(this.tableId)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((pageSize: number) => {
                this.assetUsagePageSize = pageSize;
            });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.assetUsagePageIndex = pagination.pageIndex;
                this.assetUsagePageSize = pagination.pageSize;

                this.getCampaignsUsingAsset();
            });

        this.getData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.assetId) {
            this.getData();
        }
    }

    private getData(): void {
        const asset$ = this.assetService.getAsset(this.assetId);
        const dataFields$ = this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET);

        this.dataSubscription = forkJoin([asset$, dataFields$])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([asset, dataFields]) => [this.assetDetails, this.dataFields] = [asset, dataFields],
                error: (err) => {
                    Toaster.handleApiError(err);
                    this.router.navigate([AppConstants.ASSETS_PATH]);
                }
            });
    }

    /**
     * Gets asset details with asset id
     */
    private getAssetDetails(): void {
        this.dataSubscription = this.assetService.getAsset(this.assetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (assetDetails: AssetProductModel) => this.assetDetails = assetDetails,
                error: (err: ARApiError) => {
                    Toaster.handleApiError(err);
                    this.router.navigate([AppConstants.ASSETS_PATH]);
                }
            });
    }

    public onEditAssetClicked(): void {
        this.openEditAssetModal();
    }

    public openEditAssetModal(): void {
        const modalData: IAssetEditFormData = {assetId: this.assetId};
        const modalConfig = new FullModalConfig('Edit Asset', 'Edit the details or replace the current asset by uploading a new one.', modalData);
        modalConfig.confirmClose = true;

        const modal = this.fullModalService.open(AssetEditFormComponent, modalConfig);
        modal.afterClosed()
            .pipe(filter((refresh: boolean) => refresh))
            .subscribe(() => this.onAssetEdited.next());
    }

    public onExportAssetClicked(image: AssetModel): void {
        const modalData: IExportImageModalData = {image};
        const modalConfig = new FullModalConfig('Download image', 'Select the type and size of the image you wish to download.', modalData);
        this.fullModalService.open(ExportImageModalViewComponent, modalConfig);
    }

    public onDownloadOriginalAssetClicked(assetSourceUrl: string): void {
        window.open(assetSourceUrl);
    }

    /**
     * Responder to trash button click. Looks up asset usage in campaigns and opens the correct dialog accordingly
     */
    public handleAssetDeletion(): void {
        this.totalCampaignsUsingAsset = 0;
        this.campaignsUsingAsset = [];

        this.openDeleteAssetDialog();
        this.deleteAction.button.loading = true;

        this.setPageIndex();
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private getCampaignsUsingAsset(): void {
        this.campaignsUsingAssetSubscription = this.assetService
            .findCampaignsUsingAsset(this.assetId, this.assetUsagePageSize, (this.assetUsagePageIndex - 1) * this.assetUsagePageSize)
            .pipe(
                finalize(() => this.deleteAction.button.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: res => {
                    ({items: this.campaignsUsingAsset, total: this.totalCampaignsUsingAsset} = res);
                    this.disableNextPage = this.totalCampaignsUsingAsset <= this.assetUsagePageSize * this.assetUsagePageIndex;
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * Called by deleteAssetDialog. Deletes the currently selected asset
     */
    public deleteAsset(): void {
        this.assetService.deleteAsset(this.assetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Asset deleted successfully');
                    this.segmentService.track(ESegmentTrackEvents.TRACK_REMOVE_ASSET);
                    this.onAssetDeleted.next();
                },
                error: Toaster.handleApiError
            });
    }


    /**
     * On delete button clicked, open delete asset dialog
     */
    private openDeleteAssetDialog(): void {
        const description = 'Please confirm that you wish to delete this asset. Products and campaigns won\'t have access to the asset anymore.';

        const deleteDialogConfig = new DialogCustomContentConfig('Delete asset', description);
        const cancelAction = new DialogCustomContentActionModel({text: 'Cancel', buttonType: BUTTON_TYPE.SECONDARY} as DialogButtonConfig);
        this.deleteAction = new DialogCustomContentActionModel({text: 'Delete', buttonType: BUTTON_TYPE.DESTRUCTIVE} as DialogButtonConfig);

        cancelAction.observable.subscribe(() => this.dialogCustomContentService.close());
        this.deleteAction.observable.subscribe(() => {
            this.dialogCustomContentService.close();
            this.deleteAsset();
        });

        deleteDialogConfig.addAction(cancelAction);
        deleteDialogConfig.addAction(this.deleteAction);

        this.dialogCustomContentService.open(this.assetUsageDialog, deleteDialogConfig);
    }

    public getAssetFileCategory(fileName): string {
        return FileTypeUtil.getFileCategory(fileName);
    }

}
