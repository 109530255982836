import escapeStringRegexp from 'escape-string-regexp';

/**
 *  string-util
 *  app-relator-web
 *  Created by borisvisser on 16/05/2018.
 *  Copyright © 2018 Creative Media Network. All rights reserved.
 */

export class StringUtil {

    /**
     * Transform a string to have the first letter uppercase and the rest lowercase
     * @param {string} text
     * @returns {string}
     */
    public static makeFirstCharUppercase(text: string): string {
        return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
    }

    /**
     * Escape regex characters from string
     * @param {string} stringToEscape
     * @returns {string} escaped string
     */
    public static escapeRegExp (stringToEscape: string): string {
        return escapeStringRegexp(stringToEscape);
    };
}
