<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <p class="title">
        #{{ valueIndex + 1 }}
        <span *ngFor="let description of valueDescription" [ngClass]="{bold: description.bold}">
            {{ description.text }}
        </span>
    </p>
    <nuc-button-secondary class="action" icon="nucicon_trash_fill"
                          (click)="deleteClicked.emit()"></nuc-button-secondary>

    <nuc-form-field class="tag" label="Tag">
        <nuc-dropdown [items]="tags" placeholder="Select a tag" formControlName="tag"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field class="property" label="Property">
        <property-control class="property"
            formControlName="property"
            [arrayIndexRequired]="false"
            [ruleProperties]="ruleProperties"
            (lastOperatorRemoved)="lastOperatorRemoved()" />
    </nuc-form-field>

    <div>
        <nuc-form-field class="format" label="Format">
            <nuc-dropdown [items]="formatOptions" [total]="formatOptions.length" formControlName="format"
                          placeholder="Choose optional format"></nuc-dropdown>
        </nuc-form-field>

        <ng-container class="format-string" [ngSwitch]="formatFormControl.value">
            <div class="format-string" *ngSwitchCase="FORMAT_OPTION.DATE_FORMAT">
                <span><i class="nucicon_connector"></i></span>
                <nuc-form-field class="date-format">
                    <nuc-input formControlName="formatString"
                               placeholder="Date format string"></nuc-input>
                </nuc-form-field>
                <nuc-button-secondary icon="nucicon_open" (click)="openDateFormatLink()"
                                      nucTooltip="Go to date format article"></nuc-button-secondary>
            </div>
            <div class="format-string" *ngSwitchCase="FORMAT_OPTION.TO_STRING">
                <span><i class="nucicon_connector"></i></span>
                <nuc-form-field class="separator-format">
                    <nuc-input formControlName="formatString"
                               placeholder="Separator string"></nuc-input>
                </nuc-form-field>
            </div>
        </ng-container>
    </div>
</form>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
