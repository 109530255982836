import {DestroyRef, Directive, inject} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel} from '@relayter/rubber-duck';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {CampaignItemBodyModel, CampaignItemModel} from '../../models/api/campaign-item.model';
import {finalize} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {CampaignItemsService} from '../../api/services/campaign-items.service';
import {AppConstants} from '../../app.constants';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Directive()
export abstract class CampaignItemFormComponentDirective {
    protected destroyRef = inject(DestroyRef);
    public readonly = false;

    public TAB_INFORMATION = 0;
    public TAB_PRODUCTS = 1;
    public TAB_ASSETS = 2;
    public TAB_CHANGES = 3;

    public tabBarItems: TabBarItemModel[];
    private _selectedTab: TabBarItemModel;
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }

    protected confirmAction: FullModalActionModel;
    protected confirmButton: ButtonConfig;
    protected secondaryAction: FullModalActionModel;
    protected actions: FullModalActionModel[] = [];

    protected campaignId: string;
    protected campaignItem: CampaignItemModel;

    public form: UntypedFormGroup = new FormGroup({});

    protected constructor(private campaignItemService: CampaignItemsService,
                          private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    protected setupTabBar(): void {
        this.tabBarItems = [
            new TabBarItemModel('Information', this.TAB_INFORMATION),
            new TabBarItemModel('Products', this.TAB_PRODUCTS),
            new TabBarItemModel('Assets', this.TAB_ASSETS)
        ];

        if (this.campaignItem?._id) {
            this.tabBarItems.push(new TabBarItemModel('Item changes', this.TAB_CHANGES));
        }

        this._selectedTab = this.tabBarItems[this.TAB_INFORMATION];
    }

    protected setupForm(): void {
        this.setupTabBar();

        this.readonly = this.campaignItem && !this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_CAMPAIGN_ITEM);

        // Go back to the first tab to load dataFields into the form
        if (!this.readonly) {
            this.selectedTab = this.tabBarItems[this.TAB_INFORMATION];
        }

        this.initButtons();
        this.initFormData();
    }

    protected initButtons(): void {
        const secondaryButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, this.readonly ? 'Close' : 'Cancel');
        this.secondaryAction = new FullModalActionModel(secondaryButton);

        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        this.confirmAction = new FullModalActionModel(this.confirmButton);
        this.confirmAction.observable.subscribe(() => this.onSaveButtonClicked());

        this.secondaryAction.observable.subscribe(() => this.beforeCloseForm(null, !this.readonly));

        if (this.readonly) {
            this.actions = [this.secondaryAction];
        } else {
            this.actions = [this.secondaryAction, this.confirmAction];
        }
    }


    private onSaveButtonClicked(): void {
        if (this.form?.valid) {
            this.saveCampaignItem();
        } else {
            Toaster.warn('Form contains errors. Please update incorrect and/or missing values');
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty());
        }
    }

    private saveCampaignItem(): void {
        this.confirmButton.loading = true;

        const products = this.form.value.products?.map(product => product._id);
        const assets = this.form.value.assets?.map((asset) => asset._id);
        const dataFields = DataFieldsComponentUtil.getBodyForDataFields(this.form.value.dataFields, !!this.campaignItem);

        const campaignItem = new CampaignItemBodyModel(products, assets, dataFields);

        const observable = this.campaignItem ?
            this.campaignItemService.updateCampaignItem(this.campaignId, this.campaignItem._id, campaignItem) :
            this.campaignItemService.postCampaignItem(this.campaignId, campaignItem);

        observable
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    Toaster.success(this.campaignItem ?
                        'Briefing item updated successfully' :
                        'Briefing item added successfully');

                    this.closeForm(result);
                },
                error: Toaster.handleApiError
            });
    }

    protected abstract initFormData(): void;
    public abstract beforeCloseForm(result?: CampaignItemModel, confirmClose?: boolean);
    protected abstract closeForm(result?: CampaignItemModel, confirmClose?: boolean);
}
