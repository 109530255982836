import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {FieldPropertyType, RulePropertyModel} from '../../models/api/rule-property.model';
import {BUTTON_TYPE} from '@relayter/rubber-duck';
import {VariantModel} from '../../models/api/variant.model';
import {FormatRulesetConstants} from '../../modules/format-rulesets/format-ruleset.constants';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {StringUtil} from '../../classes/string-util';

enum EPropertyType {
    ARRAY,
    FIELD,
    VARIANT
}

enum EConnectionType {
    CONNECT,
    END
}

export enum EControlType {
    VALUE,
    CONDITION
}

interface IPropertyInput {
    type: EPropertyType;
    properties: RulePropertyModel[];
    options: RulePropertyModel[];
    value?: FieldPropertyType;
    variantOptions?: VariantModel[];
}

@Component({
    selector: 'property-control',
    templateUrl: './property-control.component.html',
    styleUrls: ['./property-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PropertyControlComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: PropertyControlComponent
        }
    ]
})
export class PropertyControlComponent implements ControlValueAccessor, Validator {
    private _arrayIndexRequired = true;
    private _variantRequired = false;
    private _ruleProperties: RulePropertyModel[] = [];

    protected readonly EControlType = EControlType;

    @Input() public get ruleProperties() {
        return this._ruleProperties;
    }
    public set ruleProperties(properties: RulePropertyModel[]) {
        this._ruleProperties = properties;
        this.setupValue();
    }

    @Input() public ignoreIndex = false;
    @Input() public get arrayIndexRequired() {
        return this._arrayIndexRequired;
    }
    public set arrayIndexRequired(required: boolean) {
        if (this._arrayIndexRequired !== required) {
            this._arrayIndexRequired = required;
            this.onValidatorChange();
        }
    }
    @Input() public get variantRequired() {
        return this._variantRequired;
    }
    public set variantRequired(required: boolean) {
        if (this._variantRequired !== required) {
            this._variantRequired = required;
            this.onValidatorChange();
        }
    }
    @Input() public controlType: EControlType = EControlType.VALUE;
    @Input() public variants: VariantModel[] = [];
    @Output() public lastOperatorRemoved = new EventEmitter<void>();

    protected readonly BUTTON_TYPE = BUTTON_TYPE;
    protected readonly PROPERTY_TYPE = EPropertyType;
    protected readonly EConnectionType = EConnectionType;

    protected readonly minimalBadgeWidth = 150;

    protected propertyInputs: IPropertyInput[] = [];
    protected propertyValue: PropertyValueModel;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    private onChange = (_propertyValue: PropertyValueModel) => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onValidatorChange = () => {};

    private touched = false;
    protected disabled = false;

    protected isOpenIndex: boolean[] = [];
    protected operators = ['Item at'];

    private setupValue(): void {
        const properties = this.propertyValue?.properties || [];

        this.propertyInputs = [];

        // If no properties yet, add first one
        if (properties.length === 0) {
            this.addPropertySelector(EPropertyType.FIELD, this.ruleProperties);
        } else {
            properties.forEach((property, index) => {
                if (index === 0) {
                    const propertyValue = property as RulePropertyModel;
                    this.addPropertySelector(EPropertyType.FIELD, this.ruleProperties, propertyValue)
                } else if (property instanceof RulePropertyModel) {
                    const propertyValue = property as RulePropertyModel;
                    const selectedProperties = Array.from(properties).reverse().find((property, pIndex) =>
                        properties.length - pIndex - 1 < index && property instanceof RulePropertyModel) as RulePropertyModel;
                    this.addPropertySelector(EPropertyType.FIELD, selectedProperties?.properties || [], propertyValue)
                } else if (property instanceof VariantModel) {
                    this.addPropertySelector(EPropertyType.VARIANT, [], property);
                } else {
                    // Could be an optional field/variant/array index selection
                    const previousProperty = properties[index - 1];
                    if (previousProperty instanceof RulePropertyModel) {
                        if (previousProperty.enableVariants) {
                            this.addPropertySelector(EPropertyType.VARIANT, [], property);
                        } else if (this.ignoreIndex || !previousProperty.isArray) {
                            this.addPropertySelector(EPropertyType.FIELD, (properties[index - 1] as RulePropertyModel)?.properties || [], property);
                        } else {
                            this.addPropertySelector(EPropertyType.ARRAY, [], property);
                        }
                    } else {
                        this.addPropertySelector(EPropertyType.ARRAY, [], property);
                    }
                }
            });
        }
    }

    // Implement ControlValueAccessor interface
    public writeValue(propertyValue: PropertyValueModel): void {
        this.propertyValue = propertyValue;
        this.setupValue();
    }

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    // Implement Validator interface
    public registerOnValidatorChange(onValidatorChange: any): void {
        this.onValidatorChange = onValidatorChange;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const value: PropertyValueModel = control.value;

        if (!value) return;

        const numEntries = value.properties.length;
        if (numEntries === 0) {
            return {
                propertyControlError: {
                    message: 'Select a property'
                }
            };
        }

        for (const [index, property] of value.properties.entries()) {
            if (!property) {
                const propertyType = this.propertyInputs[index];
                switch (propertyType.type) {
                    case EPropertyType.ARRAY:
                        // Not the last array entry or last array entry required
                        if (index !== numEntries - 1 || (index === numEntries - 1 && this.arrayIndexRequired)) {
                            return {
                                propertyControlError: {
                                    message: 'Fill in the array operator index'
                                }
                            };
                        }
                        break;
                    case EPropertyType.FIELD:
                        return {
                            propertyControlError: {
                                message: 'Select a property'
                            }
                        };
                    case EPropertyType.VARIANT:
                        if (this.variantRequired) {
                            return {
                                propertyControlError: {
                                    message: 'Select a variant'
                                }
                            };
                        }
                        break;
                }
            }
        }
    }

    private addPropertySelector(type: EPropertyType, properties: RulePropertyModel[], value?: FieldPropertyType): void {
        const sortProperties = RulePropertyModel.filterPropertiesOnDataType(null, properties);
        this.propertyInputs.push({type, properties, options: sortProperties, ...(value && { value }),
            ...(type === EPropertyType.VARIANT && { variantOptions: Array.from(this.variants) })});
    }

    protected onBadgeChange(index: number, item: FieldPropertyType): void {
        if (this.propertyInputs[index]?.value === item) return;

        // Remove all above this index
        this.propertyInputs.splice(index + 1);

        this.propertyInputs[index].value = item;

        if (item instanceof RulePropertyModel) {
            if (!this.ignoreIndex && item?.isArray) {
                this.addPropertySelector(EPropertyType.ARRAY, []);
            } else if (item?.properties?.length > 0) {
                this.addPropertySelector(EPropertyType.FIELD, item.properties);
            }
            if (item?.enableVariants && this.variants.length) {
                this.addPropertySelector(EPropertyType.VARIANT, []);
            }

        }

        this.onChange(this.getFormValue());
        this.markAsTouched();
    }

    protected getBadgeOptions(index: number, event: IDropdownRequestDataEvent) {
        const propertyInput = this.propertyInputs[index];

        const options = RulePropertyModel.filterPropertiesOnDataType(null, propertyInput.properties);

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            propertyInput.options = options.filter((ruleProperty) =>
                ruleProperty.name.match(regex)?.length > 0)
        } else {
            propertyInput.options = options;
        }

    }

    protected onArrayIndexChange(index: number, value: number): void {
        if (this.propertyInputs[index]?.value === value) return;

        const property = this.propertyInputs[index];

        if (property) {
            property.value = value;
        }

        const previousControl = this.propertyInputs[index - 1];
        if (previousControl?.value instanceof RulePropertyModel && previousControl.value.properties?.length) {
            if (!this.propertyInputs[index + 1]) {
                this.addPropertySelector(EPropertyType.FIELD, previousControl.value.properties);
            }
        }

        this.onChange(this.getFormValue());
        this.markAsTouched();
    }

    protected onVariantChanged(index: number, variant: VariantModel): void {
        if (this.propertyInputs[index]?.value === variant) return;

        const property = this.propertyInputs[index];

        if (property) {
            property.value = variant;
        }

        this.onChange(this.getFormValue());
        this.markAsTouched();
    }

    protected getVariantOptions(index: number, event: IDropdownRequestDataEvent) {
        const propertyInput = this.propertyInputs[index];

        const options = Array.from(this.variants);

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            propertyInput.variantOptions = options.filter((ruleProperty) =>
                ruleProperty.name.match(regex)?.length > 0)
        } else {
            propertyInput.variantOptions = options
        }

    }

    protected removeOperator(index: number): void {
        this.propertyInputs[index].value = null;

        // Reset all values above this operator
        for (const [iIndex, propertyInput] of this.propertyInputs.entries()) {
            if (iIndex > index) propertyInput.value = null;
            if (iIndex - index > 0) this.propertyInputs[iIndex] = null;
        }

        this.propertyInputs = this.propertyInputs.filter(property => !!property);

        // If this is the last one, emit lastOperatorRemoved
        if (this.propertyInputs.length === index + 1) {
            this.lastOperatorRemoved.emit();
        }
        this.isOpenIndex[index] = false;

        this.onChange(this.getFormValue());
        this.markAsTouched();
    }

    protected showOperatorOverlay(overlayIndex: number, open: boolean): void {
        this.isOpenIndex = Array.from({length: Math.max(overlayIndex + 1, this.isOpenIndex.length)}, (_value, index) => {
            if (index === overlayIndex) {
                return !open;
            }
            return false;
        });
    }

    protected getConnectionType(index: number): EConnectionType {
        return this.propertyInputs[index + 1] ? EConnectionType.CONNECT : EConnectionType.END;
    }

    private getFormValue(): PropertyValueModel {
        if (!this.propertyInputs?.length) {
            this.propertyValue = null
        } else {
            this.propertyValue = PropertyControlComponent.createPropertyValueModel(this.propertyInputs.map(input => input.value));
        }

        return this.propertyValue;
    }

    private static createPropertyValueModel(properties: FieldPropertyType[]): PropertyValueModel {
        if (!properties[0]) return null;

        const path = properties.reduce((acc, property) => {
            if (property instanceof RulePropertyModel) {
                acc.push((property as RulePropertyModel).key);
            } else if (property instanceof VariantModel) {
                acc.push((property as VariantModel).key);
            } else if (typeof property === 'number') {
                acc.push((property - FormatRulesetConstants.MIN_VALUE_ARRAY_INDEX).toString());
            }

            return acc;
        }, []).join('.');

        const property = (properties.filter(property =>
            property instanceof RulePropertyModel) as RulePropertyModel[]).pop();

        return new PropertyValueModel(path, property, properties);
    }

    private static getPropertyPatchValue(value: string, propertyOptions: RulePropertyModel[],
                                         variants: VariantModel[] = [], ignoreIndex = false): FieldPropertyType[] {
        if (!value) return [];

        const values: FieldPropertyType[] = [];
        const properties = value.split('.');
        let searchProperties = propertyOptions;
        let searchValue = '';

        // Old fashion, because it manipulates the index
        for (let index = 0; index < properties.length; index++) {
            const property = properties[index];
            searchValue = searchValue ? `${searchValue}.${property}` : property;
            if (isNaN(+property)) {
                const rulesetProperty = searchProperties.find((valueProperty) => valueProperty.getValue() === searchValue);
                if (rulesetProperty) {
                    searchProperties = rulesetProperty.properties || [];
                    searchValue = '';
                    values.push(rulesetProperty);
                    if (!ignoreIndex && rulesetProperty.isArray) {
                        values.push(null);
                    }
                    if (rulesetProperty.enableVariants) {
                        if (!isNaN(+properties[index+1])) { // Index number of a list or multi select enum
                            continue;
                        }

                        if (properties[index+1]) {
                            // Should be a variant key
                            const variant = variants.find(variant => variant.key === properties[index+1]);
                            if (variant) {
                                values.push(variant);
                            }
                            index++;
                        } else if (variants.length) {
                            values.push(null);
                        }
                    }
                }
            } else {
                searchValue = '';
                values.splice(values.length - 1, 1, +(property) + FormatRulesetConstants.MIN_VALUE_ARRAY_INDEX);
            }
        }

        return values;
    }

    public static getPropertyValueModel(value: string, ruleProperties: RulePropertyModel[],
                                        variants: VariantModel[] = [], ignoreIndex = false): PropertyValueModel {
        const properties = PropertyControlComponent.getPropertyPatchValue(value, ruleProperties, variants, ignoreIndex);

        return PropertyControlComponent.createPropertyValueModel(properties);
    }
}
