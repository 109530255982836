import {Component, OnInit} from '@angular/core';
import {ConnectionEventModel, ConnectionModel, EConnectionType} from '../../../../../../models/api/connection.model';
import {ActivatedRoute} from '@angular/router';
import {ConnectionService} from '../../../../../../api/services/connection.service';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../../classes/toaster.class';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {DataFieldsService} from '../../../../../../api/services/data-fields.service';
import {DataFieldModel} from '../../../../../../models/api/data-field.model';


@Component({
    selector: 'connection-detail-superunie-adam',
    templateUrl: './connection-detail-superunie-adam.component.html',
    styleUrls: ['./connection-detail-superunie-adam.component.scss']
})
export class ConnectionDetailSuperunieAdamComponent extends RLBaseComponent implements OnInit {
    private connectionId: string;
    public connection: ConnectionModel;
    public productDataField: DataFieldModel;
    public events: ConnectionEventModel[];
    public loading: boolean;
    public logo = ConnectionModel.getLogo(EConnectionType.SUPERUNIE_ADAM);

    constructor(private activeRoute: ActivatedRoute,
                private connectionService: ConnectionService,
                private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsService) {
        super();
    }

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
        });
    }

    private getConnection(): void {
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (connection: ConnectionModel) => {
                    this.connection = connection;

                    const productDataField = this.connection.synchronisationSettings?.productDataField;
                    if (productDataField) {
                        this.getDataField(productDataField);
                    } else {
                        this.productDataField = null;
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private getDataField(dataFieldId: string): void {
        this.dataFieldsService.getDataField(dataFieldId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (productDataField: DataFieldModel) => {
                    this.productDataField = productDataField;
                },
                error: Toaster.handleApiError
            });
    }

    public editConnection() {
        // Get fresh copy of the connection
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }
}
